import Config from '../Config/Config';
import LocalStorage from '../Models/LocalStorage.js';

class SpeechClass {

    constructor() {
        this.getAllVoices(); // inicjujemy usługę wymowy, aby przy pierwszym uruchomieniu wymowy słowa nie było opóźnień
    }

    getLanguagesCodes() {
        return Config.get("speechLang");
    }

    canSpeech() {
        return this.getVoice() ? true : false;
    }

    speech(msgToSpeech) {
        var voiceURI = LocalStorage.getSpeechVoiceURI();
        var voice = this.getVoice(voiceURI);
        if (voice) {
            var msg = new SpeechSynthesisUtterance();
            msg.rate = Config.get("speechRate");
            msg.text = msgToSpeech;
            msg.voice = voice;
            msg.lang = voice.lang;
            msg.volumen = 1;
            window.speechSynthesis.speak(msg);
        }
    }


    getAllVoices(){
        if(typeof SpeechSynthesisUtterance !== 'undefined' && window.speechSynthesis){
            return window.speechSynthesis.getVoices() || [];
        }
        else {
            return [];
        }
    }

    getVoices(){
        var voices = [];
        var allVoices = this.getAllVoices();
        var langCodes = this.getLanguagesCodes();
        if(allVoices.length){
            for(var langCode of langCodes){
                for(let item of allVoices){
                    if(item.lang === langCode){
                        voices.push(item);
                    }
                }
            }
        }
        return voices;
    }


    getVoice(voiceURI){
        var voices = this.getVoices();
        if(voices.length){
            for(let item of voices){
                if(item.voiceURI === voiceURI){
                    return item;
                }
            }
            return voices[0];
        }

        return null;
    }
    
}


export let Speech = new SpeechClass();