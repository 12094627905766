import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import APISync from './../Models/APISync';
import ModelUser from './../Models/ModelUser';
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './../Models/LocalStorage';
import { setMultipleData } from '../ReduxActions/AppActions';

class RegisterPage extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: props.match.params.email ? props.match.params.email : "",
            inputPassword: "",
            inputRePassword: "",
            inputConfirmTerms: false,
            mode: 1, // 1 - formularz rejestracji, 2 - wysłanie maila, 3 - na podany adres jest już zarejestrowane konto
            error1: false,
            error2: false,
            sharedDictionaryID: 0
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
    }

    changeInputEmail(e){
        this.setState({inputEmail: e.target.value});
    }
    
    changeInputPassword(e){
        this.setState({inputPassword: e.target.value}, function(){
            if(this.state.inputPassword === this.state.inputRePassword){
                this.setState({error1: false});
            }
        }.bind(this));
    }

    changeInputRePassword(e){
        this.setState({inputRePassword: e.target.value}, function(){
            if(this.state.inputPassword === this.state.inputRePassword){
                this.setState({error1: false});
            }
        }.bind(this));
    }

    changeInputConfirmTerms(e){
        var error2 = this.state.inputConfirmTerms;
        this.setState({inputConfirmTerms: !this.state.inputConfirmTerms, error2: error2});
    }

    actionRegister(history){
        if(Tools.onLine()){
            if(Tools.isEmailValid(this.state.inputEmail) && Tools.isPasswordValid(this.state.inputPassword)){
                if( this.state.inputPassword ===  this.state.inputRePassword){
                    if(this.state.inputConfirmTerms){
                        Tools.loader(this.props, true);
                        ModelDictionary.getDictionaries(true)
                            .then(dictionaries => {
                                var extData = {};
                                if(LocalStorage.getShareDicLinkTokens()){
                                    extData.shareLinkTokens = LocalStorage.getShareDicLinkTokens();
                                }
                                return ModelUser.register(this.state.inputEmail, this.state.inputPassword, dictionaries, extData)
                            })
                            .then((response) => {
                                Tools.loader(this.props, false);
                                if(response.status){
                                    if(response.status === 1){
                                        Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                                        return APISync.syncDictionariesFromServer()
                                            .then(() => {
                                                return APISync.syncQueue();
                                            })
                                            .then(() => {
                                                return Promise.all([
                                                    ModelUser.getUser(),
                                                    ModelDictionary.getAllUserDictionaries(),
                                                    ModelDictionary.getBookmarksDictionary(true),
                                                ])
                                            })
                                            .then(results => {
                                                var user = results[0];
                                                var bookmarksDictionary = results[2];
                                                var userDictionaries = results[1].user;
                                                var userCatalogDictionaries = results[1].catalog;
                                                var userSharedDictionaries = results[1].shared;
                                                this.props.dispatch(setMultipleData({
                                                    user: user,
                                                    bookmarksDictionary: bookmarksDictionary,
                                                    userDictionaries: userDictionaries,
                                                    userCatalogDictionaries: userCatalogDictionaries,
                                                    userSharedDictionaries: userSharedDictionaries,
                                                }));
                                            })
                                            .then(() => {
                                                LocalStorage.clearShareDicLinkTokens();
                                                Tools.loader(this.props, false);
                                            })
                                            .then(() => {
                                                if(response.sharedDicOID){
                                                    return ModelDictionary.getDictionaryByOID(response.sharedDicOID)
                                                        .then(dictionary => {
                                                            this.setState({sharedDictionaryID: dictionary.ID})
                                                        })
                                                }
                                                else {
                                                    return true;
                                                }
                                            })
                                            .then(() => {
                                                this.setState({mode: 2});
                                            })
                                    }
                                    else if(response.status === 2){
                                        this.setState({mode: 3});
                                    }
                                }
                                else {
                                    this.setState({mode: 4});
                                }
                            })
                            .catch(e => {
                                Tools.log(e);
                                Tools.loader(this.props, false);
                                history.push('/error');
                            })
                    }
                    else {
                        this.setState({error2: true});
                    }
                }
                else {
                    this.setState({error1: true});
                }
            }
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }



    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("register_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    {this.state.mode === 1 && 
                    <div className="page-login page-login--register compact-content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="email" type="email" id="fr-email" value={this.state.inputEmail} onChange={e => this.changeInputEmail(e)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-email">{Lang.get("register_email")}</label>
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" minLength="6" id="fr-password" onChange={e => this.changeInputPassword(e)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-password">{Lang.get("register_password")}</label>
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" minLength="6" id="fr-repassword" onChange={e => this.changeInputRePassword(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionRegister(history) : null)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-repassword">{Lang.get("register_repassword")}</label>
                            {this.state.error1 && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("register_repassword_error")}</span>}
                        </div>
                        <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor="RCH1">
                            <input type="checkbox" id="RCH1" className="mdl-checkbox__input" onChange={e => this.changeInputConfirmTerms(e)} />
                            <span className="mdl-checkbox__label" dangerouslySetInnerHTML={{__html: Lang.get("register_confirm_term")}}></span>
                            {this.state.error2 && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("register_confirm_term_error")}</span>}
                        </label>
                        <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionRegister(history)}>{Lang.get("register_btn")}</button>
                    </div>
                    }

                    {this.state.mode === 2 &&
                        <div className="compact-content">
                            <div className="info-box">
                                {Lang.get("register_sent")}
                                {this.state.sharedDictionaryID > 0 &&
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => {history.push('/dic/'+this.state.sharedDictionaryID)}}>
                                        {Lang.get("register_sharedic_btn")}
                                    </button>
                                }
                            </div>
                        </div>
                    }

                    {this.state.mode === 3 &&
                        <div className="compact-content">
                            <div className="info-box">
                                {Lang.get("register_facebook_sent")}
                            </div>
                        </div>
                    }

                    {this.state.mode === 4 &&
                        <div className="compact-content">
                            <div className="info-box">
                                {Lang.get("register_error_exists")}
                            </div>
                        </div>
                    }

                </Layout>
            )} />

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(RegisterPage)
