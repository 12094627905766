import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, withRouter } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Loader from './../Components/Loader';
import Toast from './../Components/Toast';
import Alert from './../Components/Alert';
import Confirm from './../Components/Confirm';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import { setDictionary } from '../ReduxActions/AppActions';

class InitWithDic extends Component {


    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }
    
    componentDidMount() {
        this.sync();
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    sync(){
        if(Tools.onLine()){
            Tools.loader(this.props, true, Lang.get("sync_data_msg"));
            if(this.props.match && this.props.match.params.oid){
                var OID = parseInt(this.props.match.params.oid, 10);
                ModelDictionary.initializeDB()
                    .then((done) => {
                        return ModelDictionary.getDictionaries();
                    })
                    .then(userDictionaries => {
                        this.props.dispatch(setDictionary(null));

                        var existsDictionaryID = 0;
                        for(var dictionary of userDictionaries){
                            if(dictionary.OID === OID){
                                existsDictionaryID = dictionary.ID;
                                break;
                            }
                        }
                        if(existsDictionaryID){
                            Tools.loader(this.props, false); 
                            this.props.history.push('/dic/'+existsDictionaryID);
                        }
                        else {
                            return ModelDictionary.addDictionaryFromCatalog(OID)
                                .then((dictionary) => {
                                    Tools.loader(this.props, false);
                                    this.props.history.push('/dic/'+dictionary.ID);
                                }) 
                        }
                    })
                    .catch(e => {
                        Tools.loader(this.props, false);
                        this.props.history.push('/error');
                        Tools.log(e)
                    })
            }
            else {
                this.props.history.push('/');
            }
        }
        else {
            Tools.confirm(
                this.props, 
                () => {
                    this.sync();
                }, 
                Lang.get("dialog_alert_msg_offline"),
                "",
                Lang.get("dialog_alert_btn_retry"),
                true
            );
        }
    }


    render() {
        return (

            <Route render={({ history }) => (
                <div>
                    <Loader />
                    <Toast />
                    <Alert />
                    <Confirm />
                </div>
            )} /> 

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(InitWithDic))
