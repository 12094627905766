import { Component } from 'react';
import { connect } from "react-redux";
import { setMultipleData } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser';
import LocalStorage from './../Models/LocalStorage';
import APISync from './../Models/APISync';
import { onlineCheck } from '../Misc/OnlineCheck';
import Tools from '../Misc/Tools';
import Config from '../Config/Config';
import Lang from '../Misc/Lang';

class SyncProcess extends Component {


    SYNC_QUEUE_INVERVAL = Config.get("syncIntervalQueue"); // jak często synchronizować kolejkę [s]
    SYNC_FROM_SERVER_INVERVAL = Config.get("syncIntervalFromServer"); // jak często sprawdzać zmiany po stronie serwera [s]

    componentDidMount() {
        
        this.syncIntervalHandler = setInterval(() => {
            this.sync();
        }, this.SYNC_QUEUE_INVERVAL*1000);
        
        this.sync(true);

        // dla niezalogowanych co X minut pukamy do API aby na podstawie zwróconej wersji service-worker (parametr zwrotny "swv") można było ewentualnie odświezyć service-worker (zrobi to automatycznie APISync.RPCRequest())
        this.scheckSWVersionIntervalHandler = setInterval(() => {
            this.checkServiceWorkerVersion();
        }, 5 * 60 * 1000);
        this.checkServiceWorkerVersion();

        onlineCheck.attachOnlineEvent(() => {
            this.sync(true);
        });

        // gdy zamykana jest przeglądarka próbujemy zsynchronizować dane z serwerem
        window.addEventListener('beforeunload',  () => { 
            navigator.serviceWorker.ready.then(function(swRegistration) {
                return swRegistration.sync.register('syncDictionaries');
            });
            this.sync(true);
        });
        window.addEventListener('unload',  () => { 
            navigator.serviceWorker.ready.then(function(swRegistration) {
                return swRegistration.sync.register('syncDictionaries');
            });
            this.sync(true);
        });
        
    }

    componentWillUnmount(){
        if(this.syncIntervalHandler){
            clearInterval(this.syncIntervalHandler);
        }
    }

    sync(force) {
        if(Tools.onLine()){
            var _promise;
            var _syncFromServer = false;
            if(force || this.canSyncFromServer()){
                _syncFromServer = true;
                _promise = APISync.syncDictionariesFromServer().catch(Tools.log);
            }
            else {
                _promise = new Promise((resolve, reject) => { resolve() } );
            }

            return _promise
                .then(() => {
                    return APISync.syncQueue();
                })
                .then((response) => {
                    if(response){
                        var errorQueueItems = response[0];
                        var clientRefresh = response[1]
                        
                        if(_syncFromServer){
                            // sprawdzamy czy nie ma błedów i czy błąd nie dotyczy wywołania syncDictionariesFromServer
                            var _syncFromServerError = false;
                            if(errorQueueItems.length > 1){
                                for(var queueItem of errorQueueItems){
                                    if(queueItem.Method === 'syncDictionariesFromServer'){
                                        _syncFromServerError = true;
                                        break;
                                    }
                                }
                            }
                            if(!_syncFromServerError){
                                LocalStorage.setLastSyncFromServerTime();
                            }
                        }

                        if(clientRefresh){
                            return Promise.all([
                                ModelUser.getUser(),
                                ModelDictionary.getCurrentDictionary(),
                                ModelDictionary.getBookmarksDictionary(true),
                                ModelDictionary.getUserDictionaries(),
                                ModelDictionary.getUserCatalogDictionaries(),
                                ModelDictionary.getUserSharedDictionaries()
                            ])
                                .then(results => {
                                    this.props.dispatch(setMultipleData({
                                        user: results[0],
                                        dictionary: results[1],
                                        bookmarksDictionary: results[2],
                                        userDictionaries: results[3],
                                        userCatalogDictionaries: results[4],
                                        userSharedDictionaries: results[5],
                                    }));
                                    Tools.toast(Lang.get("sync_from_server_done_toast"));
                                })
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                })
                .then((results) => {
                    Tools.log('SYNC FINISH', results)
                })
                .catch((e) => {
                    Tools.log('SYNC CATCH', e)
                });
        }
        else {
            return new Promise((resolve, reject) => { resolve() } );
        }
    }

    checkServiceWorkerVersion(){
        ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return APISync.RPCRequest('ping', {});
                }
            })
            .catch(Tools.log)
    }


    
    canSyncFromServer(){
        var currentTS = Math.round(new Date().getTime()/1000);
        return (currentTS - LocalStorage.getLastSyncFromServerTime()) > this.SYNC_FROM_SERVER_INVERVAL
    }



    render() {
        return null;
    }

}


export default connect(function (store) {
    return {
        appState: store.appState
    }
})(SyncProcess)
