import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

import { 
    setDialogDicRename,
    setDialogDicShare
} from '../ReduxActions/AppActions';


class DialogDicShare extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            shareDicDescription: "",
        }
        this.dialogShow = props.appDialogs.dialogDicShare;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-share');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicShare && nextProps.appDialogs.dialogDicShare !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogDicShare;
            Tools.dialogNavBackOpen(this.dialog);
            return true;
        }
        if(this.state.shareDicDescription !== nextState.shareDicDescription){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }
    

    actionShareOnChangeDescription(e) {
        var description = e.target.value.substring(0,200);
        this.setState({ shareDicDescription: description });
    }

    actionShareGo() {
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelDictionary.share(this.props.appState.dictionary.ID, this.state.shareDicDescription)
                .then(() => {
                    Tools.loader(this.props, false);
                    Tools.toast(Lang.get("dic_dialog_share_done_toast"));
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.alert(this.props, Lang.get("dialog_alert_msg_error"));
                    Tools.log(e);
                })
            this.closeDialog();

        }
        else {
            this.closeDialog();
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionShareChangeName() {
        this.closeDialog();
        /*
        if(this.props.appDialogs.dialogDicSharePayload && this.props.appDialogs.dialogDicSharePayload.changeNameCallback){
            this.props.appDialogs.dialogDicSharePayload.changeNameCallback();
        }
        */
        this.props.dispatch(setDialogDicRename({
            renameDoneCallback: () => {
                this.props.dispatch(setDialogDicShare())
            }
        }));
    }



    render() {
        const { appState } = this.props;
        const { dictionary } = appState;

        return (

            <dialog className="mdl-dialog dialog dialog-share" data-dialog-id="share">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_share_title")}</h4>
                <div className="mdl-dialog__content">
                    {dictionary && <h5>{dictionary.Name}</h5>}
                    <p>{Lang.get("dic_dialog_share_desc")}</p>

                    <button onClick={() => this.actionShareChangeName()} className="mdl-button mdl-js-button mdl-js-ripple-effect">{Lang.get("dic_dialog_share_btn_change_name")}</button>
                    <div className="dialog-share__description">
                        <p>{Lang.get("dic_dialog_share_description_desc")}</p>
                        <div className="mdl-textfield mdl-js-textfield mdl-textfield--full-width">
                            <textarea className="mdl-textfield__input" type="text" rows= "2" id="SDDe" value={this.state.shareDicDescription} onChange={e => this.actionShareOnChangeDescription(e)}></textarea>
                            <label className="mdl-textfield__label" htmlFor="SDDe">{Lang.get("dic_dialog_share_description_label")}</label>
                        </div>
                    </div>
                    
                    <p className="disclaimer">{Lang.get("dic_dialog_share_disclaimer")}</p>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareGo()}>{Lang.get("dic_dialog_share_btn_ok")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_share_btn_cancel")}</button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicShare)