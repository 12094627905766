import React, { Component } from 'react';
import { connect } from "react-redux";
import { setMultipleData } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import Config from '../Config/Config';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordAddCSV extends Component {
    
    dialog = null;
    dialogShow = false;

    _upgradeMdlSelect = false;


    constructor(props) {
        super(props);
        this.state = {
            addWordDictionaryID: 0,
            addWordDictionaryName: "",
            importCSVFile: null,
            importCSVFileName: null,
            importCSVFileContent: null,
            importCSVEncoding: Config.get("csvImportEncoding")[0],
        }
        this.dialogShow = props.appDialogs.dialogWordAddCSV;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-add-csv');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
        this.csvImportAttachEvents();
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordAddCSV && nextProps.appDialogs.dialogWordAddCSV !== this.dialogShow){
            this.state.importCSVFileName = null;
            this.state.importCSVFileContent = null;
            this.state.addWordDictionaryID = nextProps.appState.dictionary.ID;
            this.state.addWordDictionaryName = nextProps.appState.dictionary.Name;

            this._upgradeMdlSelect = true;

            document.querySelector('#csvFile').value = "";
            this.dialogShow = nextProps.appDialogs.dialogWordAddCSV;
            Tools.dialogNavBackOpen(this.dialog);
        }
        return true;
    }

    componentDidUpdate() {
        if(this._upgradeMdlSelect){
            Tools.mdlInputRefresh();
            Tools.mdlSelectRefresh();
        }
        this._upgradeMdlSelect = false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    csvImportAttachEvents(){
        window.addEventListener("dragover", function (e) {
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e.preventDefault();
        }, false);

        var dropZone = document.querySelector('.upload-area');
        dropZone.addEventListener('dragover', e => {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
            dropZone.classList.add("upload-area--dragover");
        });
        dropZone.addEventListener('dragleave', e => {
            e.stopPropagation();
            e.preventDefault();
            dropZone.classList.remove("upload-area--dragover");
        });
        dropZone.addEventListener('drop', e => {
            e.stopPropagation();
            e.preventDefault();
            dropZone.classList.remove("upload-area--dragover");
            var files = e.dataTransfer.files;
            if(files.length > 0){
                this.setState({importCSVFile: files[0]});
            }
            else {
                this.setState({importCSVFile: null});
            }
            this.csvImportReadFile();
        });
        var csvBrowseButton = document.querySelector('#csvFile');
        csvBrowseButton.addEventListener('change', e => {
                e.stopPropagation();
                e.preventDefault();
                if(e.target.files.length > 0){
                    this.setState({importCSVFile: e.target.files[0]});
                }
                else {
                    this.setState({importCSVFile: null});
                }
                this.csvImportReadFile()
            }
        )
    }

    csvImportReadFile(){
        if(this.state.importCSVFile){
            var reader = new FileReader();
            reader.onload = function(e) {
                this.setState({importCSVFileName: this.state.importCSVFile.name, importCSVFileContent: e.target.result});
            }.bind(this);
            reader.readAsText(this.state.importCSVFile, this.state.importCSVEncoding);
        }
    }



    actionAddWordCSVOnChangeDictionary(e, dicID, dicName) {
        this.setState({ addWordDictionaryID: parseInt(dicID, 10), addWordDictionaryName: dicName });
    }

    actionAddWordCSVOnChangeEncoding(e, encoding) {
        this.setState({ importCSVEncoding: encoding }, this.csvImportReadFile);
    }

    actionAddWordCSVGo() {
        if(this.state.importCSVFileName !== null){
            this.closeDialog();
            if(Tools.onLine()){
                Tools.loader(this.props, true);
                ModelDictionary.importCSV(this.state.importCSVFileContent, this.state.addWordDictionaryID)
                    .then(() => {
                        //Tools.loader(this.props, false);
                        Tools.toast(Lang.get("dic_dialog_csv_import_done_toast"));
                        return ModelDictionary.getCurrentDictionarySorted()
                    })
                    .then((dictionary) => {
                        return Promise.all([
                            ModelDictionary.getBookmarksDictionary(true),
                            ModelDictionary.getUserDictionaries()
                        ])
                            .then(results => {
                                var bookmarksDictionary = results[0];
                                var userDictionaries = results[1];
                                this.props.dispatch(setMultipleData({
                                    bookmarksDictionary: bookmarksDictionary,
                                    userDictionaries: userDictionaries,
                                    dictionary: dictionary
                                }));
                            })
                        
                    })
                    .catch((e) => {
                        Tools.loader(this.props, false);
                        Tools.alert(this.props, Lang.get("dic_dialog_csv_error_alert"));
                        Tools.log(e);
                    })
            }
            else {
                Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
            }
        }
    }



    render() {
        const { appState } = this.props;

        return (

            <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-add-csv" data-dialog-id="addcsv">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_csv_title")}</h4>
                <div className="mdl-dialog__content">
                    <p>{Lang.get("dic_dialog_csv_desc1")}</p>
                    <p>{Lang.get("dic_dialog_csv_desc2")}</p>
                    <p>{Lang.get("dic_dialog_csv_desc3")}</p>

                    <pre>{Lang.get("dic_dialog_csv_example_code")}</pre>

                    <p>{Lang.get("dic_dialog_csv_desc4")}</p>

                    <p dangerouslySetInnerHTML={{ __html: Lang.get("dic_dialog_csv_msg_desktop_url") }}></p>

                    
                    <div className="upload-area">
                        <p>{Lang.get("dic_dialog_csv_upload_label")}</p>
                        <label htmlFor="csvFile" className="mdl-button mdl-button--raised">{Lang.get("dic_dialog_csv_upload_btn_browse")}</label>
                        <input type="file" id="csvFile" />
                        {this.state.importCSVFileName !== null && <div className="upload-area__filename">{this.state.importCSVFileName}</div>}
                    </div>

                    <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                        <input tabIndex='-1' type="text" defaultValue={this.state.addWordDictionaryName} className="mdl-textfield__input" id="DIawdc" readOnly />
                        <input type="hidden" />
                        <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                        <label htmlFor="DIawdc" className="mdl-textfield__label">{Lang.get("dic_dialog_csv_dictionary_label")}</label>
                        <ul htmlFor="DIawdc" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                            {appState.bookmarksDictionary &&
                                ((this.state.addWordDictionaryID === appState.bookmarksDictionary.ID) ? (
                                    <li onClick={e => this.actionEditWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID} data-selected>{appState.bookmarksDictionary.Name}</li>
                                ) : (
                                        <li onClick={e => this.actionEditWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID}>{appState.bookmarksDictionary.Name}</li>
                                    ))
                            }
                            {appState.userDictionaries && appState.userDictionaries.map((dic, index) =>
                                ((this.state.addWordDictionaryID === dic.ID) ? (
                                    <li key={"aw-key-" + index} onClick={e => this.actionAddWordCSVOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID} data-selected>{dic.Name}</li>
                                ) : (
                                        <li key={"aw-key-" + index} onClick={e => this.actionAddWordCSVOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID}>{dic.Name}</li>
                                    ))
                            )}
                        </ul>
                    </div>

                    <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                        <input tabIndex='-1' type="text" defaultValue={this.state.importCSVEncoding} className="mdl-textfield__input" id="DIawdce" readOnly />
                        <input type="hidden" />
                        <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                        <label htmlFor="DIawdce" className="mdl-textfield__label">{Lang.get("dic_dialog_csv_encoding_label")}</label>
                        <ul htmlFor="DIawdce" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                            {Config.get("csvImportEncoding").map((encoding, index) =>
                                ((this.state.importCSVEncoding === encoding) ? (
                                    <li key={"aw-key-" + index} onClick={e => this.actionAddWordCSVOnChangeEncoding(e, encoding)} className="mdl-menu__item" data-val={encoding} data-selected>{encoding}</li>
                                ) : (
                                        <li key={"aw-key-" + index} onClick={e => this.actionAddWordCSVOnChangeEncoding(e, encoding)} className="mdl-menu__item" data-val={encoding}>{encoding}</li>
                                    ))
                            )}
                        </ul>
                    </div>

                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionAddWordCSVGo()}>{Lang.get("dic_dialog_csv_btn_ok")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_csv_btn_cancel")}</button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordAddCSV)