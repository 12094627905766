import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';

class SettingsPasswordChangePage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputCurrentPassword: "",
            inputPassword: "",
            inputRePassword: "",
            mode: 1, // 1 - formularz zmiany hasła, 2 - potwierdzenie zmiany
            errorCurrentPassword: false,
            errorPassword: false
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    changeInputCurrentPassword(e){
        this.setState({inputCurrentPassword: e.target.value}, function(){
            this.setState({errorCurrentPassword: false});
        }.bind(this));
    }

    changeInputPassword(e){
        this.setState({inputPassword: e.target.value}, function(){
            if(this.state.inputPassword === this.state.inputRePassword){
                this.setState({errorPassword: false});
            }
        }.bind(this));
    }

    changeInputRePassword(e){
        this.setState({inputRePassword: e.target.value}, function(){
            if(this.state.inputPassword === this.state.inputRePassword){
                this.setState({errorPassword: false});
            }
        }.bind(this));
    }

    actionChangePassword(history){
        if(Tools.onLine()){
            if(Tools.isPasswordValid(this.state.inputCurrentPassword) && Tools.isPasswordValid(this.state.inputPassword)){
                if(this.state.inputPassword ===  this.state.inputRePassword){
                    Tools.loader(this.props, true);
                    ModelUser.passwordChangeSettings(this.state.inputCurrentPassword, this.state.inputPassword)
                        .then((response) => {
                            Tools.loader(this.props, false);
                            if(response.status){
                                this.setState({mode: 2});
                            }
                            else {
                                this.setState({errorCurrentPassword: true});
                            }
                        })
                        .catch(() => {
                            Tools.loader(this.props, false);
                        });
                }
                else {
                    this.setState({errorPassword: true});
                }
            }
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

   


    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("changepassword_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >

                    {this.state.mode === 1 && 
                    <div className="page-login page-login--passwordchange compact-content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" autoComplete="new-password" minLength="6" id="fr-curpassword" onChange={e => this.changeInputCurrentPassword(e)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-curpassword">{Lang.get("changepassword_currentpassword")}</label>
                            {this.state.errorCurrentPassword && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("changepassword_currentpassword_error")}</span>}
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" autoComplete="new-password" minLength="6" id="fr-password" onChange={e => this.changeInputPassword(e)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-password">{Lang.get("changepassword_password")}</label>
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" autoComplete="new-password" minLength="6" id="fr-repassword" onChange={e => this.changeInputRePassword(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionChangePassword(history) : null)} required />
                            <label className="mdl-textfield__label" htmlFor="fr-repassword">{Lang.get("changepassword_repassword")}</label>
                            {this.state.errorPassword && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("changepassword_repassword_error")}</span>}
                        </div>
                        <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionChangePassword(history)}>{Lang.get("changepassword_btn_change")}</button>
                    </div>
                    }

                    {this.state.mode === 2 &&
                        <div className="compact-content">
                            <div className="info-box">
                                {Lang.get("changepassword_changed")}
                            </div>
                        </div>
                    }

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(SettingsPasswordChangePage)
