
import Tools from '../Misc/Tools';
import APISync from './APISync.js';
import UserData from './../Models/UserData';

class ModelUser {

    constructor(){
        this.setDeviceID()
            .catch(Tools.log)
    }

    setUser(user){
        return UserData.set('user', user ? user : null);  
    }

    getUser() {
        return UserData.get('user', null);  
    }

    isAdmin(user){
        return (user && user.OID === 1);
    }

    getUserOID(){
        return UserData.get('user', null)
            .then((user) => {
                if(user){
                    return parseInt(user.OID, 10);
                }
                else {
                    return 0;
                }
            })
    }

    isUserActive(){
        return UserData.get('user', null)
            .then((user) => {
                if(user){
                    return user.Active === 1;
                }
                else {
                    return false;
                }
            })
    }

    setUserNick(nick){
        return this.getUser()
            .then(user => {
                if(user){
                    user.Nick = nick;
                    return UserData.set('user', user)
                        .then(() => { return user })
                }
                else {
                    return false;   
                }
            })
    }

    getUserNick(){
        return new Promise((resolve, reject) => { 
            UserData.get('user', null)
                .then((user) => {
                    if(user){
                        resolve(user.Nick);
                    }
                    else {
                        resolve(null);
                    }
                })
                .catch(Tools.log)
        });
    }


    setAPIToken(token){
        return UserData.set('APIToken', token ? token : null);
    }

    getAPIToken() {
        return UserData.get('APIToken', null, true);  
    }


    setDeviceID(){
        return this.getDeviceID()
            .then(result => {
                if(!result){
                    return UserData.set('deviceID',  Math.floor(Math.random() * (10000000000)) + 1);
                }
                else {
                    return false
                }
            })
            .catch(Tools.log)
    }

    getDeviceID(){
        return UserData.get('deviceID', null)
    }

    getDeviceName(){
        var d = Tools.deviceInfo();
        return d.os.name + ' ' + d.os.version + '; ' + d.browser.name + ' ' + d.browser.version;
    }



    login(email, password, extData){
        return APISync.APIRequest('login', {
                email: email, 
                password: password,
                extData: extData
            })
            .then(response => {
                if(typeof response.status !== 'undefined'){
                    if(response.status === 1){
                        return this.setUser(response.User)
                            .then(() => { return response });
                    }
                    else {
                        return response;
                    }
                }
                else {
                    throw new Error();
                }
            })
    }

    logout(){
        return this.setUser(null);
    }

    loginFB(responseFB, dictionaries, extData){
        responseFB.dictionaries = dictionaries;
        responseFB.extData = extData;
        return APISync.APIRequest('loginFB', responseFB)
            .then(response => {
                if(typeof response.status !== 'undefined'){
                    if(response.status === 1 || response.status === 2 || response.status === 4){
                        return this.setUser(response.User)
                            .then(() => { return response });
                    }
                    else {
                        return response;
                    }
                }
                else {
                    throw new Error();
                }
            })
    }

    register(email, password, dictionaries, extData){
        //@TODO - przepisz wszystkie słownika z użytkownika 0 na zarejestrowanego - ale dopiero po potweirdzeniu konta...
        return APISync.APIRequest('register', {email: email, password: password, dictionaries: dictionaries, extData: extData})
    }

    sendActivation(userOID, token){
        return APISync.APIRequest('sendActivation', {})
    }

    changeNick(nick){
        return APISync.APIRequest('nickChange', {nick: nick})
            .then((response) => {
                return this.setUserNick(nick)
                    .then(() => {
                        return response;
                    })
            })
    }

    passwordRemind(email){
        return APISync.APIRequest('passwordRemind', {email: email})
    }

    passwordChange(userOID, token, password){
        return APISync.APIRequest('passwordChange', {userOID: userOID, password: password, token: token})
    }
    
    passwordChangeSettings(currentPassword, password){
        return APISync.APIRequest('passwordChangeSettings', {currentPassword: currentPassword, password: password})
    }

    deleteAccountRequest(){
        return APISync.APIRequest('deleteAccountRequest', {})
    }

    deleteAccount(userOID, token){
        return APISync.APIRequest('deleteAccount', {userOID: userOID, token: token})
            .then(() => {
                return this.logout();
            })
    }


}

export default new ModelUser(); 