import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, withRouter } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';
import ModelDictionary from './../Models/ModelDictionary';

class SharedDictionariesPage extends Component {

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    constructor(props) {
        super(props);
        this.state = {
            logged: null,
            sharedDictionaries: null
        }
    }

    componentDidMount() {
        ModelUser.getUser()
            .then(user => {
                if(user && user.OID){
                    this.setState({logged: true})
                    this.getSharedDictionaries();
                }
                else {
                    this.setState({logged: false})
                }
            })
            .catch(Tools.log)
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    getSharedDictionaries() {
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelDictionary.getSharedDictionaries()
                .then(results => {
                    Tools.loader(this.props, false);
                    this.setState({sharedDictionaries: results.sharedDictionaries});
                })
                .catch(e => {
                    Tools.log(e);
                    Tools.loader(this.props, false);
                    this.props.history.push('/error');
                })
        }
    }

    actionDelete(OID) {
        Tools.confirm(
            this.props, 
            () => {
                Tools.loader(this.props, true);
                ModelDictionary.shareToUserDelUser(OID)
                    .then((response) => {
                        this.getSharedDictionaries();
                    })
                    .catch((e) => {
                        Tools.loader(this.props, false);
                        Tools.log(e);
                    })
            }, 
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_header"),
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_msg"),
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_btn")
        )
    }


    render() {

        return (
            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("sharedics_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    <div className="compact-content shared-dictionary">

                        {this.state.logged !== null && !this.state.logged &&
                            <div className="info-box">
                                {Lang.get("sharedics_not_logged")}
                            </div>
                        }
                        
                        {this.state.sharedDictionaries !== null && this.state.sharedDictionaries.length === 0 &&
                            <div className="info-box">
                                {Lang.get("sharedics_empty_list")}
                            </div>
                        }

                        {this.state.sharedDictionaries !== null && this.state.sharedDictionaries.length > 0 &&
                            <div className="shared-dictionary__list">
                                {this.state.sharedDictionaries.map((sharedDictionary, indexDictionary) =>
                                    <div key={"sd-"+indexDictionary} className="shared-dictionary__item">
                                        <h2>{sharedDictionary.Name}</h2>
                                        <table className="shared-list mdl-data-table mdl-js-data-table mdl-shadow--2dp">
                                            <thead>
                                                <tr>
                                                    <th>{Lang.get("dic_dialog_sharetouser_user_list_col_email")}</th>
                                                    <th>{Lang.get("dic_dialog_sharetouser_user_list_col_status")}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sharedDictionary.Shares.map((share, index) => 
                                                    <tr key={"sds-" + index}>
                                                        <td><span className="nick">{share.Nick}</span>{share.Mail}</td>
                                                        <td className={"status status-" + share.Status}>{Lang.get("dic_dialog_sharetouser_user_list_status_" + share.Status)}</td>
                                                        <td>
                                                            <button className="mdl-button mdl-js-button mdl-button--primary" onClick={e => this.actionDelete(share.OID)}>
                                                                <i className="material-icons">delete</i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        }

                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(SharedDictionariesPage))
