import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import Config from '../Config/Config';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './../Models/LocalStorage';
import APISync from './../Models/APISync';

class LoginPage extends Component {

    dialogFBTerms = null;

    constructor(props) {
        super(props);
        this.state = {
            inputEmail: props.match.params.email ? props.match.params.email : "",
            inputPassword: "",
            loginError: false,
            FBresponse: null,
            FBConfirmTerms: false,
            FBConfirmTermsError: false,
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';
    }

    componentDidMount() {
        this.dialogFBTerms = document.querySelector('.dialog-terms');
        if (this.dialogFBTerms && !this.dialogFBTerms.showModal) {
            window.dialogPolyfill.registerDialog(this.dialogFBTerms);
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
    }

    changeInputEmail(e) {
        this.setState({ inputEmail: e.target.value });
    }

    changeInputPassword(e) {
        this.setState({ inputPassword: e.target.value });
    }

    actionLogin(history) {
        if (Tools.onLine()) {
            if (Tools.isEmailValid(this.state.inputEmail) && this.state.inputPassword) {
                Tools.loader(this.props, true);
                var extData = {};
                if (LocalStorage.getShareDicLinkTokens()) {
                    extData.shareLinkTokens = LocalStorage.getShareDicLinkTokens();
                }
                ModelUser.login(this.state.inputEmail, this.state.inputPassword, extData)
                    .then((response) => {
                        if (response.status) {
                            Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                            return APISync.syncDictionariesFromServer()
                                .then(() => {
                                    return APISync.syncQueue();
                                })
                                .then(() => {
                                    LocalStorage.clearShareDicLinkTokens();
                                    Tools.loader(this.props, false);
                                })
                                .then(() => {
                                    if (response.sharedDicOID) {
                                        return ModelDictionary.getDictionaryByOID(response.sharedDicOID)
                                            .then(dictionary => {
                                                history.push('/dic/' + dictionary.ID);
                                            })
                                            .catch(() => {
                                                history.push('/');
                                            })
                                    }
                                    else {
                                        history.push('/');
                                    }
                                })
                                .catch((e) => {
                                    Tools.loader(this.props, false);
                                    Tools.log(e);
                                    history.push('/error');
                                })

                        }
                        else {
                            this.setState({ loginError: true });
                            Tools.loader(this.props, false);
                        }
                    })
                    .catch(() => {
                        Tools.loader(this.props, false);
                        history.push('/error');
                    });
            }
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }


    actionLoginFB(responseFB) {
        if (Tools.onLine()) {
            Tools.loader(this.props, true);
            ModelDictionary.getDictionaries(true)
                .then(dictionaries => {
                    var extData = {};
                    if (LocalStorage.getShareDicLinkTokens()) {
                        extData.shareLinkTokens = LocalStorage.getShareDicLinkTokens();
                    }
                    return ModelUser.loginFB(responseFB, dictionaries, extData)
                })
                .then((response) => {
                    if (response.status === 1 || response.status === 2 || response.status === 4) {
                        Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                        return APISync.syncDictionariesFromServer()
                            .then(() => {
                                return APISync.syncQueue();
                            })
                            .then(() => {
                                if (response.status === 4) {
                                    // rejestracja
                                    LocalStorage.clearShareDicLinkTokens();
                                    return true;
                                }
                                else {
                                    return true;
                                }
                            })
                            .then(() => {
                                Tools.loader(this.props, false);
                            })
                            .then(() => {
                                if (response.sharedDicOID) {
                                    return ModelDictionary.getDictionaryByOID(response.sharedDicOID)
                                        .then(dictionary => {
                                            this.props.history.push('/dic/' + dictionary.ID);
                                        })
                                        .catch(() => {
                                            this.props.history.push('/');
                                        })
                                }
                                else {
                                    this.props.history.push('/');
                                }
                            })
                    }
                    else if (response.status === 3) {
                        // popup z potwierdzeniem regulaminu
                        this.setState({ FBresponse: responseFB });
                        Tools.loader(this.props, false);
                        if (this.dialogFBTerms && !this.dialogFBTerms.open) {
                            this.dialogFBTerms.showModal();
                        }
                    }
                    else {
                        throw new Error();
                    }

                })
                .catch((e) => {
                    Tools.log(e);
                    Tools.loader(this.props, false);
                    this.props.history.push('/error');
                });
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    changeInputConfirmTerms(e) {
        var FBConfirmTermsError = this.state.FBConfirmTerms;
        this.setState({ FBConfirmTerms: !this.state.FBConfirmTerms, FBConfirmTermsError: FBConfirmTermsError });
    }

    actionLoginFBTermsAccept() {
        if (this.state.FBConfirmTerms) {
            this.closeFBTermsDialog();
            var responseFB = this.state.FBresponse;
            Tools.log('responseFB', responseFB);
            responseFB.termsAccept = 1;
            this.actionLoginFB(responseFB);
        }
        else {
            this.setState({ FBConfirmTermsError: true });
        }
    }

    closeFBTermsDialog() {
        if (this.dialogFBTerms && this.dialogFBTerms.open) {
            this.dialogFBTerms.close();
        }
    }

    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("login_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    <div className="page-login page-login--login compact-content">

                        {/*
                        <div className="info-box">
                            {Lang.get("login_infobox")}
                        </div>
                        */}

                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="email" type="email" id="f-email" value={this.state.inputEmail} onChange={e => this.changeInputEmail(e)} required />
                            <label className="mdl-textfield__label" htmlFor="f-email">{Lang.get("login_email")}</label>
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="password" type="password" minLength="6" id="f-password" value={this.state.inputPassword} onChange={e => this.changeInputPassword(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionLogin(history) : null)} required />
                            <label className="mdl-textfield__label" htmlFor="f-password">{Lang.get("login_password")}</label>
                            {this.state.loginError && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("login_error")}</span>}
                        </div>
                        <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionLogin(history)}>{Lang.get("login_btn_login")}</button>
                        <Link to="/password-remind" className="login-password-remind"><button className="mdl-button mdl-js-button mdl-js-ripple-effect">{Lang.get("login_remind_password")}</button></Link>
                        <div className="login-hr"><span>{Lang.get("login_fb_head")}</span></div>
                        {/*<button className="button-facebook mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionLoginFB(history)}>
                            <i className="button-facebook__icon"></i> {Lang.get("login_btn_fblogin")}
                        </button>*/}
                        <div className="button-facebook-native">
                            <FacebookLogin
                                appId={Config.get("FBAppID")}
                                fields="name,email"
                                callback={this.actionLoginFB.bind(this)}
                                isMobile={false}
                                icon="fa-facebook"
                                version="18.0"
                            />
                        </div>
                        <div className="register">
                            <p>{Lang.get("login_reg_lead")}</p>
                            <Link to="/register"><button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white">{Lang.get("login_reg_btn")}</button></Link>
                        </div>
                    </div>

                    <dialog className="mdl-dialog dialog dialog-terms" data-dialog-id="terms">
                        <div className="mdl-dialog__content">
                            <iframe className="dialog-terms__terms" title="Terms" src={Config.get("FBTermsURL")}></iframe>
                            <label className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor="RCH1">
                                <input type="checkbox" id="RCH1" className="mdl-checkbox__input" onChange={e => this.changeInputConfirmTerms(e)} />
                                <span className="mdl-checkbox__label">{Lang.get("login_dialog_terms_confirm")}</span>
                                {this.state.FBConfirmTermsError && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("login_dialog_terms_error")}</span>}
                            </label>
                        </div>
                        <div className="mdl-dialog__actions">
                            <button type="button" className="mdl-button mdl-button--raised close" onClick={() => this.actionLoginFBTermsAccept()}>{Lang.get("login_dialog_terms_btn_confirm")}</button>
                            <button type="button" className="mdl-button close" onClick={() => this.closeFBTermsDialog()}>{Lang.get("login_dialog_terms_btn_cancel")}</button>
                        </div>
                    </dialog>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(LoginPage)
