import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogDicPublish extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            public: 0,
            publicHighlight: 0,
            order: 999,
            description: "",
            author: "",
            disableWordsIndex: 0,
        }
        this.dialogShow = props.appDialogs.dialogDicPublish;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-publish');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicPublish && nextProps.appDialogs.dialogDicPublish !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogDicPublish;
            if(this.props.appState.dictionary && this.props.appState.dictionary.ID){
                ModelDictionary.getDictionaryById(this.props.appState.dictionary.ID, false, true)
                    .then((dictionary) => {
                        if(dictionary.OID){
                            Tools.dialogNavBackOpen(this.dialog);
                            this.getPublishState();
                        }
                    })
                    .catch(Tools.log)
            }
            
        }
        
        return true;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    changeInputPublic(e) {
        this.setState({ public: !this.state.public });
    }

    changeInputPublicHighlight(e) {
        this.setState({ publicHighlight: !this.state.publicHighlight });
    }

    changeInputDisableWordsIndex(e) {
        this.setState({ disableWordsIndex: !this.state.disableWordsIndex });
    }
    

    changeInputOrder(e) {
        this.setState({ order: e.target.value });
    }

    changeInputDescription(e) {
        var description = e.target.value.substring(0,200);
        this.setState({ description: description });
    }

    changeInputAuthor(e) {
        this.setState({ author: e.target.value });
    }




    actionSaveGo() {
        if(!this.state.loader){

            var state = {
                Description: this.state.description,
                Author: this.state.author,
                Public: this.state.public,
                PublicHighlight: this.state.publicHighlight,
                PublicOrder: parseInt(this.state.order, 10) ? parseInt(this.state.order, 10) : 999,
                DisableWordsIndex: this.state.disableWordsIndex,
            }
            this.closeDialog();
            Tools.loader(this.props, true);
            var dictionary = this.props.appState.dictionary;
            ModelDictionary.publishStateSave(dictionary, state)
                    .then((response) => {
                        Tools.loader(this.props, false);
                        Tools.toast(Lang.get("dic_dialog_publish_toast_done"));
                    })
                    .catch((e) => {
                        Tools.loader(this.props, false);
                        Tools.alert(this.props, Lang.get("dic_dialog_publish_msg_error"));
                        Tools.log(e);
                    })

        }
    }


    getPublishState(){
        this.setState({ loader: true });
        var dictionary = this.props.appState.dictionary;
        ModelDictionary.publishStateGet(dictionary)
            .then((response) => {
                if(typeof response.publishState !== 'undefined'){
                    this.setState({ 
                        loader: false,
                        public: response.publishState.Public,
                        publicHighlight: response.publishState.PublicHighlight,
                        order: response.publishState.PublicOrder,
                        description: response.publishState.Description ? response.publishState.Description : "",
                        author: response.publishState.Author ? response.publishState.Author : "",
                        disableWordsIndex: response.publishState.DisableWordsIndex,
                    });
                }
                else {
                    throw new Error("Empty publishState response");
                }
            })
            .catch((e) => {
                this.closeDialog();
                Tools.alert(this.props, Lang.get("dic_dialog_publish_msg_error"));
                Tools.log(e);
            })
    }


    render() {
        
        return (

            <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-publish" data-dialog-id="dialog-publish">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_publish_title")}</h4>
                <div className="mdl-dialog__content">

                {this.state.loader && <div className="mdl-progress mdl-js-progress mdl-progress__indeterminate"></div>}
                {!this.state.loader &&

                    <div>

                        {this.props.appState.dictionary && <h5>{this.props.appState.dictionary.Name}</h5>}
                        
                        <div className="dialog-publish__row dialog-publish__row--seperator">
                            <label>{Lang.get("dic_dialog_publish_label_public")}</label>
                            <div>
                                <label className={"mdl-switch is-upgraded" + (this.state.public ? ' is-checked' : '')} htmlFor="dp-c2">
                                    <input type="checkbox" id="dp-c2" className="mdl-switch__input" checked={this.state.public} onChange={() => {this.changeInputPublic()}} />
                                    <span className="mdl-switch__label"></span>
                                    <div className="mdl-switch__track"></div>
                                    <div className="mdl-switch__thumb">
                                        <span className="mdl-switch__focus-helper"></span>
                                    </div>
                                    <span className="mdl-switch__ripple-container mdl-ripple--center" data-upgraded=",MaterialRipple">
                                        <span className="mdl-ripple"></span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="dialog-publish__row dialog-publish__row--seperator">
                            <label>{Lang.get("dic_dialog_publish_label_disable_words_index")}</label>
                            <div>
                                <label className={"mdl-switch is-upgraded" + (this.state.disableWordsIndex ? ' is-checked' : '')} htmlFor="dp-c3">
                                    <input type="checkbox" id="dp-c3" className="mdl-switch__input" checked={this.state.disableWordsIndex} onChange={() => {this.changeInputDisableWordsIndex()}} />
                                    <span className="mdl-switch__label"></span>
                                    <div className="mdl-switch__track"></div>
                                    <div className="mdl-switch__thumb">
                                        <span className="mdl-switch__focus-helper"></span>
                                    </div>
                                    <span className="mdl-switch__ripple-container mdl-ripple--center" data-upgraded=",MaterialRipple">
                                        <span className="mdl-ripple"></span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="dialog-publish__row dialog-publish__row--seperator">
                            <label>{Lang.get("dic_dialog_publish_label_public_highlight")}</label>
                            <div>
                                <label className={"mdl-switch is-upgraded" + (this.state.publicHighlight ? ' is-checked' : '')} htmlFor="dp-c1">
                                    <input type="checkbox" id="dp-c1" className="mdl-switch__input" checked={this.state.publicHighlight} onChange={() => {this.changeInputPublicHighlight()}} />
                                    <span className="mdl-switch__label"></span>
                                    <div className="mdl-switch__track"></div>
                                    <div className="mdl-switch__thumb">
                                        <span className="mdl-switch__focus-helper"></span>
                                    </div>
                                    <span className="mdl-switch__ripple-container mdl-ripple--center" data-upgraded=",MaterialRipple">
                                        <span className="mdl-ripple"></span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="dialog-publish__row">
                            <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                                <input className="mdl-textfield__input" type="number" id="dp-i1" required="required" value={this.state.order} onChange={e => this.changeInputOrder(e)} />
                                <label className="mdl-textfield__label" htmlFor="dp-i1">{Lang.get("dic_dialog_publish_label_order")}</label>
                            </div>
                        </div>

                        <div className="dialog-publish__row">
                            <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                                <input className="mdl-textfield__input" type="text" id="dp-i2" value={this.state.author} onChange={e => this.changeInputAuthor(e)} />
                                <label className="mdl-textfield__label" htmlFor="dp-i2">{Lang.get("dic_dialog_publish_label_author")}</label>
                            </div>
                        </div>

                        <div className="dialog-publish__row">
                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--full-width">
                                <textarea className="mdl-textfield__input" type="text" rows= "2" id="dp-i3" value={this.state.description} onChange={e => this.changeInputDescription(e)}></textarea>
                                <label className="mdl-textfield__label" htmlFor="dp-i3">{Lang.get("dic_dialog_publish_label_decription")}</label>
                            </div>
                        </div>

                    </div>

                }

                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionSaveGo()}>{Lang.get("dic_dialog_publish_btn_save")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_publish_btn_cancel")}</button>
                </div>

            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicPublish)