import { setLoader, setAlert, setConfirm } from '../ReduxActions/AppActions';
import { onlineCheck } from './OnlineCheck';

var Tools = {
    
    mdlUpgrade: function(){
        if(!(typeof(window.componentHandler) === 'undefined')){
            window.componentHandler.upgradeAllRegistered();
        }
    },

    mdlUpgradeElement: function(element){
        if(!(typeof(window.componentHandler) === 'undefined')){
            window.componentHandler.upgradeElement(element);
        }
    },
    

    mdlSelectRefresh: function(){
        if(window.eventMdlSelectRefresh){
            window.dispatchEvent(window.eventMdlSelectRefresh);
        }
    },

    mdlInputRefresh: function(){
        document.querySelectorAll('.mdl-textfield__input').forEach(function(item) {
            item.dispatchEvent(new Event('reset'))
        });
    },

    mdlElementAttach: function(DOMIds, callback){
        for(var DOMId of DOMIds){
            var element = document.getElementById(DOMId);
            if(element){
                Tools.mdlUpgradeElement(element);
            }
        }
        if(callback){
            callback();
        }
    },

    loader: function(props, on, msg){
        props.dispatch(setLoader({on: on, msg: msg}));
    },

    alert: function(props, msg){
        props.dispatch(setAlert(msg));
    },

    confirm: function(props, action, header, msg, actionBtnLabel, disableCancel, cancelBtnLabel, doubleConfirm){
        props.dispatch(setConfirm({
            action: action, 
            header: header ? header : null, 
            msg: msg ? msg : null, 
            actionBtnLabel: actionBtnLabel ? actionBtnLabel : null,
            disableCancel: disableCancel,
            cancelBtnLabel: cancelBtnLabel,
            doubleConfirm: doubleConfirm
        }));
    },

    onLine: function(){
        return onlineCheck.isOnline();
    },

    toast: function(msg, actionText, actionHandler, timeout, isHtmlMsg, isLight){
        timeout = timeout ? timeout : 2750;
        var toast = document.querySelector('#toast');
        if(toast && toast.MaterialSnackbar){
            var data = {message: msg, actionHandler: actionHandler, actionText: actionText, clearQueue: true, timeout: timeout, isHtmlMsg: isHtmlMsg, lightMode: isLight};
            toast.MaterialSnackbar.showSnackbar(data);
        }
    },

    toastKnown: function(msg, actionText, actionHandler, timeout){
        timeout = timeout ? timeout : 2750;
        var toast = document.querySelector('#toast');
        if(toast && toast.MaterialSnackbar){
            var data = {message: msg, actionHandler: actionHandler, actionText: actionText, clearQueue: true, timeout: timeout, forceShow: true, isHtmlMsg: true, lightMode: true};
            toast.MaterialSnackbar.showSnackbar(data);
        }
    },
    
    toastClear: function(){
        var toast = document.querySelector('#toast');
        if(toast){
            toast.classList.remove("mdl-snackbar--active");
        }
        var toastLight = document.querySelector('#toast-light');
        if(toastLight){
            toastLight.classList.remove("mdl-snackbar--active");
        }
    },


    dialogNavBackOpen: function(dialog){
        Tools.dialogNavBackAttachGlobalEvent(); // obsługa zamykania dialog przy back przeglądarki
        setTimeout(() => {
            // Zdarzało się, że otworzony dialog nie renderował się na środku ekranu tylko poniżej. Opoźnienie pomogło.
            if(dialog && !dialog.open){
                dialog.showModal();
                window.history.pushState({state: "dialogOpen"}, null, null);
            }
        }, 10);
    },

    dialogNavBackClose: function(dialog){
        if(dialog && dialog.open){
            //dialog.close();
            window.history.back();
        }
    },

    dialogNavBackAttachGlobalEvent: function(){
        window.addEventListener('popstate', Tools.dialogNavBackAttachGlobalEventFunction);
    },

    dialogNavBackAttachGlobalEventFunction: function(event) {
        var dialog = document.querySelector('.mdl-dialog[open]');
        if(dialog && dialog.open){
            dialog.close();
        }
    },


    
    arrayShuffle: function(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    },

    isEmailValid: function(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    isPasswordValid: function(email) {
        return email.length > 5;
    },

    isNickValid: function(nick) {
        return nick.trim().length > 3;
    },

    promiseAll: function(arr) {
        return new Promise(function (resolve, reject) {
            let index = 0;
            let retArray = [];
            function next() {
                if (index < arr.length) {
                    arr[index]
                        .then((data) => {
                            retArray[index] = {
                                success: true,
                                data: data
                            };
                            index++;
                            next();
                            return true;
                        })
                        .catch(e => {
                            retArray[index] = {
                                success: false,
                                data: null
                            };
                            index++;
                            next();
                            return true;
                        });
                }
                else {
                    var out = [];
                    for(var idx = 0; idx < arr.length; idx++){
                        out[idx] = retArray[idx];
                    }
                    resolve(out);
                }
            }
            next();
        });
    },

    log: function() {
        if(process && process.env && process.env.NODE_ENV === 'development'){
            console.log(...arguments)
        }
    },


    sleep: function(ms){
        return new Promise((resolve, reject) => {
            setTimeout(resolve, ms);
        });
    },

    deviceInfo: function () {
        
        var module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
            ],
            databrowser: [
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
            ],
            init: function () {
                var agent = this.header.join(' '),
                    os = this.matchItem(agent, this.dataos),
                    browser = this.matchItem(agent, this.databrowser);
                
                return { os: os, browser: browser };
            },
            matchItem: function (string, data) {
                var i = 0,
                    j = 0,
                    regex,
                    regexv,
                    match,
                    matches,
                    version;
                
                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        };
        
        return module.init()
  
    },

    cloneObject: function (object) {
        return JSON.parse(JSON.stringify(object));
    },

    isMobileDevice: function(){
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    isIOS: function(){
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },

    isAndroid: function() {
        return /android/i.test(navigator.userAgent);
    },

    isChrome: function() {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    },

    isWordInBookmarks: function(wordObj, wordsObj) {
        if(wordsObj && Array.isArray(wordsObj) && wordsObj.length){
            for(var word of wordsObj){
                if(word.Word === wordObj.Word){
                    return true;
                }
            }
        }
        return false;
    },


    elementTouchEvents: function(onClick, onHold, onHoldTime){

        onHoldTime = onHoldTime ? onHoldTime : 1000;

        var _holdTimeout = null;
        var _holdPreventClick = null;

        return {
            onClick: (e) => {
                e.stopPropagation();
                if (!_holdPreventClick) {
                    if(onClick){
                        onClick(e);
                    }
                }
                else {
                    _holdPreventClick = false;
                }
            },
            onMouseDown(e) {
                e.stopPropagation();
                _holdTimeout = setTimeout(() => {
                    _holdPreventClick = true;
                    if(onHold){
                        onHold(e);
                    }
                }, onHoldTime);
            },
            onMouseUp(e) {
                clearTimeout(_holdTimeout);
            },
            onTouchStart(e) {
                e.stopPropagation();
                _holdTimeout = setTimeout(() => {
                    _holdPreventClick = true;
                    if(onHold){
                        onHold(e);
                    }
                }, onHoldTime);
            },
            onTouchMove(e) {
                clearTimeout(_holdTimeout);
            },
            onTouchEnd(e) {
                clearTimeout(_holdTimeout);
            }


        }

       
    


    }

}
export default Tools;