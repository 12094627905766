export function setUser(user) {
  return {
    type: 'SET_USER',
    payload: user
  }
}

export function setDictionary(dictionary) {
  return {
    type: 'SET_DICTIONARY',
    payload: dictionary
  }
}

export function setBookmarksDictionary(dictionary) {
  return {
    type: 'SET_BOOKMARKS_DICTIONARY',
    payload: dictionary
  }
}

export function setUserDictionaries(dictionaries) {
  return {
    type: 'SET_USER_DICTIONARIES',
    payload: dictionaries
  }
}

export function setUserCatalogDictionaries(dictionaries) {
  return {
    type: 'SET_USER_CATALOG_DICTIONARIES',
    payload: dictionaries
  }
}

export function setUserSharedDictionaries(dictionaries) {
  return {
    type: 'SET_USER_SHARED_DICTIONARIES',
    payload: dictionaries
  }
}

export function setMultipleData(dataObject) {
  return {
    type: 'SET_MULTIPLE_DATA',
    payload: dataObject
  }
}




export function setLoader(obj) {
  return {
    type: 'SET_LOADER',
    payload: obj
  }
}

export function setAlert(msg) {
  return {
    type: 'SET_ALERT',
    payload: msg
  }
}

export function setConfirm(obj) {
  return {
    type: 'SET_CONFIRM',
    payload: obj
  }
}


export function setDialogDicAdd(payload) {
  return {
    type: 'SET_DIALOG_DIC_ADD',
    payload: payload || null
  }
}

export function setDialogDicRename(payload) {
  return {
    type: 'SET_DIALOG_DIC_RENAME',
    payload: payload || null
  }
}

export function setDialogDicCopyAs(payload) {
  return {
    type: 'SET_DIALOG_DIC_COPYAS',
    payload: payload || null
  }
}

export function setDialogDicShare(payload) {
  return {
    type: 'SET_DIALOG_DIC_SHARE',
    payload: payload || null
  }
}

export function setDialogDicShareToUser(payload) {
  return {
    type: 'SET_DIALOG_DIC_SHARE_TO_USER',
    payload: payload || null
  }
}

export function setDialogDicShareCatalog(payload) {
  return {
    type: 'SET_DIALOG_DIC_SHARE_CATALOG',
    payload: payload || null
  }
}

export function setDialogDicPublish(payload) {
  return {
    type: 'SET_DIALOG_DIC_PUBLISH',
    payload: payload || null
  }
}

export function setDialogWordAdd(payload) {
  return {
    type: 'SET_DIALOG_WORD_ADD',
    payload: payload || null
  }
}

export function setDialogWordAddCSV(payload) {
  return {
    type: 'SET_DIALOG_WORD_ADDCSV',
    payload: payload || null
  }
}

export function setDialogWordCopy(payload) {
  return {
    type: 'SET_DIALOG_WORD_COPY',
    payload: payload || null
  }
}

export function setDialogWordEdit(payload) {
  return {
    type: 'SET_DIALOG_WORD_EDIT',
    payload: payload || null
  }
}

export function setDialogWordsSort(payload) {
  return {
    type: 'SET_DIALOG_WORDS_SORT',
    payload: payload || null
  }
}

export function setDialogWordsShow(payload) {
  return {
    type: 'SET_DIALOG_WORDS_SHOW',
    payload: payload || null
  }
}


export function setDicWordsCnt(payload) {
  return {
    type: 'SET_DIC_WORDS_CNT',
    payload: payload || null
  }
}

export function setDicWordsCntChangeKnown(payload) {
  return {
    type: 'SET_DIC_WORDS_CNT_CHANGE_KNOWN',
    payload: payload || null
  }
}


export function setDialogLargeList(payload) {
  return {
    type: 'SET_DIALOG_LARGE_LIST',
    payload: payload || null
  }
}



