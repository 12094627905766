import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import WordListItem from '../Components/WordListItem';
import Tools from '../Misc/Tools';


class WordList extends Component {

    shouldComponentUpdate(nextProps, nextState){
        if(
            JSON.stringify(this.props.appState.dictionary || null) !== JSON.stringify(nextProps.appState.dictionary || null)
            ||
            JSON.stringify(this.props.appState.userDictionaries || null) !== JSON.stringify(nextProps.appState.userDictionaries || null)
            ||
            JSON.stringify(this.props.appState.bookmarksDictionary || null) !== JSON.stringify(nextProps.appState.bookmarksDictionary || null)
        ){
            return true;
        }
        return false;
    }

    componentDidUpdate(){
        if(this.props.appState.dictionary){
            Tools.loader(this.props, false);
        }
    }


    render() {
        const { appState } = this.props;
        const { dictionary, bookmarksDictionary } = appState;
        
        return (
            
            <div>
                {dictionary && bookmarksDictionary && dictionary.Words.length > 0 &&

                    <table className={"words-list mdl-data-table mdl-js-data-table mdl-shadow--2dp" + (dictionary.LearnMode === ModelDictionary.DIC_LEARNMODE_LEFT ? " words-list--hide-left" : "") + (dictionary.LearnMode === ModelDictionary.DIC_LEARNMODE_RIGHT ? " words-list--hide-right" : "") + (dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN ? " words-list--hide-known" : "") + (dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN ? " words-list--hide-unknown" : "")}>
                        <tbody>
                            {dictionary.Words.map((word, indexWord) =>
                                <WordListItem 
                                    key={"word-" + word.ID}
                                    word={word} 
                                    dictionary={dictionary} 
                                    isInBookmarks={Tools.isWordInBookmarks(word, bookmarksDictionary.Words)}
                                    userDictionariesLength={appState.userDictionaries.length} 
                                />
                            )}
                        </tbody>
                    </table>

                }
            </div>
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(WordList);
