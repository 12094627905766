import React, { Component } from 'react';
import { connect } from "react-redux";
import { setDictionary } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordsSort extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.dialogShow = props.appDialogs.dialogWordsSort;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-sort');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordsSort && nextProps.appDialogs.dialogWordsSort !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogWordsSort;
            Tools.dialogNavBackOpen(this.dialog);
        }
        return true;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }


    actionSortChange(e) {
        Tools.loader(this.props, true);
        ModelDictionary.changeSortType(this.props.appState.dictionary.ID, e.target.value)
            .then(() => {
                return ModelDictionary.getCurrentDictionarySorted(true)
            })
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
            .catch(Tools.log)
        this.closeDialog();
    }



    render() {
        const { appState } = this.props;
        const { dictionary } = appState;

        return (
            <dialog className="mdl-dialog dialog dialog-sort" data-dialog-id="sort">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_sort_title")}</h4>
                <div className="mdl-dialog__content">
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSopt-1">
                        <input type="radio" id="DSopt-1" className="mdl-radio__button" name="dicSortType" value={ModelDictionary.DIC_SORTTYPE_DEFAULT} checked={(dictionary && dictionary.SortType === ModelDictionary.DIC_SORTTYPE_DEFAULT) ? true : false} onChange={this.actionSortChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_sort_opt_default")}</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSopt-2">
                        <input type="radio" id="DSopt-2" className="mdl-radio__button" name="dicSortType" value={ModelDictionary.DIC_SORTTYPE_RANDOM} checked={(dictionary && dictionary.SortType === ModelDictionary.DIC_SORTTYPE_RANDOM) ? true : false} onChange={this.actionSortChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_sort_opt_random")}</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSopt-3">
                        <input type="radio" id="DSopt-3" className="mdl-radio__button" name="dicSortType" value={ModelDictionary.DIC_SORTTYPE_ALPHA_WORD} checked={(dictionary && dictionary.SortType === ModelDictionary.DIC_SORTTYPE_ALPHA_WORD) ? true : false} onChange={this.actionSortChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_sort_opt_alpha_word")}</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSopt-4">
                        <input type="radio" id="DSopt-4" className="mdl-radio__button" name="dicSortType" value={ModelDictionary.DIC_SORTTYPE_ALPHA_TRANS} checked={(dictionary && dictionary.SortType === ModelDictionary.DIC_SORTTYPE_ALPHA_TRANS) ? true : false} onChange={this.actionSortChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_sort_opt_alpha_trans")}</span>
                    </label>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_sort_btn_cancel")}</button>
                </div>
            </dialog>
        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordsSort)