
import ModelUser from './ModelUser.js';
import LocalStorage from './LocalStorage.js';
import APISync from './APISync.js';
import db from './DB';

import Tools from '../Misc/Tools';

class ModelDictionary {

    TABLE_DICTIONARY = 'dictionary';
    TABLE_WORD = 'word';
    TABLE_SYNCQUEUE = 'syncqueue';
    TABLE_USERDATA = 'userdata';

    DIC_SOURCE_USER = 'user';
    DIC_SOURCE_CATALOG = 'catalog';
    DIC_SOURCE_SHARED = 'shared';
    
    DIC_TYPE_REGULAR = 'regular';
    DIC_TYPE_BOOKMARKS = 'bookmarks';
    
    DIC_LEARNMODE_LEARN = 'learn';
    DIC_LEARNMODE_LEFT = 'left';
    DIC_LEARNMODE_RIGHT = 'right';

    DIC_SORTTYPE_DEFAULT = 'default';
    DIC_SORTTYPE_RANDOM = 'random';
    DIC_SORTTYPE_ALPHA_WORD = 'alpha_word';
    DIC_SORTTYPE_ALPHA_TRANS = 'alpha_trans';

    DIC_SHOWWORDS_ALL = 'all';
    DIC_SHOWWORDS_KNOWN = 'known';
    DIC_SHOWWORDS_UNKNOWN = 'unknown';

    _shuffleWordsOrder = [];

    initializeDB(leaveUserData) {

        return new Promise((resolve, reject) => {

            if(!LocalStorage.getDataInitialized()){

                var _promisesToRun = [
                    db.table(this.TABLE_DICTIONARY).clear(),
                    db.table(this.TABLE_WORD).clear(),
                    db.table(this.TABLE_SYNCQUEUE).clear()
                ];
                if(!leaveUserData){
                    _promisesToRun.push(db.table(this.TABLE_USERDATA).clear());
                }

                Promise.all(_promisesToRun)
                    .then(() => {
                        return db.table(this.TABLE_DICTIONARY)
                        .where({UserOID: 0})
                        .count()
                        .then(res => {
                            if (!res) {
                                return db.table(this.TABLE_DICTIONARY)
                                    .add({
                                        UserOID: 0,
                                        Source: this.DIC_SOURCE_USER, 
                                        Type: this.DIC_TYPE_BOOKMARKS,
                                        Name: 'Ulubione',
                                        WordsCnt: 0,
                                        WordsKnownCnt: 0,
                                        CurrentSlide: 0,
                                        ShowWords: this.DIC_SHOWWORDS_UNKNOWN,
                                        LearnMode: this.DIC_LEARNMODE_LEARN,
                                        SortType: this.DIC_SORTTYPE_DEFAULT,
                                    })
                                    .then(() => {
                                        // synchronizujemy domyślne słowniki z katalogu
                                        return APISync.APIRequest('initDictionaries', {})
                                    })
                                    .then((response) => {
                                        if(response.dictionaries){
                                            var _promises = [];
                                            for(let dictionary of response.dictionaries){
                                                _promises.push(this.replaceDictionary(dictionary));
                                            }
                                            return Promise.all(_promises)
                                        }
                                    })
                                    .then(() => {
                                        LocalStorage.setDataInitialized(true);
                                        return true;
                                    })
                            }
                            else {
                                return false;
                            }
                        })
                        
                    })
                    .then(resolve)
                    .catch(reject);
            }
            else {
                resolve(false);
            }

        })
            
    }

    /*
    clearDB(){
        return db.table(this.TABLE_DICTIONARY).clear()
            .then(() => {
                return db.table(this.TABLE_WORD).clear()
            })
            .then(() => {
                return db.table(this.TABLE_SYNCQUEUE).clear()
            })
    }

    clearDBLoggedUser(){
        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }

                return this.getDictionaries()
                    .then(dictionaries => {
                        var _promisesToRun = [];
                        for (var dictionary of dictionaries) {
                            _promisesToRun.push(db.table(this.TABLE_WORD).where({Dictionary_ID: dictionary.ID}).delete())
                            _promisesToRun.push(db.table(this.TABLE_DICTIONARY).delete(dictionary.ID))
                        }
                        return Promise.all(_promisesToRun)
                    })
                    .then(() => {
                        return db.table(this.TABLE_SYNCQUEUE)
                            .where({User_OID: userOID})   
                            .delete()
                    })

            })
    }
    */

    setCurrentDictionaryID(dictionaryID){
        LocalStorage.setCurrentDictionaryID(dictionaryID);
    }

    getCurrentDictionaryID(){
        return LocalStorage.getCurrentDictionaryID();
    }

    setCurrentDictionary(ID) {
        return new Promise((resolve, reject) => {
            ID = parseInt(ID, 10);
            if (ID !== this.getCurrentDictionaryID()) {
                if (ID) {
                    return this.getDictionaryById(ID, false)
                        .then((dictionary) => {
                            if (dictionary) {
                                this.setCurrentDictionaryID(ID);
                                resolve(true);
                            }
                            else {
                                reject();
                            }
                        })
                        .catch(() => reject())
                }
                else {
                    this.setCurrentDictionaryID(ID);
                    resolve(true);
                }
            }
            else {
                resolve(false);
            }

        });


    }

    getCurrentDictionary() {
        //Profiler.log('getCurrentDictionary: begin');
        return this.getDictionaryById(this.getCurrentDictionaryID(), true)
            .then((currentDictionary) => {
                //Profiler.log('getCurrentDictionary: finish');
                return this.filterDictionaryWords(currentDictionary);
            })
            .catch((e) => {
                return this.getDictionaries(true)
                    .then(dictionaries => {
                        if (dictionaries && dictionaries.length > 0) {
                            return this.filterDictionaryWords(dictionaries[0]);
                        }
                        else {
                            throw new Error();
                        }
                    })
            })
    }

    filterDictionaryWords(dictionary){
        dictionary.WordsCnt = dictionary.Words.length;
        if(dictionary.ShowWords === this.DIC_SHOWWORDS_KNOWN || dictionary.ShowWords === this.DIC_SHOWWORDS_UNKNOWN){
            var _words = [];
            for(let word of dictionary.Words){
                if(dictionary.ShowWords === this.DIC_SHOWWORDS_KNOWN && word.Known > 0){
                    _words.push(word);
                }
                if(dictionary.ShowWords === this.DIC_SHOWWORDS_UNKNOWN && word.Known === 0){
                    _words.push(word);
                }

            }
            dictionary.Words = _words;
        }
        return dictionary;
    }

    getCurrentDictionarySorted(forceShuffleWords) {

        return this.getCurrentDictionary()
            .then((dictionary) => {
                //dictionary = JSON.parse(JSON.stringify(dictionary)); // klonujemy objekt
                switch (dictionary.SortType) {
                    case this.DIC_SORTTYPE_RANDOM:
                        if (!this._shuffleWordsOrder.length || forceShuffleWords) {
                            this._shuffleWordsOrder = [];
                            for (let word of dictionary.Words) {
                                this._shuffleWordsOrder.push(word.ID);
                            }
                            this._shuffleWordsOrder = Tools.arrayShuffle(this._shuffleWordsOrder);
                        }

                        var _words = {};
                        for (let word of dictionary.Words) {
                            _words[word.ID] = word;
                        }

                        var wordsNewOrder = [];
                        for (var id of this._shuffleWordsOrder) {
                            if (_words[id]) {
                                wordsNewOrder.push(_words[id]);
                            }
                        }

                        for (var word of dictionary.Words) {
                            if (this._shuffleWordsOrder.indexOf(word.ID) < 0){
                                wordsNewOrder.push(word);
                            }
                        }
                        
                        dictionary.Words = wordsNewOrder;

                        break;
                    case this.DIC_SORTTYPE_ALPHA_WORD:
                        dictionary.Words.sort(function (a, b) {
                            const strA = a.Word.toUpperCase();
                            const strB = b.Word.toUpperCase();
                            if (strA > strB) {
                                return 1;
                            } else if (strA < strB) {
                                return -1;
                            }
                            else {
                                return 0;
                            }
                        });
                        break;
                    case this.DIC_SORTTYPE_ALPHA_TRANS:
                        dictionary.Words.sort(function (a, b) {
                            const strA = a.Trans.length ? a.Trans[0].Word.toUpperCase() : '';
                            const strB = b.Trans.length ? b.Trans[0].Word.toUpperCase() : '';
                            if (strA > strB) {
                                return 1;
                            } else if (strA < strB) {
                                return -1;
                            }
                            else {
                                return 0;
                            }
                        });
                        break;
                    default:

                        break;
                }

                return dictionary;

            });

    }

    isCurrentDictionary(ID) {
        return this.getCurrentDictionary()
            .then((currentDictionary) => {
                return currentDictionary && currentDictionary.ID === ID;
            })
    }

    
    getDictionaryById(ID, withWords, userIsOwner, ignoreBelongToUser) {
        return ModelUser.getUserOID()
            .then(userOID => {
                return db.table(this.TABLE_DICTIONARY)
                    .where('ID')
                    .equals(ID)
                    .toArray(results => {
                        if (results && results.length > 0) {
                            var dictionary = results[0];
                            if(!ignoreBelongToUser && dictionary.UserOID !== userOID){
                                throw new Error("getDictionaryById: Dictionary doesn't belong to user");
                            }
                            if (userIsOwner && dictionary.Source !== this.DIC_SOURCE_USER) {
                                throw new Error("getDictionaryById: Dictionary is from catalog");
                            }
                            return dictionary;
                        }
                        else {
                            throw new Error("getDictionaryById: Dictionary doesn't exists");
                        }
                    })
                    .then(dictionary => {
                        if(withWords){
                            dictionary.Words = [];
                            return this.getWordsByDictionaryId(dictionary.ID)
                                .then(words => {
                                    dictionary.Words = words;
                                    return dictionary;
                                })
                        }
                        else {
                            if(dictionary.Words){
                                delete dictionary.Words;
                            }
                            return dictionary;
                        }
                        
                    })
                    
            })

    }

    getDictionaryByOID(OID, withWords, userIsOwner) {

        return ModelUser.getUserOID()
            .then(userOID => {
                return db.table(this.TABLE_DICTIONARY)
                    .where({OID: OID, UserOID: userOID})
                    .toArray(results => {
                        if (results && results.length > 0) {
                            var dictionary = results[0];
                            if (userIsOwner && dictionary.Source !== this.DIC_SOURCE_USER) {
                                throw new Error("getDictionaryById: Dictionary is from catalog");
                            }
                            return dictionary;
                        }
                        else {
                            throw new Error("getDictionaryByOID: Dictionary doesn't exists");
                        }
                    })
                    .then(dictionary => {
                        if(withWords){
                            dictionary.Words = [];
                            return this.getWordsByDictionaryId(dictionary.ID)
                                .then(words => {
                                    dictionary.Words = words;
                                    return dictionary;
                                })
                        }
                        else {
                            if(dictionary.Words){
                                delete dictionary.Words;
                            }
                            return dictionary;
                        }
                    })
            })

    }

    getDictionaryByWordId(WordId, onlyUserDic) {

        return db.table(this.TABLE_WORD)
            .where('ID')
            .equals(WordId)
            .toArray(results => {
                if (results && results.length > 0) {
                    return results[0];
                }
                else {
                    throw new Error("getDictionaryByWordId: Word doesn't exists");
                }
            })
            .then(word => {
                return this.getDictionaryById(word.Dictionary_ID, false, onlyUserDic)
            })

    }

    getDictionaries(withWords) {

        return ModelUser.getUserOID()
            .then(userOID => {
                var _where = {};
                _where.UserOID = userOID;

                return db.table(this.TABLE_DICTIONARY)
                    .where(_where)
                    .toArray(results => {
                        if (results && results.length > 0) {
                            return results;
                        }
                        else {
                            return results;
                            //throw new Error("getDictionaries: User doesn't have any dictionaries");
                        }
                    })
                    .then(dictionaries => {
                        if(withWords){
                            var _promises = [];
                            for(let dictionary of dictionaries){
                                dictionary.Words = [];
                                _promises.push(
                                    this.getWordsByDictionaryId(dictionary.ID)
                                        .then(words => dictionary.Words = words)
                                )
                            }
                            return Promise.all(_promises)
                                .then(() => { return dictionaries })
                        }
                        else {
                            for(let dictionary of dictionaries){
                                if(dictionary.Words){
                                    delete dictionary.Words;
                                }
                            }
                            return dictionaries;
                        }
                        
                    })
            });

    }

    getAllUserDictionaries(withWords) {
        return this.getDictionaries(withWords)
            .then(dictionaries => {
                var results = {bookmarks: null, user: [], shared: [], catalog: []};
                for (var dictionary of dictionaries) {
                    if (dictionary.Source === this.DIC_SOURCE_USER && dictionary.Type === this.DIC_TYPE_BOOKMARKS) {
                        results.bookmarks = dictionary;
                    }
                    if (dictionary.Source === this.DIC_SOURCE_USER && dictionary.Type === this.DIC_TYPE_REGULAR) {
                        results.user.push(dictionary);
                    }
                    if (dictionary.Source === this.DIC_SOURCE_SHARED) {
                        results.shared.push(dictionary);
                    }
                    if (dictionary.Source === this.DIC_SOURCE_CATALOG) {
                        results.catalog.push(dictionary);
                    }
                }
                return results;
            })
    }

    getBookmarksDictionary(withWords) {
        return this.getAllUserDictionaries(withWords)
            .then(results => {
                return results.bookmarks;
            })
    }


    getUserDictionaries(withWords) {
        return this.getAllUserDictionaries(withWords)
            .then(results => {
                return results.user;
            })
       
    }

    getUserSharedDictionaries(withWords) {
        return this.getAllUserDictionaries(withWords)
            .then(results => {
                return results.shared;
            })
    }

    getUserCatalogDictionaries(withWords) {
        return this.getAllUserDictionaries(withWords)
            .then(results => {
                return results.catalog;
            })
    }

    getWordById(WordId, onlyUserDic) {
        return db.table(this.TABLE_WORD)
            .where('ID')
            .equals(WordId)
            .toArray(results => {
                if (results && results.length > 0) {
                    return results[0];
                }
                else {
                    throw new Error("getWordById: Word doesn't exist");
                }
            })
            .then(word => {
                return this.getDictionaryById(word.Dictionary_ID, false, onlyUserDic)
                    .then(dictionary => { return word })
            })
    }

    getWordsByDictionaryId(dictionaryID){
        return db.table(this.TABLE_WORD)
                .where({Dictionary_ID: dictionaryID})
                .toArray(results => results)
    }


    getCatalogDictionaries(filter) {
        return APISync.APIRequest('catalogBrowse', {filter: filter})
    }

    addDictionary(name, disableSync) {
        return ModelUser.getUserOID()
            .then(userOID => {
                return db.table(this.TABLE_DICTIONARY).add({
                    OID: 0,
                    UserOID: userOID,
                    Source: this.DIC_SOURCE_USER, 
                    Type: this.DIC_TYPE_REGULAR,
                    Name: name,
                    WordsCnt: 0,
                    WordsKnownCnt: 0,
                    CurrentSlide: 0,
                    ShowWords: this.DIC_SHOWWORDS_UNKNOWN,
                    LearnMode: this.DIC_LEARNMODE_LEARN,
                    SortType: this.DIC_SORTTYPE_DEFAULT,
                })
            })    
            .then((dictionaryID) => {
                if(disableSync){
                    return dictionaryID;
                }
                else {
                    return APISync.syncDictionary(dictionaryID)
                        .then(() => { 
                            return dictionaryID 
                        })
                }
            })

    }

    copyDictionary(dictionaryID, name){
        return this.getDictionaryById(dictionaryID, true, true)
            .then((dictionary) => {
                return this.addDictionary(name, true)
                    .then((dictionaryID) => {
                        var wordsToAdd = []
                        for(let word of dictionary.Words){
                            word.Dictionary_ID = dictionaryID;
                            if(typeof word.ID !== 'undefined'){
                                delete word.ID;
                            }
                            if(typeof word.OID !== 'undefined'){
                                delete word.OID;
                            }
                            wordsToAdd.push(word);
                        }
                        return db.table(this.TABLE_WORD)
                            .bulkPut(wordsToAdd)
                            .then(() => {
                                return this.setDictionaryWordsCount(dictionaryID);
                            })
                            .then(() => {
                                return this.setDictionaryWordsKnownCount(dictionaryID);
                            })
                            .then(() => {
                                return APISync.syncDictionary(dictionaryID, true)
                                    .then(() => { 
                                        return dictionaryID 
                                    })
                            })
                    })
            })
    }

    addDictionaryFromCatalog(OID) {
        return APISync.APIRequest('catalogAddDictionary', {dictionaryOID: OID})
            .then(response => {
                if(response.dictionary){
                    return this.replaceDictionary(response.dictionary)
                }
            })
    }

    replaceDictionary(dictionary){

        return new Promise((resolve, reject) => {
                if(typeof dictionary.ID !== 'undefined'){
                    this.getDictionaryById(dictionary.ID ? dictionary.ID : 0)
                        .then((dictionaryFromDB) => resolve(dictionaryFromDB))
                        .catch(() => resolve(null))
                }
                else {
                    this.getDictionaryByOID(dictionary.OID ? dictionary.OID : 0)
                        .then((dictionaryFromDB) => resolve(dictionaryFromDB))
                        .catch(() => resolve(null))
                }
            })
            .then(dictionaryFromDB => {
                var dictionaryClone = this.cloneObject(dictionary);
                if(dictionaryFromDB){
                    dictionaryClone.ID = dictionaryFromDB.ID;
                    if(dictionaryClone.Words) delete dictionaryClone.Words;
                    return db.table(this.TABLE_DICTIONARY)
                        .update(dictionaryClone.ID, dictionaryClone)
                        .then(() => {
                            dictionary.ID = dictionaryClone.ID;
                            return dictionary;
                        })
                }
                else {
                    if(dictionaryClone.Words) delete dictionaryClone.Words;
                    delete dictionaryClone.ID;
                    return db.table(this.TABLE_DICTIONARY)
                        .add(dictionaryClone)
                        .then((ID) => {
                            dictionary.ID = ID;
                            return dictionary;
                        })
                }
            })
            .then(dictionary => {
                if(typeof dictionary.Words !== 'undefined' && Array.isArray(dictionary.Words)){
                    return db.table(this.TABLE_WORD)
                        .where({Dictionary_ID: dictionary.ID})   
                        .delete()
                        .then(() => {
                            var wordsToAdd = []
                            for(let word of dictionary.Words){
                                word.Dictionary_ID = dictionary.ID;
                                if(typeof word.ID !== 'undefined'){
                                    delete word.ID;
                                }
                                if(typeof word.OID === 'undefined'){
                                    word.OID = 0;
                                }
                                wordsToAdd.push(word);
                            }
                            return db.table(this.TABLE_WORD)
                                .bulkPut(wordsToAdd)
                        })     
                        .then(() => { return dictionary })
                }
                else {
                    return dictionary;
                }
            })
            .then(dictionary => {
                return this.setDictionaryWordsKnownCount(dictionary.ID)
                    .then(() => {
                        return this.setDictionaryWordsCount(dictionary.ID)
                    })
                    .then(() => {
                        return dictionary;
                    })
            });

    }


    changeLearnMode(dictionaryID, mode) {
        return new Promise((resolve, reject) => {
            if ([this.DIC_LEARNMODE_LEARN, this.DIC_LEARNMODE_LEFT, this.DIC_LEARNMODE_RIGHT].indexOf(mode) > -1) {
                this.getDictionaryById(dictionaryID)
                    .then((dictionary) => {
                        dictionary.LearnMode = mode;
                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                return APISync.syncDictionary(dictionary.ID);
                            })
                            .then(() => resolve(dictionary))
                    })
                    .catch(reject)
            }
            else {
                reject();
            }
        });
    }


    changeSortType(dictionaryID, sortType) {
        return new Promise((resolve, reject) => {
            if ([this.DIC_SORTTYPE_DEFAULT, this.DIC_SORTTYPE_RANDOM, this.DIC_SORTTYPE_ALPHA_WORD, this.DIC_SORTTYPE_ALPHA_TRANS].indexOf(sortType) > -1) {
                this.getDictionaryById(dictionaryID)
                    .then((dictionary) => {
                        dictionary.SortType = sortType;
                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                return APISync.syncDictionary(dictionary.ID);
                            })
                            .then(() => resolve(dictionary))
                    })
                    .catch(reject)
            }
            else {
                reject();
            }
            
        });

    }

    changeShowWords(dictionaryID, mode) {
        return this.getDictionaryById(dictionaryID)
            .then((dictionary) => {
                if([this.DIC_SHOWWORDS_ALL, this.DIC_SHOWWORDS_KNOWN, this.DIC_SHOWWORDS_UNKNOWN].indexOf(mode) > -1){
                    dictionary.ShowWords = mode;
                    return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                        .then(() => {
                            return APISync.syncDictionary(dictionary.ID);
                        })
                        .then(() => {return dictionary})
                }
                else {
                    throw new Error('Unsupporter ShowWords mode: ' + mode);
                }
            })
    }

    wordKnown(word) {

        return this.getWordById(word.ID)
            .then(word => {
                var _known = word.Known === 0 ? 1 : 0;
                word.Known = _known;
                return db.table(this.TABLE_WORD).update(word.ID, word)
                    .then(() => {
                        return this.setDictionaryWordsKnownCount(word.Dictionary_ID);
                    })
                    .then(() => {
                        return APISync.syncWordKnown(word);
                    })
                    .then(() => {
                        return _known === 0 ? false : true;
                    });
            })

    }

    clearKnown(dictionaryID) {
        return this.getDictionaryById(dictionaryID, true, false)
            .then(dictionary => {
                var _promisesToRun = [];
                for(let word of dictionary.Words){
                    if(word.Known > 0){
                        word.Known = 0;
                        _promisesToRun.push(db.table(this.TABLE_WORD).update(word.ID, word));
                    }
                }
                return Promise.all(_promisesToRun)
                    .then(() => {
                        return this.setDictionaryWordsKnownCount(dictionary.ID);
                    })
                    .then(() => {
                        return APISync.syncDictionaryKnownWordsClear(dictionary.ID);
                    })
            })
    }

    clearWords(dictionaryID) {
        return this.getDictionaryById(dictionaryID, true, true)
            .then(dictionary => {
                if(typeof dictionary.Words !== 'undefined'){
                    return db.table(this.TABLE_WORD)
                        .where({Dictionary_ID: dictionary.ID})   
                        .delete()
                }
                else {
                    throw new Error();
                }
            })
            .then(() => {
                return this.setDictionaryWordsCount(dictionaryID);
            })
            .then(() => {
                return this.setDictionaryWordsKnownCount(dictionaryID);
            })
            .then(() => {
                return APISync.syncDictionary(dictionaryID, true);
            })
    }

    rename(dictionaryID, name) {
        return this.getDictionaryById(dictionaryID, false, true)
            .then((dictionary) => {
                if (dictionary.Type !== this.DIC_TYPE_BOOKMARKS) {
                    dictionary.Name = name;
                    return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary);
                }
                else {
                    throw new Error("rename: there is a bookmark dictionary");
                }
            })
            .then(() => {
                return APISync.syncDictionary(dictionaryID);
            })
    }

    delete(dictionaryID) {
        return this.getDictionaryById(dictionaryID, true)
            .then((dictionary) => {
                if (dictionary && dictionary.Type !== this.DIC_TYPE_BOOKMARKS) {
                    return db.table(this.TABLE_WORD)
                        .where({Dictionary_ID: dictionaryID})   
                        .delete()
                        .then(() => {
                            return db.table(this.TABLE_DICTIONARY).delete(dictionary.ID)
                        })
                        .then(() => {
                            return APISync.syncDeleteDictionary(dictionary);
                        })
                }
                else {
                    throw new Error("delete: it's a bookmark dictionary");
                }
            })
    }

    share(dictionaryID, description) {
        return this.getDictionaryById(dictionaryID, false, true)
            .then((dictionary) => {
                if (dictionary && dictionary.Source === this.DIC_SOURCE_USER && dictionary.Type !== this.DIC_TYPE_BOOKMARKS) {
                    return APISync.APIRequest('shareDictionary', {dictionary: dictionary, description: description})
                }
                else {
                    throw new Error("share: can't share this dictionary");
                }
            })
    }

    shareToUserGetUsersList(dictionary){
        return APISync.APIRequest('shareDicToUserList', {dictionary: dictionary})
    }

    shareToUserAddUser(dictionary, email){
        return APISync.APIRequest('shareDicToUserAdd', {dictionary: dictionary, email: email})
    }

    shareToUserDelUser(OID){
        return APISync.APIRequest('shareDicToUserDelete', {OID: OID})
    }

    shareToUserAccept(oid, token){
        return APISync.APIRequest('shareDicToAccept', {oid: oid, token: token})
    }

    getSharedDictionaries(){
        return APISync.APIRequest('sharedDictionaries', {})
    }


    publishStateGet(dictionary){
        return APISync.APIRequest('publishGet', {dictionary: dictionary})
    }

    publishStateSave(dictionary, state){
        return APISync.APIRequest('publishSet', {dictionary: dictionary, state: state})
    }

    shareDicLink(oid, token){
        return APISync.APIRequest('shareDicLink', {oid: oid, token: token})
    }

    addWordToBookmarks(wordId) {
        return Promise.all([
                this.getWordById(wordId),
                this.getBookmarksDictionary()
            ])
            .then(response => {
                var word = response[0];
                var bookmarksDictionary = response[1];
                return this.addWordToDictionary(bookmarksDictionary.ID, word)
            })
    }

    delWordFromBookmarks(word) {
        return this.getBookmarksDictionary(true)
            .then(dictionary => {
                for(var bookmarkWord of dictionary.Words){
                    if(bookmarkWord.Word === word.Word){
                        return this.deleteWord(bookmarkWord.ID);
                    }
                }
            })
    }



    copyWordToDictionary(wordId, dictionaryID) {
        return Promise.all([
                this.getWordById(wordId),
                this.getDictionaryById(dictionaryID)
            ])
            .then(response => {
                var word = response[0];
                var dictionary = response[1];
                if (dictionary.Source === this.DIC_SOURCE_USER) {
                    return this.addWordToDictionary(dictionary.ID, word)
                }
                else {
                    throw new Error('copyWordToDictionary: can\'t copy word to selected dictionary');
                }
            })

    }

    changeWordDictionary(wordID, dictionaryID) {
        return Promise.all([
            this.getWordById(wordID, true),
            this.getDictionaryByWordId(wordID, true),
            this.getDictionaryById(dictionaryID, false, true)
        ])
            .then(response => {
                var word = response[0];
                var dicSource = response[1];
                var dicDest = response[2];
                if (dicSource.ID !== dicDest.ID) {

                    return this.getWordsByDictionaryId(dictionaryID)
                        .then(words => {
                            //sprawdzamy czy już nie ma takiego słowa, jeżeli jest to usuwamy
                            for(let _word of words){
                                if(_word.Word === word.Word){
                                    return this.deleteWord(_word.ID);
                                }
                            }
                            return true;
                        })
                        .then(() => {
                            word.Dictionary_ID = dicDest.ID;
                            return db.table(this.TABLE_WORD).update(word.ID, word);
                        })
                        .then(() => {
                            return APISync.syncWord(word.ID);
                        })
                        .then(() => {
                            return Promise.all([
                                this.setDictionaryWordsCount(dicSource.ID),
                                this.setDictionaryWordsCount(dicDest.ID)
                            ]);
                        })
                        .then(() => {
                            return Promise.all([
                                this.setDictionaryWordsKnownCount(dicSource.ID),
                                this.setDictionaryWordsKnownCount(dicDest.ID)
                            ]);
                        })
                }
                else {
                    return false;
                }
            })
    }

    editWord(wordObj, dictionaryID) {
        return new Promise((resolve, reject) => {
            if (wordObj.ID) {
                this.getWordById(wordObj.ID, true)
                    .then((word) => {
                        word.Word = wordObj.Word.replace(/\s\s+/g, ' ').trim();
                        word.Trans = [];
                        for (var idxTrans in wordObj.Trans) {
                            if (wordObj.Trans[idxTrans].Word) {
                                word.Trans.push({
                                    Word: wordObj.Trans[idxTrans].Word.replace(/\s\s+/g, ' ').trim(),
                                    Type: wordObj.Trans[idxTrans].Type ? wordObj.Trans[idxTrans].Type.replace(/\s\s+/g, ' ').trim() : ""
                                });
                            }
                        }

                        return db.table(this.TABLE_WORD).update(word.ID, word)
                            .then(() => {
                                return this.changeWordDictionary(word.ID, dictionaryID);
                            })
                            .then(() => {
                                return APISync.syncWord(word.ID)
                            })
                    })
                    .then(resolve)
                    .catch(reject)
            }
            else {
                reject();
            }
        });
    }


    deleteWord(wordId) {
        return this.getWordById(wordId, true)
            .then(word => {
                return db.table(this.TABLE_WORD).delete(word.ID)
                    .then(() => { return word })
            })
            .then(word => {
                return APISync.syncDeleteWord(word)
                    .then(() => { return word })
            })
            .then(word => {
                return this.setDictionaryWordsCount(word.Dictionary_ID)
                    .then(() => {
                        return this.setDictionaryWordsKnownCount(word.Dictionary_ID);
                    })
            })
    }


    addWordToDictionary(dictionaryID, word) {
        return Promise.all([
                this.getDictionaryById(dictionaryID, false, true),
                this.getWordsByDictionaryId(dictionaryID)
            ])
            .then(response => {
                var dictionary = response[0];
                var words = response[1];

                word = this.cloneObject(word);
                delete word.ID;
                word.OID = 0;
                word.Dictionary_ID = dictionary.ID;
                word.Known = 0;

                var wordExists = null;
                for(let _word of words){
                    if(_word.Word === word.Word){
                        wordExists = word;
                        wordExists.ID = _word.ID;
                        wordExists.OID = _word.OID;
                        break;
                    }
                }
                
                
                if(wordExists){
                    return db.table(this.TABLE_WORD).update(wordExists.ID, wordExists)
                        .then(() => { return wordExists.ID });
                }
                else {
                    return db.table(this.TABLE_WORD).add(word)
                }
                    
            })
            .then((wordID) => {
                return APISync.syncWord(wordID);
            })
            .then(() => {
                return this.setDictionaryWordsCount(dictionaryID);
            })
            .then(() => {
                return this.setDictionaryWordsKnownCount(dictionaryID);
            })
        
    }

    setDictionaryWordsCount(dictionaryID){
        return Promise.all([
            this.getDictionaryById(dictionaryID, false, false, true),
            this.getWordsByDictionaryId(dictionaryID)
        ])
            .then(response => {
                var dictionary = response[0];
                var words = response[1];
                dictionary.WordsCnt = words.length;
                return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                    .then(() => {
                        return dictionary;
                    })
            });
    }

    setDictionaryWordsKnownCount(dictionaryID){
        return Promise.all([
            this.getDictionaryById(dictionaryID, false, false, true),
            this.getWordsByDictionaryId(dictionaryID)
        ])
            .then(response => {
                var dictionary = response[0];
                var words = response[1];
                var knownCnt = 0;
                for(let word of words){
                    if(word.Known){
                        knownCnt++;
                    }
                }
                dictionary.WordsKnownCnt = knownCnt;
                return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                    .then(() => {
                        return dictionary;
                    })
            })
    }

    setSlideIndex(dictionaryID, index) {
        return this.getDictionaryById(dictionaryID)
            .then((dictionary) => {
                if(dictionary.CurrentSlide !== index){
                    dictionary.CurrentSlide = index;
                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                return APISync.syncDictionary(dictionaryID);
                            })
                }
                else {
                    return true;
                }
            })
    }

    importCSV(fileContent, dictionaryID){
        return this.getDictionaryById(dictionaryID, false, true)
            .then((dictionary) => {
                return APISync.APIRequest('csvImport', {csv: fileContent, dictionaryOID: dictionary.OID})
            })
            .then((response) => {
                return APISync.syncDictionariesFromServer();
            })
            .then(() => {
                return APISync.syncQueue();
            })
    }

    exportCSV(dictionaryID){
        return this.getDictionaryById(dictionaryID, false, true)
            .then((dictionary) => {
                if(dictionary.Source === this.DIC_SOURCE_USER){
                    return APISync.APIRequest('csvExport', {dictionaryOID: dictionary.OID})
                }  
                else {
                    throw new Error();
                }
            })
            .then(response => {
                if(response.csv){
                    //var csv = atob(response.csv);
                    var csv = response.csv;
                    var URL = window.URL || window.webkitURL;
                    var blob = new Blob([csv], { type: 'text/csv' });
                    var downloadUrl = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    var filename = 'dictionary_' + dictionaryID + '.csv';
                    if (typeof a.download === 'undefined') {
                        window.location = downloadUrl;
                    } else {
                        a.href = downloadUrl;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                    }
                }
                else {
                    throw new Error();
                }
            })
    }


    getSuggestedWord(query){
        return APISync.APIRequest('suggestWord', {q: query.trim()})
            .then(response => {
                return response.words
            })
    }

    cloneObject(object) {
        return JSON.parse(JSON.stringify(object));
    }

}

export default new ModelDictionary(); 