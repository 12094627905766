import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';
import { setUserDictionaries } from '../ReduxActions/AppActions';  

class DialogDicAdd extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            inputNewDictionaryName: ""
        }
        this.dialogShow = props.appDialogs.dialogDicAdd;
    }


    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-adddic');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicAdd && nextProps.appDialogs.dialogDicAdd !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogDicAdd;
            this.state.inputNewDictionaryName = '';
            Tools.dialogNavBackOpen(this.dialog);
            return true;
        }
        if(this.state.inputNewDictionaryName !== nextState.inputNewDictionaryName){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    actionNewDictionaryNameToState(e) {
        this.setState({ inputNewDictionaryName: e.target.value })
    }

    actionAddUserDictionaryGo(history) {
        if (this.state.inputNewDictionaryName) {
            ModelDictionary.addDictionary(this.state.inputNewDictionaryName)
                .then((dictionaryID) => {
                    Tools.toast(Lang.get("dic_dialog_adddic_done_toast"));
                    return ModelDictionary.getUserDictionaries()
                        .then((dictionaries) => {
                            this.props.dispatch(setUserDictionaries(dictionaries));
                            history.push('/dic/' + dictionaryID);
                        })
                })
                .catch(Tools.log);
            this.closeDialog();
        }
    }



    render() {
        return (
            <Route render={({ history }) => (
                <dialog className="mdl-dialog dialog dialog-adddic" data-dialog-id="adddic">
                    <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_adddic_title")}</h4>
                    <div className="mdl-dialog__content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" type="text" id="DIaud" required="required" value={this.state.inputNewDictionaryName} onChange={e => this.actionNewDictionaryNameToState(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionAddUserDictionaryGo(history) : null)} />
                            <label className="mdl-textfield__label" htmlFor="DIaud">{Lang.get("dic_dialog_adddic_name_label")}</label>
                        </div>
                    </div>
                    <div className="mdl-dialog__actions">
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionAddUserDictionaryGo(history)}>{Lang.get("dic_dialog_adddic_btn_ok")}</button>
                        <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_adddic_btn_cancel")}</button>
                    </div>
                </dialog>
            )} /> 
        )
    }
}

export default connect(function (store) {
    return {
        appDialogs: store.appDialogs
    }
})(DialogDicAdd)