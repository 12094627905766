export default function reducer(state={
    loader: false,
    alert: null,
    confirm: null,
    dicWordsCnt: {},
  }, action) {

    switch (action.type) {

      case "SET_LOADER": {
        return {...state, loader: action.payload}
      }
      case "SET_ALERT": {
        return {...state, alert: action.payload}
      }
      case "SET_CONFIRM": {
        var confirmObj = action.payload;
        confirmObj.ID = Math.random();
        return {...state, confirm: confirmObj}
      }
      case "SET_DIC_WORDS_CNT": {
        return {...state, dicWordsCnt: {...action.payload}}
      }
      case "SET_DIC_WORDS_CNT_CHANGE_KNOWN": {
        if(typeof state.dicWordsCnt.Known !== 'undefined'){
          state.dicWordsCnt.Known += action.payload;
          if(state.dicWordsCnt.Known < 0){
            state.dicWordsCnt.Known = 0;
          }
        }
        return {...state}
      }
      default: {
        return state;
      }
    }
}
