import React, { Component } from 'react';
import { Route } from 'react-router-dom';


class SearchInput extends Component{

    handleKeyDown = (e, history) => {
        if (e.key === 'Enter') {
            history.push("/catalog/" + this.refs.searchReference.value);
        }
    }

    render() {
        return <input ref="searchReference" className="mdl-textfield__input" type="search" id="IHsearch" defaultValue={this.props.searchValue} onKeyDown={(e) => this.handleKeyDown(e, this.props.history)} />
    }
}

class Header extends Component {

    render() {
        return (
            <Route render={({ history}) => (
                <header className="app-header mdl-layout__header">
                    <div className="mdl-layout__header-row">
                        <span className="mdl-layout-title">{this.props.title}</span>
                        <div className="mdl-layout-spacer"></div>
                        <div className="app-header__search mdl-textfield mdl-js-textfield mdl-textfield--expandable">
                            <label className="mdl-button mdl-js-button mdl-button--icon" htmlFor="IHsearch">
                                <i className="material-icons">search</i>
                            </label>
                            <div className="mdl-textfield__expandable-holder">
                                <SearchInput history={history} searchValue={this.props.searchValue} />
                                <label className="mdl-textfield__label" htmlFor="IHsearch"></label>
                            </div>
                        </div>
                        {this.props.menu}
                    </div>
                </header>
            )} />
        )
    }
}


export default Header;

