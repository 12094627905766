import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux"
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReduxStore from "./Models/ReduxStore"
import SyncProcess from './Components/SyncProcess';
import Config from './Config/Config';
import LocalStorage from './Models/LocalStorage';

import Init from './Controllers/Init';
import Main from './Controllers/Main';
import Login from './Controllers/Login';
import Register from './Controllers/Register';
import Static from './Controllers/Static';
import Catalog from './Controllers/Catalog';
import Settings from './Controllers/Settings';
import SettingsPasswordChange from './Controllers/SettingsPasswordChange';
import Error from './Controllers/Error';
import PasswordRemind from './Controllers/PasswordRemind';
import PasswordChange from './Controllers/PasswordChange';
import DeleteAccount from './Controllers/DeleteAccount';
import Welcome from './Controllers/Welcome';
import ShareAccept from './Controllers/ShareAccept';
import ShareAcceptProcess from './Controllers/ShareAcceptProcess';
import ShareLink from './Controllers/ShareLink';
import ShareLinkProcess from './Controllers/ShareLinkProcess';
import InitWithDic from './Controllers/InitWithDic';
import SharedDictionaries from './Controllers/SharedDictionaries';
import * as serviceWorker from './serviceWorker';

import './assets/css/fonts.css';
import './assets/css/material.teal-blue.min.css';
import './assets/css/getmdl-select.min.css';
import './assets/css/dialog-polyfill.css';
import './assets/css/main.scss';
import './assets/css/dark-theme.scss';

/* Google Analytics */
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
const GAID = Config.get("GAID");
if(GAID){
  ReactGA.initialize(GAID);
  const history = createBrowserHistory();
  GATrack(document.location);
  history.listen((location) => {
      GATrack(location);
  });
  function GATrack(location){
    var trackingPath = location.pathname+location.hash;
    trackingPath = trackingPath.replace(/^(.*\/dic\/)\d+/, '$1');
    ReactGA.set({'dimension1': 'online'});
    ReactGA.set({'dimension2': Config.get("appVersion")});
    ReactGA.pageview(trackingPath);
  }
}

ReactDOM.render(
  <Provider store={ReduxStore}>
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Init} />
        <Route exact path="/dic/:id" component={Main} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/:email" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/:email" component={Register} />
        <Route exact path="/static/:id" component={Static} />
        <Route exact path="/catalog" component={Catalog} />
        <Route exact path="/catalog/:query" component={Catalog} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/settings-password-change" component={SettingsPasswordChange} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/password-remind" component={PasswordRemind} />
        <Route exact path="/password-change/:userOID,:token" component={PasswordChange} />
        <Route exact path="/delete-account/:userOID,:token" component={DeleteAccount} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/share-accept/:OID,:token,:email" component={ShareAccept} />
        <Route exact path="/share-accept-process/:OID,:token,:email" component={ShareAcceptProcess} />
        <Route exact path="/share-link/:OID,:token" component={ShareLink} />
        <Route exact path="/share-link-process/:OID,:token" component={ShareLinkProcess} />
        <Route exact path="/shared-dictionaries" component={SharedDictionaries} />
        <Route exact path="/init-dic/:oid" component={InitWithDic} />
      </Switch>
    </HashRouter>
    <SyncProcess />
  </Provider>
  , document.getElementById('root')
);   



// rejestracja service-worker
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          // jeżeli została aktywowana nowa wersja service-worker, odśwież stronę
          // https://github.com/facebook/create-react-app/issues/5316
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    } 
  }
});


// Obsługa motywu ciemnego 
// Zależało mi, aby klasa css 'dark-theme' była dla elementu <html>, więc nie mogłem to zapiąć w komponencie reacta i muszę operować na DOM
if(LocalStorage.getSettingDarkTheme()){
  document.documentElement.classList.add('dark-theme');
  document.querySelector("meta[name=theme-color]").setAttribute("content", '#333333');
}


// wymuszamy sprawdzanie zmian service-worker.js co X
/* przeniesiona kontrola do API i SyncProcess
setInterval(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.update();
      }
    })
  }
}, 5 * 60 * 1000);
*/


