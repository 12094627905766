import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';

class PasswordRemindPage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputEmail: "",
            mode: 1, // 1 - formularz z mailem, 2 - wysłanie maila, 3 - nie znaleziono adresu email
            error: false
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    changeInputEmail(e){
        this.setState({inputEmail: e.target.value});
    }

    actionSendMail(history){
        if(Tools.onLine()){
            if(this.state.inputEmail && Tools.isEmailValid(this.state.inputEmail)){
                Tools.loader(this.props, true);
                ModelUser.passwordRemind(this.state.inputEmail)
                    .then((response) => {
                        Tools.loader(this.props, false);
                        if(response.status){
                            this.setState({mode: 2});
                        }
                        else {
                            this.setState({error: 1});
                        }
                        
                    })
                    .catch(() => {
                        Tools.loader(this.props, false);
                        history.push('/error');
                    });
            }
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

   


    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("remindpassword_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >

                    {this.state.mode === 1 && 
                    <div className="page-login page-login--passwordremind compact-content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" name="email" type="email" id="f-email" value={this.state.inputEmail} onChange={e => this.changeInputEmail(e)} required />
                            <label className="mdl-textfield__label" htmlFor="f-email">{Lang.get("remindpassword_email")}</label>
                            {this.state.error && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("remindpassword_error")}</span>}
                        </div>
                        <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionSendMail(history)}>{Lang.get("remindpassword_btn")}</button>
                    </div>
                    }

                    {this.state.mode === 2 &&
                        <div className="compact-content"><p>{Lang.get("remindpassword_sent")}</p></div>
                    }

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(PasswordRemindPage)
