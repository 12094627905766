import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, withRouter  } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelDictionary from './../Models/ModelDictionary';
import APISync from './../Models/APISync';
import WelcomeContent from '../Components/WelcomeContent';
import { 
    setUserSharedDictionaries
} from '../ReduxActions/AppActions';

class ShareAcceptProcessPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OID: props.match.params.OID,
            token: props.match.params.token,
            email: props.match.params.email,
            status: 0,
            sharedDictionaryID: 0
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidMount() {
        this.actionAccept();
    }
    
    actionAccept(){
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelDictionary.shareToUserAccept(parseInt(this.state.OID, 10), this.state.token)
                .then((response) => {
                    Tools.loader(this.props, false);
                    var status = response.status;
                    if(status === 5){
                        Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                        return APISync.syncDictionariesFromServer()
                            .then(() => {
                                return APISync.syncQueue();
                            })
                            .then(() => {
                                return ModelDictionary.getUserSharedDictionaries()
                            })
                            .then((dictionaries) => {
                                this.props.dispatch(setUserSharedDictionaries(dictionaries));
                                Tools.loader(this.props, false);
                                this.setState({status: response.status});
                            })
                            .then(() => {
                                if(response.sharedDicOID){
                                    return ModelDictionary.getDictionaryByOID(response.sharedDicOID)
                                        .then(dictionary => {
                                            this.setState({sharedDictionaryID: dictionary.ID})
                                        })
                                }
                                else {
                                    return true;
                                }
                            })
                    }
                    else {
                        this.setState({status: response.status});
                    }
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                    this.props.history.push('/error');
                });
                
        }
        else {
            Tools.confirm(
                this.props, 
                () => {
                    this.actionAccept();
                }, 
                Lang.get("dialog_alert_msg_offline"),
                "",
                Lang.get("dialog_alert_btn_retry"),
                true
            )
            
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }


    actionLogin(history, mail){
        history.push('/login/' + mail);
    }

    actionRegister(history, mail){
        history.push('/register/' + mail);
    }



    render() {

        return (
            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("shareaccept_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    <div className="page-share-accept compact-content">

                        {this.state.status === 1 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_1")} {this.state.email}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionLogin(history, this.state.email)}>{Lang.get("shareaccept_accept_login_btn")}</button>
                                </div>
                            </div>
                        }
                        {this.state.status === 2 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_2")}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionRegister(history, this.state.email)}>{Lang.get("shareaccept_accept_register_btn")}</button>
                                </div>

                                <div className="welcome-content">
                                    <WelcomeContent />
                                </div>
                            </div>
                        }
                        {this.state.status === 3 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_3")} {this.state.email}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionLogin(history, this.state.email)}>{Lang.get("shareaccept_accept_login_btn")}</button>
                                </div>
                            </div>
                        }
                        {this.state.status === 4 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_4")}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionRegister(history, this.state.email)}>{Lang.get("shareaccept_accept_register_btn")}</button>
                                </div>
                            </div>
                        }
                        {this.state.status === 5 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_5")}
                                    {this.state.sharedDictionaryID > 0 &&
                                        <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => {history.push('/dic/'+this.state.sharedDictionaryID)}}>
                                            {Lang.get("shareaccept_accept_dic_btn")}
                                        </button>
                                    }
                                </div>
                            </div>
                        }
                        {this.state.status === 6 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_6")}
                                </div>
                            </div>
                        }
                        {this.state.status === 7 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_7")} {this.state.email}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionLogin(history, this.state.email)}>{Lang.get("shareaccept_accept_login_btn")}</button>
                                </div>
                            </div>
                        }
                        {this.state.status === 8 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_8")}
                                    <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionRegister(history, this.state.email)}>{Lang.get("shareaccept_accept_register_btn")}</button>
                                </div>
                            </div>
                        }
                        {this.state.status === 9 && 
                            <div>
                                <div className="info-box">
                                    {Lang.get("shareaccept_accept_status_9")}
                                </div>
                            </div>
                        }

                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(ShareAcceptProcessPage))
