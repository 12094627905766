import React, { Component } from 'react';
import { connect } from "react-redux";
import Tools from '../Misc/Tools';
import Config from '../Config/Config';
import Layout from '../Components/Layout';

class StaticPage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.setPageContent(nextProps);
    }


    componentDidUpdate(){
        Tools.mdlUpgrade();
    }

    setPageContent(props){
        var title = null;
        var content = null;

        if(props.match && props.match.params.id){
            switch(props.match.params.id){
                default:
                    break;
            }
        }

        if(!title){
            props.history.push("/");
        }

        if(this.state.title !== title){
            this.setState({title: title, content: content});
            return true;
        }

        return false;
    }



    render() {

        return (

            <Layout
                headerTitle={Config.get("appName")}
                disableMenuItemSelect={true}
            >
                <div className={"page-static page-static--" + this.props.match.params.id + " compact-content"}>
                    <h1 className="page-title">{this.state.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                </div>
            </Layout>

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(StaticPage)
