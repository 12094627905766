import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

import { 
    setDialogDicShare,
} from '../ReduxActions/AppActions';

class DialogDicShareToUser extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            dictionary: null,
            loader: false,
            email: "",
            emailInvalid: false,
            shares: [],
            shareLink: "",
        }
        this.dialogShow = props.appDialogs.dialogDicShareToUser;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-share-to-user');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicShareToUser && nextProps.appDialogs.dialogDicShareToUser !== this.dialogShow){
            this.setState({
                dictionary: null,
                shares: [],
                shareLink: "",
            });
            this.dialogShow = nextProps.appDialogs.dialogDicShareToUser;
            if(this.props.appState.dictionary && this.props.appState.dictionary.ID){
                ModelDictionary.getDictionaryById(this.props.appState.dictionary.ID, false, true)
                    .then((dictionary) => {
                        if(dictionary.OID){
                            this.setState({dictionary: dictionary}, () => {
                                Tools.dialogNavBackOpen(this.dialog);
                                this.getSharesList();
                            })
                        }
                        else {
                            Tools.alert(this.props, Lang.get("dic_dialog_sharetouser_not_sync_yet"));
                        }
                    })
                    .catch(Tools.log)
            }
            
        }
        if(this.state.email !== nextState.email){
            return true;
        }
        if(this.state.shares !== nextState.shares){
            return true;
        }
        if(this.state.loader !== nextState.loader){
            return true;
        }
        if(this.state.emailInvalid !== nextState.emailInvalid){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    changeInputEmail(e) {
        this.setState({ email: e.target.value, emailInvalid: false });
    }

    actionShareToLib(){
        this.closeDialog();
        this.props.dispatch(setDialogDicShare());
    }

    actionShareGo() {
        if(!this.state.loader){
            if(Tools.isEmailValid(this.state.email)){
                this.setState({ loader: true, emailInvalid: false });
                ModelDictionary.shareToUserAddUser(this.state.dictionary, this.state.email)
                    .then((response) => {
                        if(response.status === 1){
                            this.setState({ email: "" });
                            this.getSharesList();
                            this.localToast(Lang.get("dic_dialog_sharetouser_user_add_msg_done"));
                        }
                        else if (response.status === 2){
                            this.localToast(Lang.get("dic_dialog_sharetouser_user_add_exists"));
                        }
                        else {
                            this.setState({ email: "", loader: false });
                        }
                    })
                    .catch((e) => {
                        this.setState({ loader: false });
                        Tools.alert(this.props, Lang.get("dic_dialog_sharetouser_user_add_msg_error"));
                        Tools.log(e);
                    })
            }
            else {
                this.setState({ emailInvalid: true });
            }
        }
    }

    localToast(msg){
        var toast = document.querySelector('#dicshare-toast');
        if(toast && toast.MaterialSnackbar){
            toast.MaterialSnackbar.showSnackbar({message: msg});
        }

    }

    actionDelete(OID) {
        Tools.confirm(
            this.props, 
            () => {
                this.setState({ loader: true });
                ModelDictionary.shareToUserDelUser(OID)
                    .then((response) => {
                        this.getSharesList();
                    })
                    .catch((e) => {
                        this.setState({ loader: false });
                        Tools.log(e);
                    })
            }, 
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_header"),
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_msg"),
            Lang.get("dic_dialog_sharetouser_user_list_delete_confirm_btn")
        )
    }

    actionShareLink(){
        this.shareLinkInput.select();
        document.execCommand('copy');
        this.localToast(Lang.get("dic_dialog_sharetouser_bylink_copied"));
    }


    actionShareLinkNative(){
        if (navigator.share) {
            navigator.share({
                title: Lang.get("dic_dialog_sharetouser_bylink_share_title_prefix") + " " + this.state.dictionary.Name,
                text: Lang.get("dic_dialog_sharetouser_bylink_share_title_prefix") + " " + this.state.dictionary.Name,
                url: this.state.shareLink
            })
            .catch(Tools.log);
        }
    }
    

    getSharesList(){
        this.setState({ loader: true });
        ModelDictionary.shareToUserGetUsersList(this.state.dictionary)
            .then((response) => {
                if(typeof response.shares !== 'undefined'){
                    this.setState({ shares: response.shares, shareLink: document.location.origin + response.shareLink, loader: false });
                }
            })
            .catch((e) => {
                this.setState({ loader: false });
                Tools.log(e);
            })
    }


    render() {
        const { shares } = this.state;
        return (

            <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-share-to-user" data-dialog-id="share-share-to-user">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_sharetouser_title")}</h4>
                <div className="mdl-dialog__content">
                    <p>{Lang.get("dic_dialog_sharetouser_lib_description")}</p>
                    <div className="button-center">
                        <button onClick={() => this.actionShareToLib()} className="mdl-button mdl-js-button mdl-js-ripple-effect">{Lang.get("dic_dialog_sharetouser_lib_btn")}</button>
                    </div>
                    <div className="users">
                        <h4>{Lang.get("dic_dialog_sharetouser_user_header")}</h4>

                        {shares && shares.length > 0 &&
                        <table className="shared-list mdl-data-table mdl-js-data-table mdl-shadow--2dp">
                            <thead>
                                <tr>
                                    <th>{Lang.get("dic_dialog_sharetouser_user_list_col_email")}</th>
                                    <th>{Lang.get("dic_dialog_sharetouser_user_list_col_status")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {shares.map((share, index) => 
                                    <tr key={"stul-" + index}>
                                        <td><span className="nick">{share.Nick}</span>{share.Mail}</td>
                                        <td className={"status status-" + share.Status}>{Lang.get("dic_dialog_sharetouser_user_list_status_" + share.Status)}</td>
                                        <td>
                                            <button className="mdl-button mdl-js-button mdl-button--primary" onClick={e => this.actionDelete(share.OID)}>
                                                <i className="material-icons">delete</i>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                        }

                        {this.state.loader && <div className="mdl-progress mdl-js-progress mdl-progress__indeterminate"></div>}
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" type="email" id="dsto-mail" value={this.state.email} onChange={e => this.changeInputEmail(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionShareGo() : null)} required />
                            <label className="mdl-textfield__label" htmlFor="dsto-mail">{Lang.get("dic_dialog_sharetouser_user_email")}</label>
                            {this.state.emailInvalid && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("dic_dialog_sharetouser_user_email_invalid")}</span>}
                        </div>

                    </div>

                    <div className="mdl-dialog__actions">
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareGo()}>{Lang.get("dic_dialog_sharetouser_user_btn_send")}</button>
                    </div>
                    <div className="disclaimer" dangerouslySetInnerHTML={{ __html: Lang.get("dic_dialog_sharetouser_disclaimer")}}></div>
                    
                    <div className="share-link">
                        <p>{Lang.get("dic_dialog_sharetouser_bylink")}</p>
                        <input readOnly className="mdl-textfield__input" ref={(input) => this.shareLinkInput = input} value={this.state.shareLink} />
                        <div className="mdl-dialog__actions">
                            {navigator.share !== undefined && <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareLinkNative()}>{Lang.get("dic_dialog_sharetouser_bylink_share_btn")}</button>}
                            <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareLink()}>{Lang.get("dic_dialog_sharetouser_bylink_btn")}</button>
                        </div>
                    </div>
                    
                </div>

                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_sharetouser_user_btn_cancel")}</button>
                </div>

                <div id="dicshare-toast" className="mdl-js-snackbar mdl-snackbar">
                    <div className="mdl-snackbar__text"></div>
                    <button className="mdl-snackbar__action" type="button"></button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicShareToUser)