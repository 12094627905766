import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import WelcomeContent from '../Components/WelcomeContent';
import LocalStorage from './../Models/LocalStorage';

class WelcomePage extends Component {

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidMount() {
        if(!LocalStorage.getTipStorageInfo()){
            setTimeout(() => {
                Tools.toast(
                    Lang.get("cookie_policy_toast"), 
                    Lang.get("cookie_policy_toast_btn"),
                    () => {
                        Tools.toastClear();
                        LocalStorage.setTipStorageInfo();
                    },
                    10000000,
                    true
                );
            }, 2000);
        }
    }
    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    


    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("welcome_title")}
                    disableMenuItemSelect={true}
                >

                    <div className="welcome-content compact-content">
                        <WelcomeContent />
                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(WelcomePage)
