import React, { Component } from 'react';
import { connect } from "react-redux"
import { Link } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';


import { 
    setDialogDicAdd,
} from '../ReduxActions/AppActions';

class WelcomeContent extends Component {


    actionAddUserDictionary() {
        this.props.dispatch(setDialogDicAdd());
    }

    render() {
        return (
            <div>
                <div className="welcome-content__header">
                    <h1>Words<span>Bucket</span></h1>
                    <h2>{Lang.get("app_subname")}</h2>
                    <h3>{Lang.get("welcome_tagline")}</h3>
                </div>

                <div className="welcome-content__block welcome-content__block--hello">
                    <div className="welcome-content__label">
                        <div>{Lang.get("welcome_label_welcome")}</div>
                        <span><i className="material-icons">school</i></span>
                    </div>
                    <p>{Lang.get("welcome_default_dictionaries")}</p>
                    <div className="button-c">
                        <Link to="/catalog">
                            <button className="mdl-button mdl-js-button mdl-js-ripple-effect">
                                {Lang.get("welcome_btn_catalog")}
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="welcome-content__block welcome-content__block--csv">
                    <div className="welcome-content__label">
                        <div>{Lang.get("welcome_label_dictionaries")}</div>
                        <span><i className="material-icons">note</i></span>
                    </div>
                    <p>{Lang.get("welcome_own_dictionaries")}</p>
                    <p>{Lang.get("welcome_csv")}</p>
                    {Tools.isMobileDevice() && <p dangerouslySetInnerHTML={{__html: Lang.get("welcome_browser")}}></p>}
                    <div className="button-c">
                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionAddUserDictionary()}>
                            {Lang.get("welcome_add_dic")}
                        </button>
                    </div>
                </div>

                <div className="welcome-content__block welcome-content__block--register">
                    <div className="welcome-content__label">
                        <div>{Lang.get("welcome_label_register")}</div>
                        <span><i className="material-icons">person</i></span>
                    </div>
                    <p>{Lang.get("welcome_register")}</p>
                    <div className="button-c">
                        <Link to="/login">
                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect">
                            {Lang.get("welcome_btn_register")}
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(function (store) {
    return {
        appTools: store.appTools
    }
})(WelcomeContent)
