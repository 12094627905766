class ProfilerClass {

    LAST_TS = 0;
    LAST_MSG = '';
    MSG_STACK = [];

    _intervalHandler = null;

    reset(){
        this.LAST_TS = 0;
        this.LAST_MSG = 0;
        this.MSG_STACK = [];
    }

    log(msg){
        if(process && process.env && process.env.NODE_ENV === 'development'){
            if(this.LAST_TS){
                var logItem = {}
                logItem.prev = this.LAST_MSG;
                logItem.current = msg;
                logItem.time = (new Date().getTime() - this.LAST_TS) / 1000;
                this.MSG_STACK.push(logItem)
            }

            this.LAST_TS = new Date().getTime();
            this.LAST_MSG = msg;

            if(!this._intervalHandler){
                //this._intervalHandler = setInterval(() => {this.show()}, 5000);
            }
        }
    }

    show(){
        if(this.MSG_STACK.length > 0){    
            console.table(this.MSG_STACK)
            var _timeSum = 0;
            for(var logItem of this.MSG_STACK){
                _timeSum += logItem.time;
            }
            console.log('SUMMARY: ' + _timeSum);
            this.reset();
        }
    }

}

export let Profiler = new ProfilerClass();