import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser.js';
import LocalStorage from './../Models/LocalStorage';
import WordList from '../Components/WordList';
import Lang from '../Misc/Lang';
import WordsCnt from '../Components/WordsCnt';
import Tools from '../Misc/Tools';

import { 
    setDialogWordsShow,
    setDictionary,
    setDialogWordAdd,
    setDialogWordAddCSV,
    setDialogLargeList
} from '../ReduxActions/AppActions';


class DictionaryList extends Component {

    _tipHiddenShowed = false;

    constructor(props) {
        super(props);
        this.state = {
            learnMode: null
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appState.dictionary && this.state.learnMode !== nextProps.appState.dictionary.LearnMode){
            this.state.learnMode = nextProps.appState.dictionary.LearnMode;
        }
        return true;
    }

    componentDidUpdate(){
        if(Tools.isMobileDevice() && !LocalStorage.getTipDoneLargeList() && this.props.appState.dictionary && this.props.appState.dictionary.Words.length > 200){
            this.props.dispatch(setDialogLargeList());
        }
    }

    

    actionChangeLearnMode(mode) {
        this.setState({learnMode: mode})

        // brzydkie ale szybkie na liście!!!
        var _wordListDOM = document.querySelector('.words-list');
        if(_wordListDOM){
            _wordListDOM.classList.remove('words-list--hide-left');
            _wordListDOM.classList.remove('words-list--hide-right');
            _wordListDOM.classList.add('words-list--hide-' + mode);
            document.querySelectorAll('.words-list tr').forEach(function(item) {
                item.classList.remove('show');
            });
        }

        ModelDictionary.changeLearnMode(this.props.appState.dictionary.ID, mode)

        if(!this._tipHiddenShowed && mode !== ModelDictionary.DIC_LEARNMODE_LEARN && !LocalStorage.getTipDoneHiddenWords()){
            this._tipHiddenShowed = true;
            Tools.toast(Lang.get("dic_learnmode_toast_tip_hidden"), Lang.get("dic_learnmode_toast_tip_hidden_btn"), () => {
                LocalStorage.setTipDoneHiddenWords();
				Tools.toastClear();
            }, 5000);
        }
    }

    /* ----- */
    actionChangeShowWords() {
        this.props.dispatch(setDialogWordsShow());
    }

    actionChangeShowWordsSelect(mode) {
        Tools.loader(this.props, true);
        ModelDictionary.changeShowWords(this.props.appState.dictionary.ID, mode)
            .then(() => {
                return this.refreshDictionaryState();
            })
            .catch(Tools.log)
    }

    actionShuffle() {
        Tools.loader(this.props, true);
        ModelDictionary.changeSortType(this.props.appState.dictionary.ID, ModelDictionary.DIC_SORTTYPE_RANDOM)
            .then(() => {
                return ModelDictionary.getCurrentDictionarySorted(true)
            })
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
            .catch(Tools.log)
    }

    actionAddWord() {
        this.props.dispatch(setDialogWordAdd({uploadCsvCallback: this.actionAddWordCSV.bind(this)}));
    }

    actionAddWordCSV() {
        if(Tools.onLine()){
            ModelUser.getUserOID()
                .then((userOID => {
                    if(userOID){
                        this.props.dispatch(setDialogWordAddCSV());
                    }
                    else {
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_login_required"));
                    }
                }))
                .catch(Tools.log)
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionClearKnown() {
        Tools.loader(this.props, true);
        ModelDictionary.clearKnown(this.props.appState.dictionary.ID)
            .then(() => {
                return ModelDictionary.getCurrentDictionarySorted()
            })
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
            .then(() => {
                Tools.toast(Lang.get("dic_dialog_clearknown_toast"), null, null, 3500);
            })
            .then(() => {
                return this.changeDrawerWordsCnt();
            })
            .catch(Tools.log)
    }



    render() {

        const { appState } = this.props;
        const { dictionary } = appState;

        return (
            <div>

                {dictionary && dictionary.Words.length > 0 &&

                    <div>

                        <div className="toggle-hide">
                            <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_LEARN ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("learn")}>{Lang.get("dic_learnmode_learn")}</button>
                            <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_LEFT ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("left")}>{Lang.get("dic_learnmode_left")}</button>
                            <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_RIGHT ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("right")}>{Lang.get("dic_learnmode_right")}</button>
                        </div>

                        <div className="dic-toolbar">
                            <WordsCnt />
                            <div className="dic-toolbar__tools">
                                {/*
                                <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                                    <input tabIndex='-1' type="text" defaultValue={Lang.get("dic_dialog_showwords_opt_" + dictionary.ShowWords)} className="mdl-textfield__input" id="DIcw" readOnly />
                                    <input type="hidden" />
                                    <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                    <label htmlFor="DIcw" className="mdl-textfield__label">{Lang.get("dic_dialog_showwords_title")}</label>
                                    <ul htmlFor="DIcw" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                        {(dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_ALL ?
                                            (<li data-selected className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_ALL)}>{Lang.get("dic_dialog_showwords_opt_all")}</li>)
                                            :
                                            (<li className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_ALL)}>{Lang.get("dic_dialog_showwords_opt_all")}</li>)
                                        )}
                                        {(dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN ?
                                            (<li data-selected className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_UNKNOWN)}>{Lang.get("dic_dialog_showwords_opt_unknown")}</li>)
                                            :
                                            (<li className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_UNKNOWN)}>{Lang.get("dic_dialog_showwords_opt_unknown")}</li>)
                                        )}
                                        {(dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN ?
                                            (<li data-selected className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_KNOWN)}>{Lang.get("dic_dialog_showwords_opt_known")}</li>)
                                            :
                                            (<li className="mdl-menu__item" onClick={() => this.actionChangeShowWordsSelect(ModelDictionary.DIC_SHOWWORDS_KNOWN)}>{Lang.get("dic_dialog_showwords_opt_known")}</li>)
                                        )}
                                    </ul>
                                </div>
                                */}
                                <button onClick={() => this.actionChangeShowWords()} className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab">
                                    <i className="material-icons">visibility</i>
                                </button>
                                <button onClick={() => this.actionShuffle()} className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab">
                                    <i className="material-icons">shuffle</i>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                <WordList />

                {dictionary && dictionary.WordsCnt > 0 && dictionary.Words.length <= 0 && 
                    <div className="no-words-to-show">
                        <p>{Lang.get("dic_nowordstoshow")}</p>
                        <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionChangeShowWords()}>
                            <i className="material-icons">visibility</i> {Lang.get("dic_nowordstoshow_btn_change")}
                        </button>
                        {dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN &&
                            <div>
                                <p>{Lang.get("dic_nowordstoshow_or_clearknown")}</p>
                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionClearKnown()}>
                                    <i className="material-icons">clear_all</i> {Lang.get("dic_nowordstoshow_btn_clearknown")}
                                </button>
                            </div>
                        }
                    </div>
                }

                {dictionary && (dictionary.WordsCnt === 0 && dictionary.Words.length === 0) && 
                    <div>
                        <div className="no-words-to-show">
                            <p>{Lang.get("dic_empty")}</p>
                            {dictionary.Source === ModelDictionary.DIC_SOURCE_USER &&
                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionAddWord()}>
                                    <i className="material-icons">add</i> {Lang.get("dic_empty_btn_add_word")}
                                </button>
                            }
                        </div>

                        {/*
                        <div className="add-from-catalog">
                            <p>{Lang.get("dic_empty_to_catalog")}</p>
                            <Link to="/catalog">
                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect">
                                    <i className="material-icons">add</i> {Lang.get("dic_empty_to_catalog_btn")}
                                </button>
                            </Link>
                        </div>
                        */}
                    </div>
                }

                {dictionary && dictionary.WordsCnt > 0 && dictionary.Source === ModelDictionary.DIC_SOURCE_USER &&
                    <div className="dictionary__add-word">
                        <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionAddWord()}>
                            <i className="material-icons">add</i> {Lang.get("dic_empty_btn_add_word")}
                        </button>
                    </div>
                }
            </div>
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(DictionaryList);
