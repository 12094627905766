import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, withRouter } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Loader from './../Components/Loader';
import Toast from './../Components/Toast';
import Alert from './../Components/Alert';
import Confirm from './../Components/Confirm';
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './../Models/LocalStorage.js';
import Lang from '../Misc/Lang';

class Init extends Component {


    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }
    
    componentDidMount() {
        this.sync();
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }

    sync(){

        if(LocalStorage.getDataInitialized()){
            this.props.history.push('/dic/'+ModelDictionary.getCurrentDictionaryID());
        }
        else {
            if(Tools.onLine()){
                Tools.loader(this.props, true, Lang.get("sync_data_msg"));

                ModelDictionary.initializeDB()
                    .then((done) => {
                        Tools.loader(this.props, false);
                        this.props.history.push('/welcome');
                    })
                    .catch(e => {
                        Tools.loader(this.props, false);
                        this.props.history.push('/error');
                        Tools.log(e)
                    })
            }
            else {
                setTimeout(() => {
                    Tools.confirm(
                        this.props, 
                        () => {
                            this.sync();
                        }, 
                        Lang.get("dialog_alert_msg_offline"),
                        "",
                        Lang.get("dialog_alert_btn_retry"),
                        true
                    );
                }, 1000)
            }
        }

    }


    render() {
        return (

            <Route render={({ history }) => (
                <div>
                    <Loader />
                    <Toast />
                    <Alert />
                    <Confirm />
                </div>
            )} /> 

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(Init))
