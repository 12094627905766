import React, { Component } from 'react';
import { connect } from "react-redux";
import { setDictionary } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordsShow extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.dialogShow = props.appDialogs.dialogWordsShow;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-showwords');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordsShow && nextProps.appDialogs.dialogWordsShow !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogWordsShow;
            Tools.dialogNavBackOpen(this.dialog);
        }
        return true;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }


    actionWordShowChange(e) {
        Tools.loader(this.props, true);
        ModelDictionary.changeShowWords(this.props.appState.dictionary.ID, e.target.value)
            .then(() => {
                return ModelDictionary.getCurrentDictionary()
            })
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
            .catch(Tools.log)
        this.closeDialog();
    }



    render() {
        const { appState } = this.props;
        const { dictionary } = appState;

        return (
            <dialog className="mdl-dialog dialog dialog-showwords">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_showwords_title")}</h4>
                <div className="mdl-dialog__content">
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSWopt-1">
                        <input type="radio" id="DSWopt-1" className="mdl-radio__button" value={ModelDictionary.DIC_SHOWWORDS_ALL} checked={(dictionary && dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_ALL) ? true : false} onChange={this.actionWordShowChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_showwords_opt_all")}</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSWopt-2">
                        <input type="radio" id="DSWopt-2" className="mdl-radio__button" value={ModelDictionary.DIC_SHOWWORDS_UNKNOWN} checked={(dictionary && dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN) ? true : false} onChange={this.actionWordShowChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_showwords_opt_unknown")}</span>
                    </label>
                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="DSWopt-3">
                        <input type="radio" id="DSWopt-3" className="mdl-radio__button" value={ModelDictionary.DIC_SHOWWORDS_KNOWN} checked={(dictionary && dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN) ? true : false} onChange={this.actionWordShowChange.bind(this)} />
                        <span className="mdl-radio__label">{Lang.get("dic_dialog_showwords_opt_known")}</span>
                    </label>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_showwords_btn_cancel")}</button>
                </div>
            </dialog>
        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordsShow)