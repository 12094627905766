import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Link, withRouter } from 'react-router-dom';
import LocalStorage from './../Models/LocalStorage.js';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser';
import Config from '../Config/Config';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';
import GooglePlayLogo from '../assets/gfx/google-play.svg';
import { setMultipleData, setDialogDicAdd, setDictionary } from '../ReduxActions/AppActions';

class Drawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogs: {},
        }
        this.initData();
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }


    initData() {
        if(!LocalStorage.getDataInitialized()){
            this.props.history.push('/');
        }
        else {
            this.appPropsDispatch();
        }
    }

    appPropsDispatch() {
        return Promise.all([
            ModelUser.getUser(),
            ModelDictionary.getAllUserDictionaries(),
            ModelDictionary.getBookmarksDictionary(true),
        ])
            .then(results => {
                var user = results[0];
                var bookmarksDictionary = results[2];
                var userDictionaries = results[1].user;
                var userCatalogDictionaries = results[1].catalog;
                var userSharedDictionaries = results[1].shared;
                this.props.dispatch(setMultipleData({
                    user: user,
                    bookmarksDictionary: bookmarksDictionary,
                    userDictionaries: userDictionaries,
                    userCatalogDictionaries: userCatalogDictionaries,
                    userSharedDictionaries: userSharedDictionaries,
                }));
            })
            .catch(Tools.log)

    }


    handleClickNoDic() {
        this.props.dispatch(setDictionary(null));
        this.handleClick();
    }

    handleClick() {
        if(typeof document.querySelector('.mdl-layout').MaterialLayout !== 'undefined'){
            document.querySelector('.mdl-layout').MaterialLayout.toggleDrawer();
        }
        if(typeof document.querySelector('.mdl-layout__obfuscator').classList !== 'undefined'){
            document.querySelector('.mdl-layout__obfuscator').classList.remove('is-visible');
        }
        if(typeof document.querySelector('.mdl-layout__drawer').classList !== 'undefined'){
            document.querySelector('.mdl-layout__drawer').classList.remove('is-visible');
        }
    }

    showLoader(){
        Tools.loader(this.props, true);
    }
    

    actionAddUserDictionary() {
        this.props.dispatch(setDialogDicAdd());
    }


    actionLogout(history) {
        ModelUser.logout()
            .then(() => {
                setTimeout(() => {
                    Tools.toast(Lang.get("logout_toast"));    
                }, 1000);
            })
            .then(() => {
                //this.props.history.push('/dic/'+ModelDictionary.getCurrentDictionaryID());
                history.push('/welcome')
            })
            .catch(Tools.log);
    }

    actionSettings(history) {
        history.push('/settings');
    }


    render() {

        const { appState } = this.props;
        return (
            <Route render={({ history }) => (

                <div className="app-drawer mdl-layout__drawer">
                    <header className="app-drawer-header">
                        <div className="app-name">
                            <h1>Words<span>Bucket</span></h1>
                            <h2>{Lang.get("app_subname")}</h2>
                        </div>
                        {appState.user &&
                            <div className="app-user-dropdown">
                                <span>{appState.user.Nick}</span>
                                <div className="mdl-layout-spacer"></div>
                                <button id="IDuserLogin" className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon">
                                    <i className="material-icons" role="presentation">arrow_drop_down</i>
                                </button>
                                <ul className="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect" htmlFor="IDuserLogin">
                                <li className="mdl-menu__item" onClick={(e) => {this.handleClickNoDic(); this.actionSettings(history)}}><i className="material-icons">settings</i>{Lang.get("drawer_user_settings")}</li>
                                    <li className="mdl-menu__item" onClick={(e) => {this.handleClick(); this.actionLogout(history)}}><i className="material-icons">power_settings_new</i>{Lang.get("drawer_logout")}</li>
                                </ul>
                            </div>
                        }
                        {!appState.user &&
                            <div className="app-login">
                                <Link onClick={() => {this.handleClickNoDic()}} to="/login"><button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised">{Lang.get("drawer_login")}</button></Link>
                                <Link onClick={() => {this.handleClickNoDic()}} to="/settings"><i className="material-icons">settings</i></Link>
                            </div>
                        }
                    </header>
                    <nav className="app-navigation mdl-navigation">

                        {appState.bookmarksDictionary &&
                            <Link onClick={() => {this.handleClick(); this.showLoader()} } className={"mdl-navigation__link mdl-navigation__link--divider" + (!this.props.disableMenuItemSelect && appState.dictionary && appState.bookmarksDictionary.ID === appState.dictionary.ID ? ' mdl-navigation__link--current' : '')} to={"/dic/" + appState.bookmarksDictionary.ID}><i className="material-icons" role="presentation">star</i><span>{Lang.get("drawer_bookmarks")} <span className="counter">({appState.bookmarksDictionary.WordsKnownCnt}/{appState.bookmarksDictionary.WordsCnt})</span></span></Link>
                        }

                        {appState.userDictionaries &&
                            Object.values(appState.userDictionaries).map(function (dic) {
                                return (
                                    <Link onClick={() => {this.handleClick(); this.showLoader()} } key={dic.ID} className={"mdl-navigation__link mdl-navigation__link-dic" + (!this.props.disableMenuItemSelect && appState.dictionary && dic.ID === appState.dictionary.ID ? ' mdl-navigation__link--current' : '')} to={"/dic/" + dic.ID}><i className="material-icons" role="presentation">note</i><span>{dic.Name} <span className="counter">({dic.WordsKnownCnt}/{dic.WordsCnt})</span></span></Link>
                                )
                            }.bind(this))
                        }

                        <button className="mdl-navigation__link" onClick={() => {this.handleClick(); this.actionAddUserDictionary()}}><i className="material-icons" role="presentation">add</i>{Lang.get("drawer_add_dic")}</button>

                        {appState.userSharedDictionaries && appState.userSharedDictionaries.length > 0 && <label>{Lang.get("drawer_label_shared")}</label>}
                        {appState.userSharedDictionaries && appState.userSharedDictionaries.length > 0 && 
                            Object.values(appState.userSharedDictionaries).map(function (dic) {
                                return (
                                    <Link onClick={() => {this.handleClick(); this.showLoader()} } key={dic.ID} className={"mdl-navigation__link mdl-navigation__link-dic" + (!this.props.disableMenuItemSelect && appState.dictionary && dic.ID === appState.dictionary.ID ? ' mdl-navigation__link--current' : '')} to={"/dic/" + dic.ID}>
                                        <i className="material-icons" role="presentation">list_alt</i>
                                        <span>
                                            {dic.Name} <span className="counter">({dic.WordsKnownCnt}/{dic.WordsCnt})</span>
                                            {dic.Author && 
                                                <span className="author">{dic.Author}</span>
                                            }
                                        </span>
                                    </Link>
                                )
                            }.bind(this))
                        }


                        <label>{Lang.get("drawer_label_library")}</label>
                        {appState.userCatalogDictionaries &&
                            Object.values(appState.userCatalogDictionaries).map(function (dic) {
                                return (
                                    <Link onClick={() => {this.handleClick(); this.showLoader()} } key={dic.ID} className={"mdl-navigation__link mdl-navigation__link-dic" + (!this.props.disableMenuItemSelect && appState.dictionary && dic.ID === appState.dictionary.ID ? ' mdl-navigation__link--current' : '')} to={"/dic/" + dic.ID}><i className="material-icons" role="presentation">list_alt</i><span>{dic.Name} <span className="counter">({dic.WordsKnownCnt}/{dic.WordsCnt})</span></span></Link>
                                )
                            }.bind(this))
                        }

                        <Link onClick={() => {this.handleClickNoDic()}} className="mdl-navigation__link" to="/catalog"><i className="material-icons" role="presentation">library_add</i>{Lang.get("drawer_add_dic_catalog")}</Link>

                        {Tools.isAndroid() && <a className="mdl-navigation__link gplay" rel="noopener noreferrer" href={Config.get("GPlayURL")}><img src={GooglePlayLogo} alt="" />Google Play</a>}
                    </nav>

                    <footer className="app-drawer-footer">
                        <a href={Config.get("contactURL")} target="_blank" rel="noopener noreferrer">&copy; W3media.pl</a> (v{Config.get('appVersion')})
                    </footer>


                </div>

            )} />
        )
    }
}


export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(Drawer))
