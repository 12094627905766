import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import LocalStorage from './../Models/LocalStorage';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordsLargeList extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.dialogShow = props.appDialogs.dialogWordsLargeList;
    }


    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-largelist');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordsLargeList && nextProps.appDialogs.dialogWordsLargeList !== this.dialogShow){
            this.dialogShow = nextProps.appDialogs.dialogWordsLargeList;
            Tools.dialogNavBackOpen(this.dialog);
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    actionDissmiss(e) {
        LocalStorage.setTipDoneLargeList();
        this.closeDialog();
    }

    actionSettings(history) {
        LocalStorage.setTipDoneLargeList();
        history.push('/settings/');
    }



    render() {
        return (
            <Route render={({ history }) => (
                <dialog className="mdl-dialog dialog dialog-largelist" data-dialog-id="largelist">
                    <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_largelist_title")}</h4>
                    <div className="mdl-dialog__content">
                        {Lang.get("dic_dialog_largelist_content")}
                    </div>
                    <div className="mdl-dialog__actions">
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionDissmiss()}>{Lang.get("dic_dialog_largelist_btn_close")}</button>
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionSettings(history)}>{Lang.get("dic_dialog_largelist_btn_settings")}</button>
                    </div>
                </dialog>
            )} /> 
        )
    }
}

export default connect(function (store) {
    return {
        appDialogs: store.appDialogs
    }
})(DialogWordsLargeList)