import React, { Component } from 'react';
import { connect } from "react-redux";
import { setMultipleData } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordEdit extends Component {
    
    dialog = null;
    dialogShow = false;

    _upgradeMdlSelect = false;

    constructor(props) {
        super(props);
        this.state = {
            editWord: null,
            editWordDictionaryID: 0,
            editWordDictionaryName: "",
        }
        this.dialogShow = props.appDialogs.dialogWordEdit;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-edit-word');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordEdit && nextProps.appDialogs.dialogWordEdit !== this.dialogShow){
            
            this.state.editWord = nextProps.appDialogs.dialogWordEditPayload.word;
            
            for(let _idx in this.state.editWord.Trans){
                if(this.state.editWord.Trans[_idx].Type){
                    this.state.editWord.Trans[_idx].Word += " ";
                }
            }

            this.state.editWordDictionaryName = nextProps.appState.dictionary.Name;
            this.state.editWordDictionaryID = nextProps.appState.dictionary.ID;
            
            this.dialogShow = nextProps.appDialogs.dialogWordEdit;
            Tools.dialogNavBackOpen(this.dialog);
            this._upgradeMdlSelect = true;
        }
        if(this.state.editWord !== null && this.state.editWord.Trans.length !== nextState.editWord.Trans.length){
            this._upgradeMdlSelect = true;
        }
        return true;
    }

    componentDidUpdate() {
        if(this._upgradeMdlSelect){
            document.querySelector(".dialog-edit-word").querySelectorAll("*")
                .forEach(function(item) {
                    if(item.className.match(/(mdl-js-textfield|mdl-js-menu)/) && !item.hasAttribute('data-upgraded')){
                        Tools.mdlUpgradeElement(item);
                    }
                });
            Tools.mdlInputRefresh();
            Tools.mdlSelectRefresh();
        }
        this._upgradeMdlSelect = false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    onFocusBlurFocus(e) {
        // łatka na tymczasowy(?) bug w Chrome dla Androida, który w przypadku gdy są co najmnie dwa inputy po focusie na kolejnym, wpisaniu czegokolwiek i naciśnięciu spacji powodował wklejanie treści z poprzedniego inputa
        if(Tools.isChrome() && Tools.isAndroid()){
            let element = e.target;
            if(this._lastFocusElement !== element){
                this._lastFocusElement = element;
                element.blur();
                setTimeout(() => {
                    element.focus();
                }, 10)
            }
        }
    }

    actionEditWordOnChangeWord(e) {
        var _editWord = this.state.editWord;
        _editWord.Word = e.target.value;
        this.setState({ editWord: _editWord });
    }
    actionEditWordOnChangeTrans(e, idx) {
        var _editWord = this.state.editWord;
        if (_editWord.Trans[idx]) {
            _editWord.Trans[idx] = this.transStrToObj(e.target.value);
            this.setState({ editWord: _editWord });
        }
    }

    actionEditWordOnChangeDictionary(e, dicID, dicName) {
        this.setState({ editWordDictionaryID: parseInt(dicID, 10), editWordDictionaryName: dicName });
    }

    actionEditWordGo() {
        if (this.state.editWord.Word && this.state.editWord.Trans[0].Word) {
            ModelDictionary.editWord(this.state.editWord, this.state.editWordDictionaryID)
                .then(() => {
                    Tools.toast(Lang.get("dic_dialog_editword_done_toast"));
                    return ModelDictionary.getCurrentDictionarySorted()
                })
                .then((dictionary) => {
                    return Promise.all([
                        ModelDictionary.getBookmarksDictionary(true),
                        ModelDictionary.getUserDictionaries()
                    ])
                        .then(results => {
                            var bookmarksDictionary = results[0];
                            var userDictionaries = results[1];
                            this.props.dispatch(setMultipleData({
                                bookmarksDictionary: bookmarksDictionary,
                                userDictionaries: userDictionaries,
                                dictionary: dictionary
                            }));
                        })
                })
                .catch(Tools.log)
            this.closeDialog();
        }
    }

    actionEditWordAddTrans() {
        var _obj = Tools.cloneObject(this.state.editWord);
        _obj.Trans.push({
            Word: '',
            Type: '',
        });
        this.setState({ editWord: _obj });
        setTimeout(function () {
            Tools.mdlSelectRefresh();
        }, 500)

    }

    actionEditWordDelTrans(index) {
        var _editWord = Tools.cloneObject(this.state.editWord);
        var _newTrans = [];
        for (var _idx in this.state.editWord.Trans) {
            if (index !== parseInt(_idx, 10)) {
                _newTrans.push(this.state.editWord.Trans[_idx]);
            }
        }
        if(!_newTrans.length){
            _newTrans = this.getDefaultEditWord().Trans;
        }
        _editWord.Trans = _newTrans;
        this.setState({ editWord: _editWord }, function () {
            Tools.mdlInputRefresh();
        });
    }

    getDefaultEditWord() {
        return {
            Word: '',
            Trans: [
                {
                    Word: '',
                    Type: '',
                }
            ]
        };
    }

    transObjToStr(word) {
        //return word.Word.replace(/\s\s+/g, ' ') + (word.Type ? " [" + word.Type.trim() + "]" : "");
        return word.Word + (word.Type ? "[" + word.Type.trim() + "]" : "");
    }

    transStrToObj(str) {
        var _match = str.match(/(.*?)\[(.*?)\]/);
        if (_match) {
            return {
                Word: _match[1],
                Type: _match[2],
            }
        }
        else {
            return {
                Word: str,
                Type: ""
            }
        }
    }




    render() {
        const { appState } = this.props;
        
        return (

            <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-edit-word" data-dialog-id="editword">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_editword_title")}</h4>
                {this.state.editWord && 
                    <div className="mdl-dialog__content">
                        <div id="_chk11" className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" type="text" id="DIeww" required value={this.state.editWord.Word} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" onFocus={this.onFocusBlurFocus.bind(this)} onChange={e => this.actionEditWordOnChangeWord(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionEditWordGo() : null)} />
                            <label className="mdl-textfield__label" htmlFor="DIeww">{Lang.get("dic_dialog_editword_word_label")}</label>
                        </div>
                        {this.state.editWord.Trans.map((trans, index) =>
                            <div key={"awt-" + index} className="dialog-add-word__trans">
                                <i className="delete material-icons" onClick={e => this.actionEditWordDelTrans(index)}>close</i>
                                <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                                    {(() => index > 0
                                        ?
                                        <input className="mdl-textfield__input" type="text" id={"trweidx-" + index} value={this.transObjToStr(trans)} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" onFocus={this.onFocusBlurFocus.bind(this)} onChange={e => this.actionEditWordOnChangeTrans(e, index)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionEditWordGo() : null)} />
                                        :
                                        <input className="mdl-textfield__input" type="text" id={"trweidx-" + index} value={this.transObjToStr(trans)} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" onFocus={this.onFocusBlurFocus.bind(this)} onChange={e => this.actionEditWordOnChangeTrans(e, index)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionEditWordGo() : null)} required />
                                    )()}
                                    <label className="mdl-textfield__label" htmlFor={"trweidx-" + index}>{Lang.get("dic_dialog_editword_trans_label")}</label>
                                </div>
                            </div>
                        )}
                        <div className="add-trans">
                            <button className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionEditWordAddTrans()}>
                                <i className="material-icons">add</i>
                            </button>
                        </div>
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                            <input tabIndex='-1' type="text" defaultValue={this.state.editWordDictionaryName} className="mdl-textfield__input" id="DIewd" readOnly />
                            <input type="hidden" />
                            <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                            <label htmlFor="DIewd" className="mdl-textfield__label">{Lang.get("dic_dialog_editword_dictionary_label")}</label>
                            <ul htmlFor="DIewd" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                {appState.bookmarksDictionary &&
                                    ((this.state.editWordDictionaryID === appState.bookmarksDictionary.ID) ? (
                                        <li onClick={e => this.actionEditWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID} data-selected>{appState.bookmarksDictionary.Name}</li>
                                    ) : (
                                            <li onClick={e => this.actionEditWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID}>{appState.bookmarksDictionary.Name}</li>
                                        ))
                                }
                                {appState.userDictionaries && appState.userDictionaries.map((dic, index) =>
                                    ((this.state.editWordDictionaryID === dic.ID) ? (
                                        <li key={"aw-key-" + index} onClick={e => this.actionEditWordOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID} data-selected>{dic.Name}</li>
                                    ) : (
                                            <li key={"aw-key-" + index} onClick={e => this.actionEditWordOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID}>{dic.Name}</li>
                                        ))
                                )}
                            </ul>
                        </div>
                    </div>
                }
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionEditWordGo()}>{Lang.get("dic_dialog_editword_btn_ok")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_editword_btn_cancel")}</button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordEdit)