
import db from './DB';

class UserData {

    TABLE_USERDATA = 'userdata';
    MEMORY_STORE = {};

    get(key, defaultValue, skipCache){
        return new Promise((resolve, reject) => {
            if(!skipCache && typeof this.MEMORY_STORE[key] !== 'undefined' ){
                resolve(this.MEMORY_STORE[key]);
            }
            else {
                db.table(this.TABLE_USERDATA).get(1)
                    .then(result => {
                        if(typeof result[key] !== 'undefined'){
                            this.MEMORY_STORE[key] = result[key];
                            resolve(result[key]);
                        }
                        else {
                            resolve(defaultValue);
                        }
                    })
                    .catch(() => resolve(defaultValue))
                }
        });
    }

    set(key, value){
        this.MEMORY_STORE[key] = value;
        return new Promise((resolve, reject) => {
            db.table(this.TABLE_USERDATA).get(1)
                .then(result => { 
                    var dataObj
                    if(!result){
                        dataObj = {ID: 1};
                        dataObj[key] = value;
                        return db.table(this.TABLE_USERDATA).add(dataObj)
                    }
                    else {
                        dataObj = result;
                        dataObj[key] = value;
                        return db.table(this.TABLE_USERDATA).update(dataObj.ID, dataObj)
                    }
                })
                .then(resolve)
                .catch(() => {
                    delete this.MEMORY_STORE[key];
                    reject()
                })
        });
    }

}

export default new UserData(); 