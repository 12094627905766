import { combineReducers } from "redux"

import appState from "./AppStateReducer"
import appTools from "./AppToolsReducer"
import appDialogs from "./AppDialogsReducer"

export default combineReducers({
  appState,
  appTools,
  appDialogs
})
