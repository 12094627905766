import React, { Component } from 'react';
import { connect } from "react-redux";
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class Confirm extends Component {

    dialog = null;
    confirmID = null;
    confirmCnt = 0;

    constructor(props) {
        super(props);
        if(props.appTools.confirm){
            this.confirmID = props.appTools.confirm.ID;
        }
        this.state = {
            doubleConfirmLabel: false,
        }
    }

    componentDidMount() {
        this.dialog = document.querySelector('.dialog-confirm');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    componentDidUpdate() { 
        Tools.mdlUpgrade();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.appTools.confirm && nextProps.appTools.confirm.ID && nextProps.appTools.confirm.ID !== this.confirmID && this.dialog){
            this.confirmID = nextProps.appTools.confirm.ID;
            if(this.dialog && this.dialog.open){
                this.dialog.close();
            }
            Tools.dialogNavBackOpen(this.dialog);
            this.setState({doubleConfirmLabel: false});
            this.confirmCnt = 0;
            return true;
        }
        if(this.state.doubleConfirmLabel !== nextState.doubleConfirmLabel){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    actionGo(){
        if(this.props.appTools.confirm.doubleConfirm && this.confirmCnt === 0){
            this.setState({doubleConfirmLabel: true});
            this.confirmCnt = 1;
        }
        else {
            this.props.appTools.confirm.action();
            this.closeDialog();
        }
    }

    render() {
        const { appTools } = this.props;
        return (
            <dialog className="mdl-dialog dialog dialog-confirm" data-dialog-id="confirm">
                {appTools.confirm !== null && 
                    <div>
                        <h4 className="mdl-dialog__title">{appTools.confirm.header ? appTools.confirm.header : Lang.get("dialog_confirm_header_default")}</h4>
                        <div className="mdl-dialog__content">
                            <p>{appTools.confirm.msg ? appTools.confirm.msg : ""}</p>
                        </div>
                        <div className="mdl-dialog__actions">
                            <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionGo()}>{this.state.doubleConfirmLabel ? Lang.get("dialog_confirm_btn_ok_confirm") : (appTools.confirm.actionBtnLabel ? appTools.confirm.actionBtnLabel : Lang.get("dialog_confirm_btn_ok"))}</button>
                            {!appTools.confirm.disableCancel && <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{appTools.confirm.cancelBtnLabel ? appTools.confirm.cancelBtnLabel : Lang.get("dialog_confirm_btn_cancel")}</button>}
                        </div>
                    </div>
                }
            </dialog>
        )
    }
}

export default connect(function (store) {
    return {
        appTools: store.appTools
    }
})(Confirm)
