import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser.js';
import Tools from '../Misc/Tools';
import { Speech } from '../Misc/Speech';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import DictionaryList from '../Components/DictionaryList';
import DictionarySlideshow from '../Components/DictionarySlideshow';

import { 
    setMultipleData, 
    setDictionary, 
    setDialogWordsSort, 
    setDialogDicRename,
    setDialogDicCopyAs,
    setDialogDicShareToUser,
    setDialogWordAdd,
    setDialogWordAddCSV,
    setDicWordsCnt,
    setDialogWordsShow,
    setDialogDicPublish,
    setDialogDicShareCatalog
} from '../ReduxActions/AppActions';
import LocalStorage from '../Models/LocalStorage';


class MainPage extends Component {

    _tipHoldSpeechTimeout = null;
    _tipHoldSpeech = false;

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidMount() {
        Tools.loader(this.props, true);
        this.refreshDictionaryState(true)
            .catch(Tools.log);

        if(!Tools.isIOS()){
            // W Safari jest jest problem z odworzeniem za pierwszym razem głosu. Obsługuje tylko click a nie hold
            this._tipHoldSpeechTimeout = setTimeout(() => {
                if(
                    this.props.appState.dictionary 
                    && this.props.appState.dictionary.Words.length > 0 
                    && !this._tipHoldSpeechInitDelay 
                    && !this._tipHoldSpeech 
                    && !LocalStorage.getTipDoneHoldSpeech() 
                    && Speech.canSpeech()
                    && LocalStorage.getSettingSpeechOnTouch()){
                        this._tipHoldSpeech = true;
                        Tools.toast(Lang.get("dic_holdspeech_toast_tip"), Lang.get("dic_holdspeech_toast_tip_btn"), () => {
                            LocalStorage.setTipDoneHoldSpeech();
                            Tools.toastClear();
                        }, 15000);
                }
            }, 10000);
        }
    }

    componentWillUnmount(){
        if(this._tipHoldSpeechTimeout){
            clearTimeout(this._tipHoldSpeechTimeout);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(
            JSON.stringify(this.props.appState.dictionary || null) !== JSON.stringify(nextProps.appState.dictionary || null)
        ){
            if(nextProps.appState.dictionary){
                this.setWordsCounter(nextProps.appState.dictionary);
            }
        }
        return true;
    }


    componentDidUpdate() {
        this.changeDictionaryFromPropsMatch(this.props);
        Tools.mdlUpgrade();
        Tools.mdlSelectRefresh();
    }

    changeDictionaryFromPropsMatch(props) {
        var currentDictionaryID = props.match && props.match.params.id ? props.match.params.id : ModelDictionary.getCurrentDictionaryID();
        if (currentDictionaryID) {
            ModelDictionary.setCurrentDictionary(currentDictionaryID)
                .then((changed) => {
                    if (changed) {
                        return this.refreshDictionaryState(true);
                    }
                    else {
                        Tools.loader(this.props, false);
                    }
                    this._dictionaryID = currentDictionaryID;
                })
                .catch(Tools.log)
        }
    }

    refreshDictionaryState(forceShuffleWords) {
        return ModelDictionary.getCurrentDictionarySorted(forceShuffleWords)
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
    }


    setWordsCounter(dictionary){
        var _knownCnt = 0;
        if(dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN){
            _knownCnt = dictionary.Words.length;
        }
        else if(dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN){
            _knownCnt = dictionary.WordsCnt - dictionary.Words.length;
        }
        else {
            for(let _word of dictionary.Words){
                if(_word.Known > 0){
                    _knownCnt++;
                }
            }
        }
        this.props.dispatch(setDicWordsCnt({All: dictionary.WordsCnt, Known: _knownCnt}));
    }

    isSlideshowMode(){
        return LocalStorage.getModeSlideshow();
    }


    /* ----- */
    actionShuffle() {
        Tools.loader(this.props, true);
        ModelDictionary.changeSortType(this.props.appState.dictionary.ID, ModelDictionary.DIC_SORTTYPE_RANDOM)
            .then(() => {
                return ModelDictionary.setSlideIndex(this.props.appState.dictionary.ID, 0)
            })
            .then(() => {
                return this.refreshDictionaryState(true);
            })
            .catch(Tools.log)
    }

    /* ----- */
    actionSort() {
        this.props.dispatch(setDialogWordsSort());
    }

    /* ----- */
    actionChangeShowWords() {
        this.props.dispatch(setDialogWordsShow());
    }


    /* ----- */
    actionClearKnown() {
        Tools.confirm(
            this.props, 
            () => {
                Tools.loader(this.props, true);
                ModelDictionary.clearKnown(this.props.appState.dictionary.ID)
                    .then(() => {
                        return ModelDictionary.getCurrentDictionarySorted()
                    })
                    .then((dictionary) => {
                        return ModelDictionary.setSlideIndex(dictionary.ID, 0)
                            .then(() => {
                                dictionary.CurrentSlide = 0;
                                return dictionary;
                            })
                    })
                    .then((dictionary) => {
                        this.props.dispatch(setDictionary(dictionary));
                    })
                    .then(() => {
                        Tools.toast(Lang.get("dic_dialog_clearknown_toast"), null, null, 3500);
                    })
                    .then(() => {
                        return this.changeDrawerWordsCnt();
                    })
                    .catch(Tools.log)
            }, 
            Lang.get("dic_dialog_clearknown_title"),
            Lang.get("dic_dialog_clearknown_confirm"),
            Lang.get("dic_dialog_clearknown_btn_ok")
        )

    }


    changeDrawerWordsCnt(){
        Promise.all([
            ModelDictionary.getBookmarksDictionary(true),
            ModelDictionary.getAllUserDictionaries()
        ])
            .then(results => {
                var bookmarksDictionary = results[0];
                var dictionaries = results[1];

                var userDictionaries = dictionaries.user;
                var userCatalogDictionaries = dictionaries.catalog;
                var userSharedDictionaries = dictionaries.shared;
                this.props.dispatch(setMultipleData({
                    bookmarksDictionary: bookmarksDictionary,
                    userDictionaries: userDictionaries,
                    userCatalogDictionaries: userCatalogDictionaries,
                    userSharedDictionaries: userSharedDictionaries,
                }));
            })
            .catch(Tools.log)
  
    }

    /* ----- */
    actionClearWords() {
        Tools.confirm(
            this.props, 
            () => {
                ModelDictionary.clearWords(this.props.appState.dictionary.ID)
                    .then(() => {
                        return ModelDictionary.getCurrentDictionarySorted(true)
                    })
                    .then((dictionary) => {

                        return Promise.all([
                            ModelDictionary.getBookmarksDictionary(true),
                            ModelDictionary.getAllUserDictionaries()
                        ])
                            .then(results => {
                                var bookmarksDictionary = results[0];
                                var userDictionaries = results[1].user;
                                this.props.dispatch(setMultipleData({
                                    bookmarksDictionary: bookmarksDictionary,
                                    userDictionaries: userDictionaries,
                                    dictionary: dictionary
                                }));
                            })

                    })
                    .catch(Tools.log)
            }, 
            Lang.get("dic_dialog_clearwords_title"),
            Lang.get("dic_dialog_clearwords_confirm"),
            Lang.get("dic_dialog_clearwords_btn_ok"),
            false,
            null,
            true
        )
    }



    /* ----- */
    actionRename() {
        this.props.dispatch(setDialogDicRename());
    }
    

    /* ----- */
    actionCopyAs() {
        this.props.dispatch(setDialogDicCopyAs());
    }
    

    /* ----- */
    actionDelete() {
        Tools.confirm(
            this.props, 
            () => {
                this.actionDeleteGo();
            }, 
            Lang.get("dic_dialog_delete_title"),
            Lang.get("dic_dialog_delete_confirm"),
            Lang.get("dic_dialog_delete_btn_ok"),
            false,
            null,
            (this.props.appState.dictionary.Source === ModelDictionary.DIC_SOURCE_USER)
        )
    }

    actionDeleteGo(){
        ModelDictionary.delete(this.props.appState.dictionary.ID)
            .then(() => {
                return Promise.all([
                    ModelDictionary.getCurrentDictionarySorted(true),
                    ModelDictionary.getUserDictionaries(),
                    ModelDictionary.getUserCatalogDictionaries(),
                    ModelDictionary.getUserSharedDictionaries()
                ])
            })
            .then(results => {
                this.props.dispatch(setMultipleData({
                    dictionary: results[0],
                    userDictionaries: results[1],
                    userCatalogDictionaries: results[2],
                    userSharedDictionaries: results[3],
                }))
                Tools.toast(Lang.get("dic_dialog_delete_done_toast"));
            })
            .catch(Tools.log)
    }


    /* ----- */
    actionShare() {
        if(Tools.onLine()){
            ModelUser.getUser()
                .then((user => {
                    if(user && user.OID){
                        if(user.Active > 0){
                            this.props.dispatch(setDialogDicShareToUser());
                        }
                        else {
                            Tools.alert(this.props, Lang.get("account_is_not_active"));
                        }
                    }
                    else {
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_login_required"));
                    }
                }))
                .catch(Tools.log)
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    /* ----- */
    actionShareCatalog() {
        this.props.dispatch(setDialogDicShareCatalog());
    }


    /* ----- */
    actionAddWord() {
        this.props.dispatch(setDialogWordAdd({uploadCsvCallback: this.actionAddWordCSV.bind(this)}));
    }
    

    /* ----- */
    actionAddWordCSV() {
        if(Tools.onLine()){
            ModelUser.getUserOID()
                .then((userOID => {
                    if(userOID){
                        this.props.dispatch(setDialogWordAddCSV());
                    }
                    else {
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_login_required"));
                    }
                }))
                .catch(Tools.log)
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }
    

    /* ----- */
    actionExportCsv(){
        if(Tools.onLine()){
            ModelUser.getUserOID()
                .then(userOID => {
                    if(userOID){
                        Tools.loader(this.props, true);
                        return ModelDictionary.exportCSV(this.props.appState.dictionary.ID);
                    }
                    else {
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_login_required")); 
                        return true;
                    }
                })
                .then(() => {
                    Tools.loader(this.props, false);
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                })
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    /* ----- */
    actionCatalog() {
        if(Tools.onLine()){
            this.props.dispatch(setDialogDicPublish());
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }
    

    render() {

        const { appState } = this.props;
        const { dictionary } = appState;

        var HeaderMenu = (
            <div>
                {dictionary &&
                    <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon" id="IHMenu">
                        <i className="material-icons">more_vert</i>
                    </button>
                }
                {dictionary &&
                    <ul className="mdl-menu mdl-js-menu mdl-js-ripple-effect mdl-menu--bottom-right" htmlFor="IHMenu">
                        <li className="mdl-menu__item" onClick={() => this.actionShuffle()}><i className="material-icons">shuffle</i>{Lang.get("dic_menu_random")}</li>
                        <li className="mdl-menu__item" onClick={() => this.actionSort()}><i className="material-icons">sort</i>{Lang.get("dic_menu_sort")}:&nbsp;{dictionary.SortType === ModelDictionary.DIC_SORTTYPE_DEFAULT && Lang.get('dic_menu_sort_default')}{dictionary.SortType === ModelDictionary.DIC_SORTTYPE_RANDOM && Lang.get('dic_menu_sort_random')}{dictionary.SortType === ModelDictionary.DIC_SORTTYPE_ALPHA_WORD && Lang.get('dic_menu_sort_alpha_word')}{dictionary.SortType === ModelDictionary.DIC_SORTTYPE_ALPHA_TRANS && Lang.get('dic_menu_sort_alpha_trans')}</li>
                        <li className="mdl-menu__item" onClick={() => this.actionChangeShowWords()}><i className="material-icons">visibility</i>{Lang.get("dic_menu_showwords")}:&nbsp;{dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN && Lang.get('dic_menu_showwords_unknown')}{dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN && Lang.get('dic_menu_showwords_known')}{dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_ALL && Lang.get('dic_menu_showwords_all')}</li>
                        <li className="mdl-menu__item" onClick={() => this.actionClearKnown()}><i className="material-icons">clear_all</i>{Lang.get("dic_menu_clear")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER && dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS ? "" : " hide")} onClick={() => this.actionRename()}><i className="material-icons">edit</i>{Lang.get("dic_menu_rename")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={() => this.actionCopyAs()}><i className="material-icons">dynamic_feed</i>{Lang.get("dic_menu_copyas")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={() => this.actionClearWords()}><i className="material-icons">clear</i>{Lang.get("dic_menu_clear_words")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={() => this.actionExportCsv()}><i className="material-icons">save_alt</i>{Lang.get("dic_menu_export_csv")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER && dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS ? "" : " hide")} onClick={() => this.actionShare()}><i className="material-icons">share</i>{Lang.get("dic_menu_share")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_CATALOG ? "" : " hide")} onClick={() => this.actionShareCatalog()}><i className="material-icons">share</i>{Lang.get("dic_menu_share")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS ? "" : " hide")} onClick={() => this.actionDelete()}><i className="material-icons">delete</i>{Lang.get("dic_menu_delete")}</li>
                        <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER && dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS && ModelUser.isAdmin(appState.user) ? "" : " hide")} onClick={() => this.actionCatalog()}><i className="material-icons">list_alt</i>{Lang.get("dic_menu_catalog")}</li>
                        <li className={"mdl-menu__item mdl-menu__item--divider" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={() => this.actionAddWord()}><i className="material-icons">add</i>{Lang.get("dic_menu_add_word")}</li>
                    </ul>
                }
            </div>
        );

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={dictionary ? dictionary.Name : null}
                    headerMenu={HeaderMenu}
                    contentClass={"dictionary--" + (this.isSlideshowMode() ? "slideshow" : "list")}
                >

                    {(this.isSlideshowMode() ?
                            <DictionarySlideshow />
                        :
                            <DictionaryList />
                    )}


                </Layout>
            )} />

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(MainPage)
