import config from 'react-global-configuration';

var configDef = {
    "appName": "WordsBucket",
    "appVersion": "1.1.19",
    "dbName": "words_enpl", // UWAGA! To również trzeba zmienić w service-worker-custom.js 
    "dbVersion": 1,
    "APIEndpoint": "//api-enpl.wordsbucket.com/", // UWAGA! To również trzeba zmienić w service-worker-custom.js 
    "FBAppID": 2268366206813060,
    "GAID": "UA-146654835-1",
    "speechLang": ["en-GB", "en_GB", "en-US", "en_US"],
    "speechRate": "0.7",
    "speechTestMessage": "hello world",
    "extDictionaryWordUrl": "https://www.diki.pl/slownik-angielskiego?q=",
    "csvImportEncoding": ['UTF-8', 'CP1250'],
    "syncIntervalQueue": 10, // jak często synchronizować kolejkę [s]
    "syncIntervalFromServer": 300, // jak często sprawdzać zmiany po stronie serwera [s]
    "GPlayURL": "https://play.google.com/store/apps/details?id=com.wordsbucket.enpl",
    "contactURL": "https://wordsbucket.com/kontakt/",
    "termsURL": "https://wordsbucket.com/regulamin/",
    "privacyURL": "https://wordsbucket.com/polityka-prywatnosci/",
    "FBTermsURL": "https://wordsbucket.com/terms-iframe/"
}

// DEVEL
if(process && process.env && process.env.NODE_ENV === 'development'){
    configDef.APIEndpoint = '//api-enpl.words.my.int/';
    //configDef.APIEndpoint = '//10.0.0.4/';
    configDef.GAID = ""; 
    configDef.FBTermsURL = "https://words.my.int/terms-iframe/"; 
}

config.set(configDef);

export default config;