var lang = {
    "default": {

        "app_subname": "angielski",

        "drawer_logout": "Wyloguj się",
        "drawer_login": "Zaloguj się",
        "drawer_user_settings": "Ustawienia",
        "drawer_bookmarks": "Ulubione",
        "drawer_add_dic": "Dodaj własny słownik",
        "drawer_add_dic_catalog": "Dodaj z biblioteki",
        "drawer_label_library": "Biblioteka",
        "drawer_label_shared": "Udostępnione",

        "offline_msg": "Brak połączenia z internetem",
        "offline_msg_btn_reload": "Spróbuj ponownie",
        "sync_data_msg": "Trwa synchronizacja danych",

        "cookie_policy_toast": "Aplikacja wykorzystuje Cookies, Local Storage oraz IndexedDB. <a href=\"https://wordsbucket.com/polityka-prywatnosci/\" target=\"_blank\">Polityka Prywatności</a>",
        "cookie_policy_toast_btn": "OK",

        "logout_toast": "Zostałeś wylogowany",
        "sync_from_server_done_toast": "Dane z serwera zostały zsynchronizowane",
        "account_is_not_active": "Twoje konto nie jest aktywne. Sprawdź skrzynkę e-mail i aktywuj konto aby skorzystać z funkcji udostępniania słowników.",

        "dialog_alert_btn_ok": "OK",
        "dialog_alert_msg_offline": "Brak połączenia z internetem",
        "dialog_alert_btn_retry": "Spróbuj ponownie",
        "dialog_alert_msg_login_required": "Aby skorzystać z tej opcji musisz być zalogowany",
        "dialog_alert_msg_error": "Przepraszamy, wystąpił błąd. Spróbuj ponownie później.",

        "dialog_confirm_header_default": "Czy jesteś pewien?",
        "dialog_confirm_btn_ok": "OK",
        "dialog_confirm_btn_ok_confirm": "Potwierdź",
        "dialog_confirm_btn_cancel": "Anuluj",

        "welcome_tagline": "Platforma do nauki słów języków obcych",
        "welcome_label_welcome": "Witaj!",
        "welcome_default_dictionaries": "Dodaliśmy dla Ciebie kilka domyślnych słowników. Masz do nich dostęp z pozycji menu. Możesz dodać kolejne korzystając z bogatej biblioteki.",
        "welcome_btn_catalog": "Dodaj z biblioteki",
        "welcome_label_dictionaries": "Własne słowniki",
        "welcome_own_dictionaries": "Dodaj własne słowniki i ucz się słów, które sam wybierzesz. Będziesz mógł podzielić się nimi z innymi użytkownikami.",
        "welcome_add_dic": "Dodaj słownik",
        "welcome_csv": "Własne słowniki możesz eskportować i importować do formatu CSV a następnie edytować słownik np. w aplikacji Microsoft Excel.",
        "welcome_browser": "Pamiętaj, możesz korzystać z aplikacji również na komputerze. Wystarczy wejść na adres <span class=\"link\">https://enpl.wordsbucket.com</span>. Edycja słownika będzie łatwiejsza.",
        "welcome_label_register": "Zarejestruj się",
        "welcome_register": "Zarejestruj się aby mieć pewność, że Twoje słowniki pozostaną na trwałe. Tylko wtedy będą synchronizowane z serwerem. Będziesz mógł korzystać z aplikacji na wielu urządzaniach oraz korzystać z funkcji, które są dostępne tylko dla zalogowanych użytkowników.",
        "welcome_btn_register": "Zarejestruj się",

        "login_infobox": "Zaloguj się. Będziesz mógł z tych samych słowników korzystać na różnych urządzeniach.",
        "login_title": "Zaloguj się",
        "login_email": "Adres e-mail",
        "login_password": "Hasło",
        "login_btn_login": "Zaloguj",
        "login_fb_head": "lub przez Facebooka",
        "login_btn_fblogin": "Zaloguj się za pomocą Facebooka",
        "login_reg_lead": "Nie masz konta?",
        "login_reg_btn": "Zarejestruj się",
        "login_error": "Błędny email lub hasło",
        "login_remind_password": "Nie pamiętam hasła",
        "login_dialog_terms_confirm": "Akcetuję powyższy regulamin usługi oraz zapoznałem się z informacją o administratorze i przetwarzaniu danych osobowych",
        "login_dialog_terms_btn_confirm": "Załóż konto",
        "login_dialog_terms_btn_cancel": "Anuluj",
        "login_dialog_terms_error": "Aby się zarejestować musisz potwierdzić",

        "register_title": "Zarejestruj się",
        "register_email": "Adres e-mail",
        "register_password": "Hasło",
        "register_repassword": "Powtórz hasło",
        "register_repassword_error": "Powtórzone hasło jest błędne",
        "register_confirm_term": "Akcetuję <a href=\"https://wordsbucket.com/regulamin/\" target=\"_blank\">regulamin usługi</a> oraz zapoznałem się z <a href=\"https://wordsbucket.com/polityka-prywatnosci/\" target=\"_blank\">informacją o administratorze i przetwarzaniu danych osobowych</a>",
        "register_confirm_term_error": "Aby się zarejestować musisz potwierdzić",
        "register_btn": "Zarejestruj się",
        "register_error_exists": "Na podany adres e-mail jest już zarejestrowane konto.",
        "register_facebook_sent": "Zauważyliśmy, że masz już konto założone przez Facebook. Wysłaliśmy do Ciebi e-mail z linkiem aktywującym możliowość logowania hasłem. Przejdź do swojej skrzynki pocztowej i kliknij w linka aktywujący w otrzymanej wiadomości.",
        "register_sent": "Gratulacje! Zarejestrowałeś się w WordsBucket. Na podany adres e-mail została wysłana wiadomość z linkiem aktywującym konto.",
        "register_sharedic_btn": "Udostępniony słownik",

        "remindpassword_title": "Zmiana hasła",
        "remindpassword_email": "Adres e-mail",
        "remindpassword_btn": "Zmień hasło",
        "remindpassword_error": "Na podany adres e-mail nie ma zarejestrowanego konta",
        "remindpassword_sent": "Na podany adres została wysłana wiadomość z dalszą instrukcją",

        "changepassword_title": "Zmiana hasła",
        "changepassword_currentpassword": "Aktualne hasło",
        "changepassword_currentpassword_error": "Podaj prawidłowe aktualne hasło",
        "changepassword_password": "Nowe hasło",
        "changepassword_repassword": "Powtórz nowe hasło",
        "changepassword_repassword_error": "Powtórzone hasło jest błędne",
        "changepassword_btn_change": "Zmień hasło",
        "changepassword_changed": "Hasło zostało zmienione",

        "deleteaccount_title": "Usunięcie konta",
        "deleteaccount_msg_ok": "Konto zostało usunięte. Przykro nam, że się z nami rozstajesz :-(",

        "shareaccept_title": "Udostępniony słownik",
        "shareaccept_accept_status_1": "Zaproszenie zostało zaakceptowane. Zaloguj się na właściwe konto: ",
        "shareaccept_accept_status_2": "Zaproszenie zostało zaakceptowane. Zarejestruj się, aby móc korzystać z udostępnionego słownika.",
        "shareaccept_accept_status_3": "Zaproszenie zostało zaakceptowane. Aktualnie jesteś zalogowany na inne konto niż to, na które udostępniono słownik. Zaloguj się na konto: ",
        "shareaccept_accept_status_4": "Zaproszenie zostało zaakceptowane. Aktualnie jesteś zalogowany na inne konto niż to, na które udostępniono słownik. Zarejestruj się, aby móc korzystać z udostępnionego słownika.",
        "shareaccept_accept_status_5": "Zaproszenie zostało zaakceptowane. Słownik został dodany do Twojej kolekcji.",
        "shareaccept_accept_status_6": "Zaproszenie zostało już wcześniej zaakceptowane. Słownik jest już w Twojej kolekcji.",
        "shareaccept_accept_status_7": "Zaproszenie zostało już wcześniej zaakceptowane. Zaloguj się na właściwe konto: ",
        "shareaccept_accept_status_8": "Zaproszenie zostało już wcześniej zaakceptowane. Zarejestruj się, aby móc korzystać z udostępnionego słownika.",
        "shareaccept_accept_status_9": "Przykro nam, zaproszenie zostało cofnięte.",
        "shareaccept_accept_login_btn": "Zaloguj się",
        "shareaccept_accept_register_btn": "Zarejestruj się",
        "shareaccept_accept_dic_btn": "Zobacz słownik",

        "sharelink_title": "Udostępniony słownik",
        "sharelink_status_1": "Słownik został dodany do Twojej kolekcji.",
        "sharelink_status_2": "Słownik został już wcześniej dodany do Twojej kolekcji.",
        "sharelink_status_3": "Błędny token lub słownik już nie istnieje.",
        "sharelink_status_4": "Przykro nam, zaproszenie zostało cofnięte.",
        "sharelink_status_5": "Jesteś właścicielem udostępnionego słownika.",
        "sharelink_status_10": "Zaloguj lub zarejestruj się, aby korzystać z udostępnionego słownika.",
        "sharelink_login_btn": "Zaloguj się",
        "sharelink_dic_btn": "Zobacz słownik",

        "errorpage_title": "Wystąpił błąd",
        "errorpage_content": "Przepraszamy, wystąpił błąd. Spróbuj ponownie później.",

        "contactpage_title": "Kontakt",

        "settings_title": "Ustawienia",
        "settings_section_theme": "Motyw",
        "settings_theme_dark": "Motyw ciemny",
        "settings_section_speech": "Wymowa",
        "settings_speech_voice": "Głos",
        "settings_speechontouch": "Wymowa przy przytrzymaniu na słowie",
        "settings_section_mode": "Tryb słownika",
        "settings_mode_list": "lista",
        "settings_mode_slideshow": "slideshow",
        "settings_section_nick": "Nick",
        "settings_nick_label": "Nick",
        "settings_nick_error": "Nick jest niepoprawny lub jest już zajęty. Użyj co najmniej 3 znaków",
        "settings_nick_btn": "Zmień",
        "settings_nick_toast_changed": "Nick został zmieniony",
        "settings_section_password": "Hasło",
        "settings_changepassword_btn": "Zmień hasło",
        "settings_section_delete": "Usuń konto",
        "settings_delete_btn": "Usuń konto",
        "settings_delete_dialog_title": "Usuń konto",
        "settings_delete_dialog_confirm": "Czy na pewno?",
        "settings_delete_dialog_btn_cancel": "Anuluj",
        "settings_delete_dialog_btn_ok": "Usuń",
        "settings_delete_sent_info": "Na Twoją skrzynkę pocztową został wysłany e-mail z linkiem usuwającym konto",
        "settings_section_cleardb": "Wyczyść lokalną bazę danych",
        "settings_cleardb_btn": "Wyczyść bazę",
        "settings_cleardb_dialog_title": "Wyczyść lokalną bazę danych",
        "settings_cleardb_dialog_confirm": "Jeżeli nie jesteś zalogowany, to wszystkie Twoje słowniki zostaną usunięte. Jeżeli jesteś zalogowany, to wszystkie słowniki zsynchronizują się ponownie z serwerem. Czy jesteś pewien?",
        "settings_cleardb_dialog_btn_ok": "Wyczyść",
        "settings_section_forcesync": "Wymuś synchronizację z serwerem",
        "settings_forcesync_btn": "Synchronizuj",
        "settings_link_text_terms": "Regulamin usługi",
        "settings_link_text_private_policy": "Polityka prywatności",
        "settings_link_text_contact": "Kontakt",
        "settings_not_active": "Twoje konto nie jest aktywne. Masz 7 dni aby aktywować konto. W przeciwnym razie konto zostanie usunięte. Przejdź do swojej skrzynki pocztowej. Na Twój adres e-mail została wysłana wiadomość z linkiem aktywującym konto. Jeżeli nie możesz odnaleźć wiadomości, sprawdź folder SPAM lub wyślij link aktywujący ponownie.",
        "settings_not_active_send_btn": "Wyślij ponownie",
        "settings_activation_sent": "Wysłaliśmy do Ciebie wiadomość z linkiem aktywującym konto",
        "settings_shareddictionaries_label": "Słowniki, które udostępniasz",
        "settings_shareddictionaries_btn": "Udostępnione słowniki",

        "catalog_title": "Biblioteka",
        "catalog_search_label": "Szukana fraza",
        "catalog_not_found": "Nie znaleziono słowników. Znajdź słownik na poniższej liście.",
        "catalog_item_btn_add": "dodaj",
        "catalog_item_btn_del": "usuń",
        "catalog_label_author": "autor",
        "catalog_label_words_count": "słów",
        "catalog_dic_added_toast": "Słownik jest dostępny z pozycji menu",
        "catalog_dic_added_toast_dic_button": "Przejdź do słownika",

        "welcome_title": "WordsBucket",


        "dic_menu_random": "Pomieszaj słowa",
        "dic_menu_sort": "Sortuj",
        "dic_menu_sort_default": "domyślnie",
        "dic_menu_sort_random": "losowo",
        "dic_menu_sort_alpha_word": "alfabetycznie słowa",
        "dic_menu_sort_alpha_trans": "alfabetycznie tłumaczenia",

        "dic_menu_showwords": "Pokaż słowa",
        "dic_menu_showwords_all": "wszystkie",
        "dic_menu_showwords_known": "znane",
        "dic_menu_showwords_unknown": "nieznane",
        "dic_menu_clear": "Wyczyść, które znam",
        "dic_menu_rename": "Zmień nazwę",
        "dic_menu_copyas": "Kopiuj jako",
        "dic_menu_export_csv": "Eksportuj słownik",
        "dic_menu_clear_words": "Usuń słowa",
        "dic_menu_delete": "Usuń słownik",
        "dic_menu_share": "Udostępnij",
        "dic_menu_add_word": "Dodaj słowo",
        "dic_menu_catalog": "Biblioteka",


        "dic_learnmode_learn": "Tryb nauki",
        "dic_learnmode_left": "Lewy",
        "dic_learnmode_right": "Prawy",
        "dic_learnmode_top": "Górny",
        "dic_learnmode_bottom": "Dolny",
        "dic_learnmode_toast_tip_hidden": "Kliknij w słowo aby odsłonić",
        "dic_learnmode_toast_tip_hidden_btn": "Już wiem",
        "dic_nowordstoshow": "Wszystkie słowa są ukryte",
        "dic_nowordstoshow_btn_change": "Zmień widoczność",
        "dic_nowordstoshow_or_clearknown": "lub",
        "dic_nowordstoshow_btn_clearknown": "Wyczyść, które znam",
        "dic_empty": "Słownik nie ma jeszcze żadnych słów",
        "dic_empty_btn_add_word": "Dodaj słowo",
        "dic_empty_to_catalog": "Dodaj słownik z biblioteki. Dostęp do wszystkich słowników będzisz miał z pozycji menu.",
        "dic_empty_to_catalog_btn": "Dodaj z biblioteki",
        "dic_words_known_cnt_label": "znam: ",
        "dic_holdspeech_toast_tip": "Przytrzymaj na słowie aby usłyszeć wymowę",
        "dic_holdspeech_toast_tip_btn": "Już wiem",
        "dic_slideshow_carousel_toast_tip": "Kliknij 3 razy w strzałkę w prawo aby przejść do pierwszego słowa",
        "dic_slideshow_carousel_toast_tip_btn": "Już wiem",


        
        "dic_dialog_sort_title": "Sortowanie słów",
        "dic_dialog_sort_opt_default": "domyślnie",
        "dic_dialog_sort_opt_random": "losowo",
        "dic_dialog_sort_opt_alpha_word": "alfabetycznie: według słowa",
        "dic_dialog_sort_opt_alpha_trans": "alfabetycznie: według tłumaczenia",
        "dic_dialog_sort_btn_cancel": "Anuluj",

        "dic_dialog_showwords_title": "Pokaż słowa",
        "dic_dialog_showwords_opt_all": "wszystkie",
        "dic_dialog_showwords_opt_known": "znane",
        "dic_dialog_showwords_opt_unknown": "nieznane",
        "dic_dialog_showwords_btn_cancel": "Anuluj",

        "dic_dialog_clearknown_title": "Wyczyść, które znam",
        "dic_dialog_clearknown_confirm": "Wyczyść oznaczenia słów, które już znam w tym słowniku.",
        "dic_dialog_clearknown_btn_ok": "Wyczyść",
        "dic_dialog_clearknown_toast": "Oznaczenia znanych słów w tym słowniku zostały usunięte",

        "dic_dialog_clearwords_title": "Usuń wszystkie słowa ze słownika",
        "dic_dialog_clearwords_confirm": "Czy jesteś pewien?",
        "dic_dialog_clearwords_btn_ok": "Usuń",

        "dic_dialog_rename_title": "Zmień nazwę słownika",
        "dic_dialog_rename_name_label": "Nazwa słownika",
        "dic_dialog_rename_btn_cancel": "Anuluj",
        "dic_dialog_rename_btn_ok": "Zmień",
        "dic_dialog_rename_done_toast": "Nazwa została zmieniona",


        "dic_dialog_delete_title": "Usuń słownik",
        "dic_dialog_delete_confirm": "Czy na pewno?",
        "dic_dialog_delete_btn_ok": "Usuń",
        "dic_dialog_delete_done_toast": "Słownik został usunięty",

        "dic_dialog_deleteword_title": "Usuń słowo",
        "dic_dialog_deleteword_confirm": "Czy na pewno?",
        "dic_dialog_deleteword_btn_ok": "Usuń",
        "dic_dialog_deleteword_done_toast": "Słowo zostało usunięte",


        "dic_dialog_sharetouser_title": "Udostępnij słownik",
        "dic_dialog_sharetouser_not_sync_yet": "Słownik nie został jeszcze zsynchronizowany. Spróbuj za chwilę.",
        "dic_dialog_sharetouser_lib_description": "Udostępnij słownik w bibliotece. Inni też będą chcieli uczyć się słów z Twojego słownika.",
        "dic_dialog_sharetouser_lib_btn": "Udostępnij w bibliotece",
        "dic_dialog_sharetouser_user_header": "Udostępnij wybranym osobom (tylko do odczytu)",
        "dic_dialog_sharetouser_user_email": "E-mail osoby, której udostępniasz",
        "dic_dialog_sharetouser_user_btn_send": "Udostępnij",
        "dic_dialog_sharetouser_user_btn_cancel": "Zamknij",
        "dic_dialog_sharetouser_user_list_col_email": "użytkownik",
        "dic_dialog_sharetouser_user_list_col_status": "status",
        "dic_dialog_sharetouser_user_list_status_waiting": "oczekuje",
        "dic_dialog_sharetouser_user_list_status_accepted": "zaakceptowany",
        "dic_dialog_sharetouser_user_list_status_added": "dodany",
        "dic_dialog_sharetouser_user_list_status_deleted": "usunięty",
        "dic_dialog_sharetouser_user_list_delete": "usuń",
        "dic_dialog_sharetouser_user_list_delete_confirm_header": "Usuń dostęp do słownika",
        "dic_dialog_sharetouser_user_list_delete_confirm_msg": "Czy jesteś pewien?",
        "dic_dialog_sharetouser_user_list_delete_confirm_btn": "usuń",
        "dic_dialog_sharetouser_user_email_invalid": "Wprowadź prawidłowy adres e-mail",
        "dic_dialog_sharetouser_user_add_msg_done": "Zaproszenie zostało wysłane e-mailem",
        "dic_dialog_sharetouser_user_add_exists": "Użytkownik otrzymał już zaproszenie",
        "dic_dialog_sharetouser_user_add_msg_error": "Wystąpił błąd. Spróbuj później.",
        "dic_dialog_sharetouser_bylink": "Możesz również udostępnić słownik przekazując osobom poniższy link. Pamietaj jednak, że udostępniając słownik za pomocą odnośnika nie masz kontroli nad tym, kto będzie z niego korzystał, ponieważ link może zostać przekazany kolejnym osobom.",
        "dic_dialog_sharetouser_bylink_catalog": "Udostępnij słownik znajomym. Wyślij im poniższy link.",
        "dic_dialog_sharetouser_bylink_btn": "Skopiuj link",
        "dic_dialog_sharetouser_bylink_copied": "Link został skopiowany do schowka.",
        "dic_dialog_sharetouser_bylink_share_btn": "Udostępnij link",
        "dic_dialog_sharetouser_bylink_share_title_prefix": "Słownik: ",
        "dic_dialog_sharetouser_disclaimer": "Podane adresy e-mail nie są zapisywane i przechowywane przez platformę WordsBucket. Służą jedynie do wysłania zaproszenia. Adres e-mail jest zapisywany i przechowywany jedynie podczas rejestracji w aplikacji WordsBucket. Przeczytaj więcej w <a href=\"https://wordsbucket.com/polityka-prywatnosci/\" target=\"_blank\">polityce prywatności</a>.",

        "sharedics_title": "Udostępnione słowniki",
        "sharedics_not_logged": "Funkcja jest dostępna tylko dla zalogowanych użytkowników.",
        "sharedics_empty_list": "Nie udostępniasz aktualnie żadnego słownika wybranym osobom. Otwórz stworzony przez siebie słownik, a następnie w menu w prawym górnym rogu wybierz opcję \"Udostępnij\".",
            

        "dic_dialog_share_title": "Udostępnij słownik w bibliotece",
        "dic_dialog_share_desc": "Zadbaj o to aby nazwa jak najlepiej odzwierciedlała jego zawartość, np: \"Dyscypliny sportowe\".",
        "dic_dialog_share_btn_change_name": "Zmień nazwę",
        "dic_dialog_share_description_desc": "Dodaj krótki opis słownika (do 200 znaków):",
        "dic_dialog_share_description_label": "Krótki opis słownika",
        "dic_dialog_share_disclaimer": "Słownik będzie dostępny w katalogu po akceptacji przez administratorów.",
        "dic_dialog_share_btn_cancel": "Anuluj",
        "dic_dialog_share_btn_ok": "Udostępnij",
        "dic_dialog_share_done_toast": "Słownik został przekazany do akceptacji. Gdy tylko zostanie zatwierdzony powiadomimy Cię o tym mailem.",

        "dic_dialog_publish_title": "Publikuj w bibliotece",
        "dic_dialog_publish_label_public": "W bibliotece",
        "dic_dialog_publish_label_disable_words_index": "Wyłącz indeksowanie słów",
        "dic_dialog_publish_label_public_highlight": "Wyróżniony",
        "dic_dialog_publish_label_order": "Kolejność",
        "dic_dialog_publish_label_author": "Autor",
        "dic_dialog_publish_label_decription": "Opis",
        "dic_dialog_publish_btn_save": "Zapisz",
        "dic_dialog_publish_btn_cancel": "Anuluj",
        "dic_dialog_publish_msg_error": "Wystąpił błąd. Spróbuj później.",
        "dic_dialog_publish_toast_done": "Zmiany zostały zapisane",

        "dic_dialog_adddic_title": "Dodaj słownik",
        "dic_dialog_adddic_name_label": "Nazwa słownika",
        "dic_dialog_adddic_btn_cancel": "Anuluj",
        "dic_dialog_adddic_btn_ok": "Dodaj",
        "dic_dialog_adddic_done_toast": "Słownik został dodany",

        "dic_dialog_copyas_title": "Skopiuj słownik",
        "dic_dialog_copyas_name_label": "Nazwa słownika",
        "dic_dialog_copyas_name_postfix": "(kopia)",
        "dic_dialog_copyas_btn_cancel": "Anuluj",
        "dic_dialog_copyas_btn_ok": "Kopiuj",
        "dic_dialog_copyas_done_toast": "Słownik został skopiowany",

        "dic_dialog_copyword_title": "Skopiuj do słownika",
        "dic_dialog_copyword_word_label": "słowo",
        "dic_dialog_copyword_dictionary_label": "Słownik",
        "dic_dialog_copyword_btn_cancel": "Anuluj",
        "dic_dialog_copyword_btn_ok": "Skopiuj",
        "dic_dialog_copyword_done_toast": "Słowo zostało skopiowane",

        "dic_dialog_addword_title": "Dodaj słowo",
        "dic_dialog_addword_word_label": "Słowo w języku obcym",
        "dic_dialog_addword_trans_label": "Tłumaczenie",
        "dic_dialog_addword_transtype_label": "Część mowy (opcjonalnie)",
        "dic_dialog_addword_dictionary_label": "Słownik",
        "dic_dialog_addword_disclaimer_csv": "Możesz też łatwo dodać wiele słów przesyłając plik CSV",
        "dic_dialog_addword_btn_csv": "Prześlij plik CSV",
        "dic_dialog_addword_btn_cancel": "Anuluj",
        "dic_dialog_addword_btn_ok": "Dodaj",
        "dic_dialog_addword_btn_ok_next": "Dodaj kolejne",
        "dic_dialog_addword_done_toast": "Słowo zostało dodane",
        "dic_dialog_addword_types": [
            "rzeczownik", "przymiotnik", "czasownik", "liczebnik", "zaimek", "przysłówek", "przyimek", "spójnik", "wykrzyknik"
        ],


        "dic_dialog_csv_title": "Dodaj słowa",
        "dic_dialog_csv_word_label": "Słowo w języku obcym",
        "dic_dialog_csv_desc1": "Prześlij plik CSV ze słowami. Możesz do tego wykorzystać Excela. W pierwszej kolumnie umieść słowo w języku obcym a w kolejnych jego tłumaczenia. Następnie wyeksportuj plik jako CSV. Kolumny powinny być rozdzielone przecinkami.",
        "dic_dialog_csv_desc2": "Opcjonalnie przy każdym tłumaczeniu możesz określić część mowy dopisując jej nazwę w nawiasie kwadratowym, np. [rzeczownik],[czasownik], ...",
        "dic_dialog_csv_desc3": "Przykładowy plik powinien wyglądać tak:",
        "dic_dialog_csv_example_code": "\"work\",\"praca, zawód\",\"pracować\"\n\"pay\",\"płacić [czasownik]\",\"płaca [rzeczownik]\"",
        "dic_dialog_csv_desc4": "UWAGA! Aktualne słowa w słowniku zostaną usunięte. Maksymalna liczba słów w CSV wynosi 1000.",
        "dic_dialog_csv_msg_desktop_url": "Najwygodniej zrobisz to na komputerze wchodząc na adres <a href=\"https://enpl.wordsbucket.com\">https://enpl.wordsbucket.com</a>",
        "dic_dialog_csv_upload_label": "Upuść tutaj plik CSV",
        "dic_dialog_csv_upload_btn_browse": "Przeglądaj pliki",
        "dic_dialog_csv_dictionary_label": "Słownik",
        "dic_dialog_csv_encoding_label": "Kodowanie znaków",
        "dic_dialog_csv_btn_cancel": "Anuluj",
        "dic_dialog_csv_btn_ok": "Wyślij",
        "dic_dialog_csv_import_done_toast": "Słowa zostały dodane",
        "dic_dialog_csv_error_alert": "Wystąpił błąd. Upewnij się, że przesłałeś prawidłowy format pliku CSV.",


        "dic_dialog_editword_title": "Edytuj słowo",
        "dic_dialog_editword_word_label": "Słowo w języku obcym",
        "dic_dialog_editword_trans_label": "Tłumaczenie",
        "dic_dialog_editword_dictionary_label": "Słownik",
        "dic_dialog_editword_btn_cancel": "Anuluj",
        "dic_dialog_editword_btn_ok": "Zapisz",
        "dic_dialog_editword_done_toast": "Słowo zostało zapisane",

        "dic_item_opts_speech": "Wymowa",
        "dic_item_opts_diki": "Diki",
        "dic_item_opts_add_to_bookmarks": "Dodaj do ulubionych",
        "dic_item_opts_del_from_bookmarks": "Usuń z ulubionych",
        "dic_item_opts_copy_to_dic": "Skopiuj do słownika",
        "dic_item_opts_edit": "Edytuj",
        "dic_item_opts_delete": "Usuń",
        "dic_item_toast_undo_known_msg": "znam",
        "dic_item_toast_undo_unknown_msg": "nie znam",
        "dic_item_toast_undo_btn": "cofnij",
        "dic_item_toast_bookmarks_add": "Dodano do ulubionych",
        "dic_item_toast_bookmarks_del": "Usunięto z ulubionych",
        "dic_item_toast_bookmarks_add_btn": "Ulubione",


        "dic_dialog_largelist_title": "Tryb: lista",
        "dic_dialog_largelist_content": "Masz włączony tryb \"lista\". Przy większej liczbie słów w słowniku ten tryb może okazać się mało wydajny. Tryb \"lista\" najlepiej nadaje się do edycji słownika. Zawsze możesz zmienić tryb przechodząc do ustawień.",
        "dic_dialog_largelist_btn_close": "Zamknij",
        "dic_dialog_largelist_btn_settings": "Ustawienia",

        "tip_register_header": "Zarejestruj się",
        "tip_register_content": "Zarejestruj się aby mieć pewność, że Twoje słowniki pozostaną na trwałe. Tylko wtedy będą synchronizowane z serwerem. Będziesz mógł korzystać z aplikacji na wielu urządzaniach oraz korzystać z funkcji, które są dostępne tylko dla zalogowanych użytkowników.",
        "tip_register_btn": "Zarejestruj się",
        "tip_register_btn_cancel": "Zamknij",
        

    }
}

export default lang;