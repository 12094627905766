import React, { Component } from 'react';
import Header from './../Components/Header';
import Drawer from './../Components/Drawer';
import Loader from './../Components/Loader';
import Toast from './../Components/Toast';
import Alert from './../Components/Alert';
import Confirm from './../Components/Confirm';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';

import DialogDicAdd from '../Components/DialogDicAdd';
import DialogDicRename from '../Components/DialogDicRename';
import DialogDicCopyAs from '../Components/DialogDicCopyAs';
import DialogWordsSort from '../Components/DialogWordsSort';
import DialogDicShare from '../Components/DialogDicShare';
import DialogDicShareToUser from '../Components/DialogDicShareToUser';
import DialogDicShareCatalog from '../Components/DialogDicShareCatalog';
import DialogWordAdd from '../Components/DialogWordAdd';
import DialogWordAddCSV from '../Components/DialogWordAddCSV';
import DialogWordEdit from '../Components/DialogWordEdit';
import DialogWordCopy from '../Components/DialogWordCopy';
import DialogWordsShow from '../Components/DialogWordsShow';
import DialogWordsLargeList from '../Components/DialogWordsLargeList';
import DialogDicPublish from '../Components/DialogDicPublish';

class Layout extends Component {

    actionReload() {
        if(this.props.onlineRequiredCallback){
            this.props.onlineRequiredCallback();
        }
    }

    render() {

        var content = this.props.children;
        if(this.props.onlineRequiredCallback && !Tools.onLine()){
            content = (
                <div className="compact-content offline-msg">
                    {Lang.get("offline_msg")}
                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionReload()}>
                        <i className="material-icons">refresh</i> {Lang.get("offline_msg_btn_reload")}
                    </button>
                </div>
            )
        }

        return (
            <div id="page" className={"noselect" + (Tools.isIOS() ? ' ios' : '')}>
                <div className="app-layout mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
                    <Header title={this.props.headerTitle} menu={this.props.headerMenu} searchValue={this.props.searchValue} />
                    <Drawer disableMenuItemSelect={this.props.disableMenuItemSelect}/>
                    <main className="mdl-layout__content mdl-color--grey-100">
                        <div className={"main-content" + (this.props.contentClass ? " " + this.props.contentClass : "")}>
                            {content}
                        </div>
                    </main>
                </div>
                <Loader />
                <Toast />
                <Alert />
                <Confirm />

                <DialogDicAdd />
                <DialogDicRename />
                <DialogDicCopyAs />
                <DialogWordsSort />
                <DialogDicShare />
                <DialogDicShareToUser />
                <DialogDicShareCatalog />
                <DialogWordAdd />
                <DialogWordAddCSV />
                <DialogWordEdit />
                <DialogWordCopy />
                <DialogWordsShow />
                <DialogWordsLargeList />
                <DialogDicPublish />
            </div>
        )
    }

}

export default Layout;
