import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import LocalStorage from './../Models/LocalStorage';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser.js';
import Lang from '../Misc/Lang';
import WordsCnt from '../Components/WordsCnt';
import Tools from '../Misc/Tools';
import { Speech } from '../Misc/Speech';
import WordMenu from '../Components/WordMenu';

import {
    setMultipleData, 
    setDictionary,
    setDialogWordsShow,
    setDialogWordAdd,
    setDialogWordAddCSV,
    setBookmarksDictionary,
    setDicWordsCntChangeKnown
} from '../ReduxActions/AppActions';    

class DictionarySlideshow extends Component {

    _tipHiddenShowed = false;
    _tipSlidesCarousel = false;
    _carouselClickCnt = 0;

    constructor(props) {
        super(props);
        this.state = {
            learnMode: null,
            dictionary: null,
            word: null,
            isBookmarker: false,
            slideIndex: 0,
            showWords: []
        }

        window.addEventListener('resize', () => {
            this.setCorrectVHTrick();
        });

        this.attachKeyboardEventsFn = this.attachKeyboardEvents.bind(this);

    }

    setCorrectVHTrick(){
        // w css element ma style: height:calc((var(--vh, 1vh) * 100) - 104px);
        let slideshowElement = document.getElementById("slideshow");
        if(slideshowElement){
            let vh = window.innerHeight * 0.01;
            slideshowElement.style.setProperty('--vh', `${vh}px`);
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.attachKeyboardEventsFn, true);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.attachKeyboardEventsFn, true);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.appState.dictionary){
            if (JSON.stringify(this.props.appState.dictionary) !== JSON.stringify(prevProps.appState.dictionary)) {
                this.setState({
                    dictionary: this.props.appState.dictionary, 
                    learnMode: this.props.appState.dictionary.LearnMode
                    },
                    () => {
                        this.setSlideIndex(this.state.dictionary.CurrentSlide);
                        this.clearShow();
                        Tools.loader(this.props, false);
                    }
                );
            }
        }
        Tools.mdlUpgrade();
        this.setCorrectVHTrick();
    }

    

    /*** EVENTS ***/
    wordTouchEvents = new Tools.elementTouchEvents(
        (e) => {
            this.switchShow();
            LocalStorage.setTipDoneHiddenWords();
        },
        (e) => {
            if(LocalStorage.getSettingSpeechOnTouch()){
                this.actionWordSpeech(this.state.word.Word);
            }
        },
        300
    );

    transTouchEvents = new Tools.elementTouchEvents(
        (e) => {
            this.switchShow();
            LocalStorage.setTipDoneHiddenWords();
        }
    )

    prevSlideTouchEvents = new Tools.elementTouchEvents(
        (e) => {this.actionPrevSlide()},
        (e) => {this.goSlideFirst()},
        1000
    );

    nextSlideTouchEvents = new Tools.elementTouchEvents(
        (e) => {this.actionNextSlide()},
        (e) => {this.goSlideLast()},
        1000
    );


    attachKeyboardEvents(event) {
        if(!this.isActiveInputElement()){
            if (event.keyCode === 38) {
                this.actionWordSpeech(this.state.word.Word);
                event.preventDefault();
            }
            else if (event.keyCode === 39) {
                this.actionNextSlide();
                event.preventDefault();
            }
            else if (event.keyCode === 37) {
                this.actionPrevSlide();
                event.preventDefault();
            }
            else if (event.keyCode === 32) {
                this.actionWordKnown(event, this.state.word);
                event.preventDefault();

            }
        }
    }


    isActiveInputElement() {
        return /(INPUT|TEXTAREA|SELECT)/.test(document.activeElement.nodeName);
    }


    canSpeech() {
        return Speech.canSpeech();
    }


    /* ----- */
    actionWordSpeech(word) {
        Speech.speech(word)
        LocalStorage.setTipDoneHoldSpeech();
    }


    actionChangeLearnMode(mode) {
        this.setState({learnMode: mode})
        this.clearShow();
        ModelDictionary.changeLearnMode(this.state.dictionary.ID, mode)
            .catch(Tools.log)

        if(!this._tipHiddenShowed && mode !== ModelDictionary.DIC_LEARNMODE_LEARN && !LocalStorage.getTipDoneHiddenWords()){
            this._tipHiddenShowed = true;
            Tools.toast(Lang.get("dic_learnmode_toast_tip_hidden"), Lang.get("dic_learnmode_toast_tip_hidden_btn"), () => {
                LocalStorage.setTipDoneHiddenWords();
                Tools.toastClear();
            }, 5000);
        }
    }

    actionWordAddToBookmarks(word, history) {
        this.setState({isBookmarker: true});
        ModelDictionary.addWordToBookmarks(word.ID)
            .then(() => {
                return ModelDictionary.getBookmarksDictionary(true);
            })
            .then(bookmarksDictionary => {
                this.props.dispatch(setBookmarksDictionary(bookmarksDictionary));
                return bookmarksDictionary;
            })
            .catch((e) => {
                this.setState({isBookmarker: false});
                Tools.log(e);
            })
    }

    actionWordDelFromBookmarks(word, history) {
        this.setState({isBookmarker: false});
        ModelDictionary.delWordFromBookmarks(word)
            .then(() => {
                return ModelDictionary.getBookmarksDictionary(true);
            })
            .then(bookmarksDictionary => {
                this.props.dispatch(setBookmarksDictionary(bookmarksDictionary));
                return bookmarksDictionary;
            })
            .catch((e) => {
                this.setState({isBookmarker: true});
                Tools.log(e);
            })
    }

    actionWordKnown(e, word) {

        var _word;
        for(var _wordIt of this.state.dictionary.Words){
            if(_wordIt.ID === word.ID){
                _word = _wordIt;
            }
        }
        //var _word = this.state.dictionary.Words[this.getSlideIndex()];
        if(_word){
            _word.Known = _word.Known ? 0 : 1;
            this.setState(
                {word: _word, dictionary: ModelDictionary.filterDictionaryWords(this.state.dictionary)},
                () => {
                    this.setSlideIndex(this.getSlideIndex());
                    if(_word.Known){
                        this.props.dispatch(setDicWordsCntChangeKnown(1));
                    }
                    else {
                        this.props.dispatch(setDicWordsCntChangeKnown(-1));
                    }
                }
            );
        }
        
        ModelDictionary.wordKnown(word)
            .then((isKnown) => {
                if (this.state.dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN && isKnown) {
                    Tools.toastKnown(
                        Lang.get("dic_item_toast_undo_known_msg") + ": <strong>" + word.Word + "</strong>", 
                        Lang.get("dic_item_toast_undo_btn"), 
                        () => {
                            Tools.toastClear();
                            ModelDictionary.wordKnown(word)
                                .then(() => {
                                    return ModelDictionary.getCurrentDictionarySorted();
                                })
                                .then((dictionary) => {
                                    this.props.dispatch(setDictionary(dictionary));
                                })
                                .then(() => {
                                    this.changeDrawerWordsCnt();
                                });
                        },
                        3000
                    );
                }
                else if (this.state.dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN && !isKnown) {
                    Tools.toastKnown(
                        Lang.get("dic_item_toast_undo_unknown_msg") + ": <strong>" + word.Word + "</strong>", 
                        Lang.get("dic_item_toast_undo_btn"), 
                        () => {
                            Tools.toastClear();
                            ModelDictionary.wordKnown(word)
                                .then(() => {
                                    return ModelDictionary.getCurrentDictionarySorted();
                                })
                                .then((dictionary) => {
                                    this.props.dispatch(setDictionary(dictionary));
                                })
                                .then(() => {
                                    this.changeDrawerWordsCnt();
                                })
                        },
                        3000
                    );
                }
            })
            .then(() => {
                this.changeDrawerWordsCnt();
            })
            .catch(e => {
                Tools.log(e)
            })
        
    }

    changeDrawerWordsCnt(){

        Promise.all([
            ModelDictionary.getBookmarksDictionary(true),
            ModelDictionary.getAllUserDictionaries()
        ])
            .then(results => {
                var bookmarksDictionary = results[0];
                var dictionaries = results[1];

                var userDictionaries = dictionaries.user;
                var userCatalogDictionaries = dictionaries.catalog;
                var userSharedDictionaries = dictionaries.shared;
                this.props.dispatch(setMultipleData({
                    bookmarksDictionary: bookmarksDictionary,
                    userDictionaries: userDictionaries,
                    userCatalogDictionaries: userCatalogDictionaries,
                    userSharedDictionaries: userSharedDictionaries,
                }));
            })
            .catch(Tools.log)

    }

    actionChangeShowWords() {
        this.props.dispatch(setDialogWordsShow());
    }

    actionAddWord() {
        this.props.dispatch(setDialogWordAdd(
            {
                uploadCsvCallback: this.actionAddWordCSV.bind(this),
                addDoneCallback: () => {
                    this.goSlideLast();
                }
            }
        ));
    }

    actionAddWordCSV() {
        if(Tools.onLine()){
            ModelUser.getUserOID()
                .then((userOID => {
                    if(userOID){
                        this.props.dispatch(setDialogWordAddCSV());
                    }
                    else {
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_login_required"));
                    }
                }))
                .catch(Tools.log)
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionNextSlide(){
        var currentSlideIndex = this.getSlideIndex();
        currentSlideIndex++;
        currentSlideIndex = this.filterSlideIndex(currentSlideIndex);
        this.setSlideIndex(currentSlideIndex);
        if(this.isLastSlide()){
            if(!LocalStorage.getTipSlidesCarousel() && !this._tipSlidesCarousel && this.state.dictionary && this.state.dictionary.Words.length > 5){
                this._tipSlidesCarousel = true;
                Tools.toast(Lang.get("dic_slideshow_carousel_toast_tip"), Lang.get("dic_slideshow_carousel_toast_tip_btn"), () => {
                    LocalStorage.setTipSlidesCarousel();
                    Tools.toastClear();
                }, 5000);
            }

            this._carouselClickCnt++;
            if(this._carouselClickCnt > 3){
                this.goSlideFirst();
                this._carouselClickCnt = 0;
                LocalStorage.setTipSlidesCarousel();
            }
        }
        else {
            this._carouselClickCnt = 0;
        }
    }

    actionPrevSlide(){
        var currentSlideIndex = this.getSlideIndex();
        currentSlideIndex--;
        currentSlideIndex = this.filterSlideIndex(currentSlideIndex);
        this.setSlideIndex(currentSlideIndex);
        if(this.isFirstSlide()){
            this._carouselClickCnt++;
            if(this._carouselClickCnt > 3){
                this.goSlideLast();
                this._carouselClickCnt = 0;
            }
        }
        else {
            this._carouselClickCnt = 0;
        }
    }

    actionClearKnown() {
        Tools.loader(this.props, true);
        ModelDictionary.clearKnown(this.state.dictionary.ID)
            .then(() => {
                return ModelDictionary.getCurrentDictionarySorted()
            })
            .then((dictionary) => {
                return ModelDictionary.setSlideIndex(dictionary.ID, 0)
                    .then(() => {
                        dictionary.CurrentSlide = 0;
                        return dictionary;
                    })
            })
            .then((dictionary) => {
                this.props.dispatch(setDictionary(dictionary));
            })
            .then(() => {
                Tools.toast(Lang.get("dic_dialog_clearknown_toast"), null, null, 3500);
            })
            .then(() => {
                return this.changeDrawerWordsCnt();
            })
            .catch(Tools.log)
    }

    goSlideFirst() {
        this.setSlideIndex(0);
    }

    goSlideLast() {
        this.setSlideIndex(this.filterSlideIndex(1000000));
    }

    filterSlideIndex(index){
        var slideIndex = index;
        if(index > this.getSlidesCnt() -1){
            slideIndex = this.getSlidesCnt() - 1;  
        }
        if(slideIndex < 0){
            slideIndex = 0;
        }
        return slideIndex;
    }

    getSlideIndex(){
        var currentSlideIndex = this.state.slideIndex;
        currentSlideIndex = this.filterSlideIndex(currentSlideIndex);
        return currentSlideIndex;
    }

    setSlideIndex(index){
        ModelDictionary.setSlideIndex(this.state.dictionary.ID, index);
        this.setState({slideIndex: index}, () => {
            this.setSlide();
        });
    }

    getSlidesCnt(){
        return this.state.dictionary.Words.length;
    }

    isFirstSlide(){
        return this.getSlideIndex() === 0;
    }

    isLastSlide(){
        return this.getSlideIndex() >= this.getSlidesCnt() -1;
    }

    getWord(){
        if(this.getSlidesCnt() > 0){
            return this.state.dictionary.Words[this.getSlideIndex()];
        }
        return null
    }

    setSlide(){
        var word = this.getWord();
        if(word){
            this.setState({word: word}, () => {
                if(this.props.appState.bookmarksDictionary){
                    this.setState({isBookmarker: Tools.isWordInBookmarks(word, this.props.appState.bookmarksDictionary.Words)})
                }
            });
        }
    }

    isShow(){
        var word = this.getWord();
        if(word){
            return this.state.showWords.indexOf(word.Word) < 0 ? false : true;
        }
        return false;
    }

    switchShow(){
        var word = this.getWord();
        if(word){
            var newShowWords = [];
            if(this.isShow()){
                for(var _word of this.state.showWords){
                    if(_word !== word.Word){
                        newShowWords.push(_word);
                    }
                }
            }
            else {
                newShowWords = this.state.showWords;
                newShowWords.push(word.Word);
            }
            this.setState({showWords: newShowWords});
        }
    }

    clearShow() {
        this.setState({showWords: []});
    }

   


    render() {

        const { appState } = this.props;
        const { dictionary, bookmarksDictionary } = appState;
        const { word } = this.state;

        return (
            <Route render={({ history }) => (
                <div>
                    {dictionary && dictionary.Words.length > 0 && word != null && bookmarksDictionary &&
                        <div id="slideshow" className={"slideshow" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_LEFT && !this.isShow() ? " slideshow--hide-top" : "") + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_RIGHT && !this.isShow() ? " slideshow--hide-bottom" : "")}>

                            <div className="toggle-hide">
                                <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_LEARN ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("learn")}>{Lang.get("dic_learnmode_learn")}</button>
                                <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_LEFT ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("left")}>{Lang.get("dic_learnmode_top")}</button>
                                <button className={"mdl-button mdl-js-button mdl-button--raised" + (this.state.learnMode === ModelDictionary.DIC_LEARNMODE_RIGHT ? " mdl-button--accent" : "")} onClick={() => this.actionChangeLearnMode("right")}>{Lang.get("dic_learnmode_bottom")}</button>
                            </div>

                            <div className="word-tools">
                                <div className="word__known-coutner"><WordsCnt /></div>
                                <div className="word-tools__buttons">
                                    {dictionary.Source === ModelDictionary.DIC_SOURCE_USER &&
                                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionAddWord()}>
                                            <i className="material-icons">add</i>
                                        </button>
                                    }
                                    {dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS && this.state.isBookmarker &&
                                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionWordDelFromBookmarks(word, history)}>
                                            <i className="material-icons">star</i>
                                        </button>
                                    }
                                    {dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS && !this.state.isBookmarker &&
                                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionWordAddToBookmarks(word, history)}>
                                            <i className="material-icons">star_border</i>
                                        </button>
                                    }
                                    {Tools.isIOS() && this.canSpeech() && 
                                        <button className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionWordSpeech(word.Word)}>
                                            <i className="material-icons">record_voice_over</i>
                                        </button>
                                    }

                                    <WordMenu 
                                        word={word} 
                                        dictionary={dictionary}
                                        isInBookmarks={this.state.isBookmarker}
                                        userDictionariesLength={appState.userDictionaries.length} 
                                        onBookmark={(marked) => { this.setState({isBookmarker: marked}); }}
                                    />
                                </div>
                            </div>

                            <h3 className="word" {...this.wordTouchEvents}>{word.Word}</h3>

                            <div className="trans" {...this.transTouchEvents}>
                                <div className="trans-fade trans-fade--top"></div>
                                <div className="trans-scroll">
                                    <ul>
                                        {this.state.word.Trans.map((trans, indexWordTrans) =>
                                            <li key={"word-" + indexWordTrans}>
                                                {trans.Word}
                                                {trans.Type &&
                                                    <span>{trans.Type}</span>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="trans-fade trans-fade--bottom"></div>
                            </div>

                            <div className="paggination">
                                <div className="paggination__buttons">
                                    <button className={"mdl-button mdl-js-button mdl-button--fab" + (this.isFirstSlide() ? "" : " mdl-button--colored")} {...this.prevSlideTouchEvents}>
                                        <i className="material-icons">navigate_before</i>
                                    </button>
                                    <button className={"mdl-button mdl-js-button mdl-button--fab" + (word.Known > 0 ? " mdl-button--colored mdl-button--colored-special" : "")} onClick={(e) => this.actionWordKnown(e, word)}>
                                        <i className="material-icons">done</i>
                                    </button>
                                    <button className={"mdl-button mdl-js-button mdl-button--fab" + (this.isLastSlide() ? "" : " mdl-button--colored")} {...this.nextSlideTouchEvents}>
                                        <i className="material-icons">navigate_next</i>
                                    </button>
                                </div>
                                <div className="paggination__counter">
                                    {this.getSlideIndex()+1}/{this.getSlidesCnt()}
                                </div>
                            </div>

                        </div>
                    }

                    {dictionary && dictionary.WordsCnt > 0 && dictionary.Words.length <= 0 && 
                        <div className="no-words-to-show">
                            <p>{Lang.get("dic_nowordstoshow")}</p>
                            <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionChangeShowWords()}>
                                <i className="material-icons">visibility</i> {Lang.get("dic_nowordstoshow_btn_change")}
                            </button>
                            {dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN &&
                                <div>
                                    <p>{Lang.get("dic_nowordstoshow_or_clearknown")}</p>
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionClearKnown()}>
                                        <i className="material-icons">clear_all</i> {Lang.get("dic_nowordstoshow_btn_clearknown")}
                                    </button>
                                </div>
                            }
                        </div>
                    }

                    {dictionary && (dictionary.WordsCnt === 0 && dictionary.Words.length === 0) && 
                        <div>
                            <div className="no-words-to-show">
                                <p>{Lang.get("dic_empty")}</p>
                                {dictionary.Source === ModelDictionary.DIC_SOURCE_USER &&
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionAddWord()}>
                                        <i className="material-icons">add</i> {Lang.get("dic_empty_btn_add_word")}
                                    </button>
                                }
                            </div>
                        </div>
                    }

                </div>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(DictionarySlideshow);
