import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Tools from '../Misc/Tools';
import { Speech } from '../Misc/Speech';
import Config from '../Config/Config';
import Lang from '../Misc/Lang';

import { 
    setDialogWordEdit,
    setDialogWordCopy,
    setMultipleData,
    setBookmarksDictionary
} from '../ReduxActions/AppActions';


class WordMenu extends Component {

    _mdlMenuInitialized = false;
    _mdlMenuElementsToAttach: [];
    _mdlMenuButtonElement: null;

    constructor(props) {
        super(props);

        this.state = {
            word: this.props.word,
            buildMenu: false // false - menu nie osadzone w DOM
        }
    }

    componentDidUpdate(prevProps) {
        if(this.state.buildMenu && !this._mdlMenuInitialized){
            this._mdlMenuInitialized = true;
            Tools.mdlElementAttach(this._mdlMenuElementsToAttach);
            this._mdlMenuButtonElement.click();
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if (JSON.stringify(nextProps.word) !== JSON.stringify(nextState.word)) {
            this.state.word = nextProps.word;
        }
        return true;
    }

    buildMenu(e, elementsToAttach){
        this.setState({buildMenu: true});
        e.stopPropagation();
        this._mdlMenuElementsToAttach = elementsToAttach;
        this._mdlMenuButtonElement = e.currentTarget;
    }

    canSpeech() {
        return Speech.canSpeech();
    }

    /* ----- */
    actionWordSpeech(word) {
        Speech.speech(word)
    }

    /* ----- */
    actionEditWord(wordId) {
        ModelDictionary.getWordById(wordId)
            .then((word) => {
                if (word) {
                    word = Tools.cloneObject(word); // klonujemy objekt
                    this.props.dispatch(setDialogWordEdit({word: word}));
                }
            })
            .catch(Tools.log)
    }

    /* ----- */
    actionWordDiki(word) {
        if (Config.get("extDictionaryWordUrl")) {
            window.open(Config.get("extDictionaryWordUrl") + encodeURI(word), '_blank').focus();
        }
    }

    /* ----- */
    actionWordAddToBookmarks(word, history) {
        ModelDictionary.addWordToBookmarks(word.ID)
            .then(() => {
                return ModelDictionary.getBookmarksDictionary(true);
            })
            .then(bookmarksDictionary => {
                this.props.dispatch(setBookmarksDictionary(bookmarksDictionary));
                return bookmarksDictionary;
            })
            .then((bookmarksDictionary) => {
                if(this.props.onBookmark){
                    this.props.onBookmark(true);
                }
                Tools.toast(Lang.get("dic_item_toast_bookmarks_add"), Lang.get("dic_item_toast_bookmarks_add_btn"), () => {
                    history.push('/dic/' + bookmarksDictionary.ID);
                });
            })
            .catch(Tools.log)
    }

    actionWordDelFromBookmarks(word, history) {
        ModelDictionary.delWordFromBookmarks(word)
            .then(() => {
                return ModelDictionary.getBookmarksDictionary(true);
            })
            .then(bookmarksDictionary => {
                this.props.dispatch(setBookmarksDictionary(bookmarksDictionary));
                return bookmarksDictionary;
            })
            .then((bookmarksDictionary) => {
                if(this.props.onBookmark){
                    this.props.onBookmark(false);
                }
                Tools.toast(Lang.get("dic_item_toast_bookmarks_del"), Lang.get("dic_item_toast_bookmarks_add_btn"), () => {
                    history.push('/dic/' + bookmarksDictionary.ID);
                });
            })
            .catch(Tools.log)
    }

    actionCopyWordToDictionary(wordId) {
        ModelDictionary.getWordById(wordId)
            .then((word) => {
                if (word) {
                    this.props.dispatch(setDialogWordCopy({word: word}));
                }
            })
            .catch(Tools.log)
        
    }


    actionDeleteWord(wordId) {
        Tools.confirm(
            this.props, 
            () => {
                ModelDictionary.deleteWord(wordId)
                    .then(() => {
                        Tools.toast(Lang.get("dic_dialog_deleteword_done_toast"));
                        return ModelDictionary.getCurrentDictionarySorted()
                    })
                    .then((dictionary) => {
                        return Promise.all([
                            ModelDictionary.getBookmarksDictionary(true),
                            ModelDictionary.getUserDictionaries()
                        ])
                            .then(results => {
                                var bookmarksDictionary = results[0];
                                var userDictionaries = results[1];
                                this.props.dispatch(setMultipleData({
                                    bookmarksDictionary: bookmarksDictionary,
                                    userDictionaries: userDictionaries,
                                    dictionary: dictionary
                                }));
                            })
                    })
                    .catch(Tools.log)
            }, 
            Lang.get("dic_dialog_deleteword_title"),
            Lang.get("dic_dialog_deleteword_confirm"),
            Lang.get("dic_dialog_deleteword_btn_ok")
        )
    }
    



    render() {
        const { word, isInBookmarks, dictionary, userDictionariesLength} = this.props;
        return (
            <Route render={({ history }) => (
                <div>
                    <button className={"mdl-ignore-js mdl-button mdl-js-button mdl-js-ripple-effect" + (this.props.mini ? " mdl-button--icon" : " mdl-button--fab mdl-button--mini-fab")} id={"worditemopts-opts-" + word.ID} onClick={(e) => { this.buildMenu(e, ['worditemopts-opts-'+word.ID, 'worditemopts-opts-menu-'+word.ID])}}>
                        <i className="material-icons">more_vert</i>
                    </button>
                    {this.state.buildMenu &&
                        <ul className="mdl-ignore-js mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect" htmlFor={"worditemopts-opts-" + word.ID} id={"worditemopts-opts-menu-" + word.ID}>
                            <li className={"mdl-menu__item" + (this.canSpeech() ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionWordSpeech(word.Word)}}><i className="material-icons">record_voice_over</i>{Lang.get("dic_item_opts_speech")}</li>
                            <li className={"mdl-menu__item" + (Config.get("extDictionaryWordUrl")  ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionWordDiki(word.Word)}}><i className="material-icons">library_books</i>{Lang.get("dic_item_opts_diki")}</li>
                            <li className={"mdl-menu__item" + (dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS && isInBookmarks ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionWordDelFromBookmarks(word, history)}}><i className="material-icons">star</i>{Lang.get("dic_item_opts_del_from_bookmarks")}</li>
                            <li className={"mdl-menu__item" + (dictionary.Type !== ModelDictionary.DIC_TYPE_BOOKMARKS && !isInBookmarks ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionWordAddToBookmarks(word, history)}}><i className="material-icons">star_border</i>{Lang.get("dic_item_opts_add_to_bookmarks")}</li>
                            <li className={"mdl-menu__item" + (userDictionariesLength > 0 ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionCopyWordToDictionary(word.ID)}}><i className="material-icons">note</i>{Lang.get("dic_item_opts_copy_to_dic")}</li>
                            <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionEditWord(word.ID)}}><i className="material-icons">edit</i>{Lang.get("dic_item_opts_edit")}</li>
                            <li className={"mdl-menu__item" + (dictionary.Source === ModelDictionary.DIC_SOURCE_USER ? "" : " hide")} onClick={e => {e.stopPropagation();this.actionDeleteWord(word.ID)}}><i className="material-icons">delete</i>{Lang.get("dic_item_opts_delete")}</li>
                        </ul>
                    }
                </div>
            )} />
        )
    }

}

export default connect(function (store) {
    return {}
})(WordMenu);
