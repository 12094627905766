import React, { Component } from 'react';
import { connect } from "react-redux";
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogDicShareCatalog extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            shareLink: ""
        }
        this.dialogShow = props.appDialogs.dialogDicShareCatalog;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-share-catalog');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicShareCatalog && nextProps.appDialogs.dialogDicShareCatalog !== this.dialogShow){
            this.setState({
                shareLink: ""
            });
            this.dialogShow = nextProps.appDialogs.dialogDicShareCatalog;
            if(this.props.appState.dictionary && this.props.appState.dictionary.ID){
                this.setState({shareLink: document.location.origin + '/#/init-dic/' + this.props.appState.dictionary.OID});
                Tools.dialogNavBackOpen(this.dialog);
            }
            return true;
        }
        if(this.state.shareLink !== nextState.shareLink){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    localToast(msg){
        var toast = document.querySelector('#dicshare-catalog-toast');
        if(toast && toast.MaterialSnackbar){
            toast.MaterialSnackbar.showSnackbar({message: msg});
        }

    }

    actionShareLink(){
        this.shareLinkInput.select();
        document.execCommand('copy');
        this.localToast(Lang.get("dic_dialog_sharetouser_bylink_copied"));
    }


    actionShareLinkNative(){
        if (navigator.share) {
            navigator.share({
                title: Lang.get("dic_dialog_sharetouser_bylink_share_title_prefix") + " " + this.props.appState.dictionary.Name,
                text: Lang.get("dic_dialog_sharetouser_bylink_share_title_prefix") + " " + this.props.appState.dictionary.Name,
                url: this.state.shareLink
            })
            .catch(Tools.log);
        }
    }
    


    render() {

        return (

            <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-share-catalog" data-dialog-id="share-share-to-user">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_sharetouser_title")}</h4>
                <div className="mdl-dialog__content">
                    <div className="share-link">
                        <p>{Lang.get("dic_dialog_sharetouser_bylink_catalog")}</p>
                        <input readOnly className="mdl-textfield__input" ref={(input) => this.shareLinkInput = input} value={this.state.shareLink} />
                        <div className="mdl-dialog__actions">
                            {navigator.share !== undefined && <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareLinkNative()}>{Lang.get("dic_dialog_sharetouser_bylink_share_btn")}</button>}
                            <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionShareLink()}>{Lang.get("dic_dialog_sharetouser_bylink_btn")}</button>
                        </div>
                    </div>
                </div>

                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_sharetouser_user_btn_cancel")}</button>
                </div>

                <div id="dicshare-catalog-toast" className="mdl-js-snackbar mdl-snackbar">
                    <div className="mdl-snackbar__text"></div>
                    <button className="mdl-snackbar__action" type="button"></button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicShareCatalog)