import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';
import ModelDictionary from './../Models/ModelDictionary';
import { setMultipleData } from '../ReduxActions/AppActions';

class DeleteAccountPage extends Component {

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    constructor(props) {
        super(props);
        this.state = {
            userOID: props.match.params.userOID,
            token: props.match.params.token ? props.match.params.token : null,
            mode: 0, // 1 - konto zostało usunięte
        }
    }

    componentDidMount() {
        this.actionDeleteAccount();
    }

    actionDeleteAccount(){
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelUser.deleteAccount(parseInt(this.state.userOID, 10), this.state.token)
                .then((response) => {
                    Tools.loader(this.props, false);
                    this.setState({mode: 1});
                    return Promise.all([
                        ModelUser.getUser(),
                        ModelDictionary.getCurrentDictionary(),
                        ModelDictionary.getBookmarksDictionary(true),
                        ModelDictionary.getUserDictionaries(),
                        ModelDictionary.getUserCatalogDictionaries(),
                        ModelDictionary.getUserSharedDictionaries()
                    ])
                        .then(results => {
                            this.props.dispatch(setMultipleData({
                                user: results[0],
                                dictionary: results[1],
                                bookmarksDictionary: results[2],
                                userDictionaries: results[3],
                                userCatalogDictionaries: results[4],
                                userSharedDictionaries: results[5],
                            }));
                        })
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                });
        }
        else {
            Tools.confirm(
                this.props, 
                () => {
                    this.actionDeleteAccount();
                }, 
                Lang.get("dialog_alert_msg_offline"),
                "",
                Lang.get("dialog_alert_btn_retry"),
                true
            )
            
        }
    }
    
    componentDidUpdate() {
        Tools.mdlUpgrade();
    }



    render() {

        return (
            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("deleteaccount_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    <div className="page-static compact-content">

                        {this.state.mode === 1 && 
                            <div className="info-box">
                                {Lang.get("deleteaccount_msg_ok")}
                            </div>
                        }

                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(DeleteAccountPage)
