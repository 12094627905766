export default function reducer(state={
    user: null,
    dictionary: null,
    bookmarksDictionary: null,
    userDictionaries: [],
    userCatalogDictionaries: [],
    userSharedDictionaries: [],
  }, action) {

    switch (action.type) {
      case "SET_USER": {
        return {...state, user: action.payload}
      }
      case "SET_DICTIONARY": {
        return {...state, dictionary: action.payload}
      }
      case "SET_BOOKMARKS_DICTIONARY": {
        return {...state, bookmarksDictionary: action.payload}
      }
      case "SET_USER_DICTIONARIES": {
        return {...state, userDictionaries: action.payload}
      }
      case "SET_USER_CATALOG_DICTIONARIES": {
        return {...state, userCatalogDictionaries: action.payload}
      }
      case "SET_USER_SHARED_DICTIONARIES": {
        return {...state, userSharedDictionaries: action.payload}
      }

      case "SET_MULTIPLE_DATA": {
        return {...state, ...action.payload}
      }


      default: {
        return state;
      }
    }
}
