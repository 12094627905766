class OnlineCheck {

    ONLINE = false;

    TEST_REQUEST = '/ping.pong';
    TEST_FREQ = 60 * 1000;

    timer = null;

    onlineEvents = [];
    offlineEvents = [];

    constructor() {
        this.ONLINE = navigator.onLine;
        
        window.addEventListener('online',  () => { this.eventOnline() });
        window.addEventListener('offline',  () => { this.eventOffline() });
        
        this.runTimer();
    }

    /* Wywoływany opcjonalnie - jeżeli chcemy nadpisać TEST_FREQ i TEST_REQUEST */
    init(testFreeq, testFile){
        if(testFreeq){
            this.TEST_FREQ = parseInt(testFreeq, 10);
        }
        if(testFile){
            this.TEST_REQUEST = testFile;
        }
        if(this.timer){
            clearInterval(this.timer);
        }
        this.runTimer();
    }

    runTimer(){
        this.timer = setInterval(() => {
            this.checkOnline();
        }, this.TEST_FREQ)
    }

    attachOnlineEvent(callback){
        this.onlineEvents.push(callback);
    }

    attachOfflineEvent(callback){
        this.offlineEvents.push(callback);
    }

    isOnline() {
        return this.ONLINE;
    }


    /* ------- */

    eventOnline(){
        this.ONLINE = true;
        this.triggerEventsOnline();
        // dla pewności po 3s wywołujemy sprawdzenie
        setTimeout(() => {
            this.checkOnline();
        }, 3000);
    }

    eventOffline(){
        this.ONLINE = false;
        this.triggerEventsOffline();
        // dla pewności po 3s wywołujemy sprawdzenie
        setTimeout(() => {
            this.checkOnline();
        }, 3000);

    }

    checkOnline(){
        this.fetchRequest()
            .then(resp => {
                if(!this.ONLINE){
                    this.ONLINE = true;
                    this.triggerEventsOnline();
                }
            })
            .catch(e => {
                if(this.ONLINE){
                    this.ONLINE = false;
                    this.triggerEventsOffline();
                }
            })
    }

    fetchRequest(){
        return fetch(this.TEST_REQUEST, {method: 'HEAD'});
    }

    triggerEventsOnline(){
        if(this.onlineEvents.length > 0){
            for(var callback of this.onlineEvents){
                callback();
            }
        }
    }

    triggerEventsOffline(){
        if(this.offlineEvents.length > 0){
            for(var callback of this.offlineEvents){
                callback();
            }
        }
    }


}


export let onlineCheck = new OnlineCheck();