import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';
import { setUserDictionaries } from '../ReduxActions/AppActions';  

class DialogDicCopyAs extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            dictionaryID: null,
            inputNewDictionaryName: ""
        }
        this.dialogShow = props.appDialogs.dialogDicCopyAs;
    }


    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-copyas');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicCopyAs && nextProps.appDialogs.dialogDicCopyAs !== this.dialogShow){
            if(nextProps.appState.dictionary){
                this.state.dictionaryID = nextProps.appState.dictionary.ID;
                this.state.inputNewDictionaryName = nextProps.appState.dictionary.Name + ' ' + Lang.get('dic_dialog_copyas_name_postfix');
            }
            this.dialogShow = nextProps.appDialogs.dialogDicCopyAs;
            Tools.dialogNavBackOpen(this.dialog);
            return true;
        }
        if(this.state.inputNewDictionaryName !== nextState.inputNewDictionaryName){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }
    

    actionNewDictionaryNameToState(e) {
        this.setState({ inputNewDictionaryName: e.target.value })
    }

    actionCopyDictionary(history) {
        if (this.state.inputNewDictionaryName) {
            this.closeDialog();
            Tools.loader(this.props, true);
            ModelDictionary.copyDictionary(this.state.dictionaryID, this.state.inputNewDictionaryName)
                .then((dictionaryID) => {
                    Tools.toast(Lang.get("dic_dialog_copyas_done_toast"));
                    return ModelDictionary.getUserDictionaries()
                        .then((dictionaries) => {
                            Tools.loader(this.props, false);
                            this.props.dispatch(setUserDictionaries(dictionaries));
                            history.push('/dic/' + dictionaryID);
                        })
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                    history.push('/error');
                });
        }
    }



    render() {
        return (
            <Route render={({ history }) => (
                <dialog className="mdl-dialog dialog dialog-copyas" data-dialog-id="copyas">
                    <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_copyas_title")}</h4>
                    <div className="mdl-dialog__content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            <input className="mdl-textfield__input" type="text" id="DIaud1" required="required" value={this.state.inputNewDictionaryName} onChange={e => this.actionNewDictionaryNameToState(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionCopyDictionary(history) : null)} />
                            <label className="mdl-textfield__label" htmlFor="DIaud1">{Lang.get("dic_dialog_copyas_name_label")}</label>
                        </div>
                    </div>
                    <div className="mdl-dialog__actions">
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionCopyDictionary(history)}>{Lang.get("dic_dialog_copyas_btn_ok")}</button>
                        <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_copyas_btn_cancel")}</button>
                    </div>
                </dialog>
            )} /> 
        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicCopyAs)