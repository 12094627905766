import React, { Component } from 'react';

class Toast extends Component {
    render() {
        return (
            <div id="toast" className="toast mdl-js-snackbar mdl-snackbar">
                <div className="mdl-snackbar__text"></div>
                <button className="mdl-snackbar__action" type="button"></button>
            </div>
        )
    }
}

export default Toast;