import React, { Component } from 'react';
import { connect } from "react-redux"
import Lang from '../Misc/Lang';

class WordsCnt extends Component {

    render() {
        const { appTools } = this.props;
        if(typeof appTools.dicWordsCnt.All !== 'undefined' && appTools.dicWordsCnt.All !== null){
            return (
                <div className="words-count"><label>{Lang.get("dic_words_known_cnt_label")}</label><span>{appTools.dicWordsCnt.Known}/{appTools.dicWordsCnt.All}</span></div>
            )
        }
        else {
            return null;
        }
    }
}

export default connect(function (store) {
    return {
        appTools: store.appTools
    }
})(WordsCnt)
