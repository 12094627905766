import React, { Component } from 'react';
import { connect } from "react-redux";
import Tools from '../Misc/Tools';
import Config from '../Config/Config';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';

class ErrorPage extends Component {

    componentDidUpdate(){
        Tools.loader(this.props, false);
        Tools.mdlUpgrade();
    }

    render() {

        return (

            <Layout
                headerTitle={Config.get("appName")}
                disableMenuItemSelect={true}
            >
                <div className="page-static compact-content">
                    <h1 className="page-title">{Lang.get("errorpage_title")}</h1>
                    <p>{Lang.get("errorpage_content")}</p>
                </div>
            </Layout>

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(ErrorPage)
