import React, { Component } from 'react';
import { connect } from "react-redux";
import { setMultipleData } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogDicRename extends Component {
    
    dialog = null;
    dialogShow = false;

    constructor(props) {
        super(props);
        this.state = {
            inputRenameName: "",
        }
        this.dialogShow = props.appDialogs.dialogDicRename;
    }


    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-rename');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogDicRename && nextProps.appDialogs.dialogDicRename !== this.dialogShow){
            if(nextProps.appState.dictionary){
                this.state.inputRenameName = nextProps.appState.dictionary.Name
            }
            this.dialogShow = nextProps.appDialogs.dialogDicRename;
            Tools.dialogNavBackOpen(this.dialog);
            return true;
        }
        if(this.state.inputRenameName !== nextState.inputRenameName){
            return true;
        }
        return false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    actionRenameToState(e) {
        this.setState({ inputRenameName: e.target.value })
    }

    actionRenameGo() {
        if (this.state.inputRenameName) {
            ModelDictionary.rename(this.props.appState.dictionary.ID, this.state.inputRenameName)
                .then(() => {
                    return Promise.all([
                        ModelDictionary.getUserDictionaries(),
                        ModelDictionary.getCurrentDictionarySorted(),
                    ])
                })
                .then(results => {
                    var userDictionaries = results[0];
                    var dictionary = results[1];
                    this.props.dispatch(setMultipleData({
                        userDictionaries: userDictionaries,
                        dictionary: dictionary,
                    }));
                })
                .then(() => {
                    if(this.props.appDialogs.dialogDicRenamePayload && this.props.appDialogs.dialogDicRenamePayload.renameDoneCallback){
                        this.props.appDialogs.dialogDicRenamePayload.renameDoneCallback();
                    }
                })
                .catch(Tools.log)
            this.closeDialog();
            Tools.toast(Lang.get("dic_dialog_rename_done_toast"));
        }
    }



    render() {
        return (

            <dialog className="mdl-dialog dialog dialog-rename" data-dialog-id="rename">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_rename_title")}</h4>
                <div className="mdl-dialog__content">
                    <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                        <input className="mdl-textfield__input" type="text" id="DChN" required="required" value={this.state.inputRenameName} onChange={e => this.actionRenameToState(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionRenameGo() : null)} />
                        <label className="mdl-textfield__label" htmlFor="DChN">{Lang.get("dic_dialog_rename_name_label")}</label>
                    </div>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionRenameGo()}>{Lang.get("dic_dialog_rename_btn_ok")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_rename_btn_cancel")}</button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogDicRename)