import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, withRouter  } from 'react-router-dom';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser';
import LocalStorage from './../Models/LocalStorage';
import APISync from './../Models/APISync';
import WelcomeContent from '../Components/WelcomeContent';

import { 
    setUserSharedDictionaries
} from '../ReduxActions/AppActions';

class ShareLinkProcessPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OID: parseInt(props.match.params.OID, 10),
            token: props.match.params.token,
            status: 0,
            sharedDictionaryID: 0
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }

    componentDidMount() {
        this.actionAccept();
    }
    
    actionAccept(){
        if(Tools.onLine()){
            Tools.loader(this.props, true);

            ModelUser.getUserOID()
                .then(userOID => {
                    if(userOID){
                        return ModelDictionary.shareDicLink(this.state.OID, this.state.token)
                            .then(response => {
                                this.setState({status: response.status});
                                Tools.loader(this.props, false);

                                if(response.status === 1){
                                    Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                                    return APISync.syncDictionariesFromServer()
                                    .then(() => {
                                        return APISync.syncQueue();
                                    })
                                    .then(() => {
                                        return ModelDictionary.getUserSharedDictionaries()
                                    })
                                    .then((dictionaries) => {
                                        this.props.dispatch(setUserSharedDictionaries(dictionaries));
                                        Tools.loader(this.props, false);
                                        this.setState({status: response.status});
                                    })
                                    .then(() => {
                                        return ModelDictionary.getDictionaryByOID(this.state.OID)
                                            .then(dictionary => {
                                                this.setState({sharedDictionaryID: dictionary.ID})
                                            })
                                    })
                                }
                                if(response.status === 2){
                                    return ModelDictionary.getDictionaryByOID(this.state.OID)
                                        .then(dictionary => {
                                            this.setState({sharedDictionaryID: dictionary.ID})
                                        })
                                }

                            })
                    }   
                    else {
                        Tools.loader(this.props, false);
                        this.setState({status: 10}); 
                        LocalStorage.addShareDicLinkToken(this.state.OID, this.state.token);
                    }
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                    this.props.history.push('/error');
                });

        }
        else {
            Tools.confirm(
                this.props, 
                () => {
                    this.actionAccept();
                }, 
                Lang.get("dialog_alert_msg_offline"),
                "",
                Lang.get("dialog_alert_btn_retry"),
                true
            )
            
        }
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
    }


    actionLogin(history){
        history.push('/login');
    }

    actionRegister(history){
        history.push('/register');
    }



    render() {

        return (
            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("sharelink_title")}
                    disableMenuItemSelect={true}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >
                    <div className="page-share-link compact-content">
                        <div className="info-box">
                            {this.state.status === 1 && <div>{Lang.get("sharelink_status_1")}</div>}
                            {this.state.status === 2 && <div>{Lang.get("sharelink_status_2")}</div>}
                            {this.state.status === 3 && <div>{Lang.get("sharelink_status_3")}</div>}
                            {this.state.status === 4 && <div>{Lang.get("sharelink_status_4")}</div>}
                            {this.state.status === 5 && <div>{Lang.get("sharelink_status_5")}</div>}
                            {this.state.status === 10 && <div>{Lang.get("sharelink_status_10")}</div>}
                            {this.state.sharedDictionaryID > 0 &&
                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => {history.push('/dic/'+this.state.sharedDictionaryID)}}>
                                    {Lang.get("sharelink_dic_btn")}
                                </button>
                            }
                            {this.state.status === 10 && 
                                <button className="button-login mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionLogin(history)}>{Lang.get("sharelink_login_btn")}</button>
                            }
                        </div>
                        {this.state.status === 10 && 
                            <div className="welcome-content">
                                <WelcomeContent />
                            </div>
                        }
                        
                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(withRouter(ShareLinkProcessPage))
