import { applyMiddleware, createStore } from "redux"

//import logger from "redux-logger"
import thunk from "redux-thunk"
import { createPromise } from "redux-promise-middleware"
import reducer from "./../ReduxReducers"

const promise = createPromise({ types: { fulfilled: 'success' } });

//const middleware = applyMiddleware(promise, thunk, logger)
const middleware = applyMiddleware(promise, thunk)
export default createStore(reducer, middleware)