import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './../Models/LocalStorage';
import { Speech } from '../Misc/Speech';
import Tools from '../Misc/Tools';
import Lang from '../Misc/Lang';
import WordMenu from '../Components/WordMenu';

import { 
    setDicWordsCntChangeKnown,
    setMultipleData
} from '../ReduxActions/AppActions';


class WordListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            word: this.props.word
        }

    }



    shouldComponentUpdate(nextProps, nextState){
        if (JSON.stringify(nextProps.word) !== JSON.stringify(nextState.word)) {
            this.state.word = nextProps.word;
        }
        
        return true;
    }


    /*** EVENTS ***/
    wordTouchEvents = new Tools.elementTouchEvents(
        (e) => {
            // brzydkie ale wynika ze sposobu realizacji trybu "lewy/prawy"
            if(e.currentTarget.classList.contains('show')){
                this.setState({ show: false })
                e.currentTarget.classList.remove('show');
            }
            else {
                this.setState({ show: true })
                e.currentTarget.classList.add('show');
            }
            LocalStorage.setTipDoneHiddenWords();
        },
        (e) => {
            if(LocalStorage.getSettingSpeechOnTouch()){
                this.actionWordSpeech(this.state.word.Word);
            }
        },
        300
    );

    


    canSpeech() {
        return Speech.canSpeech();
    }


    /* ----- */
    actionWordSpeech(word) {
        Speech.speech(word)
        LocalStorage.setTipDoneHoldSpeech();
    }


    /* ----- */
    actionWordKnown(e, word) {
        ModelDictionary.wordKnown(word)
            .then((isKnown) => {
                if (this.props.dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_UNKNOWN && this.state.word.Known > 0) {
                    Tools.toastKnown(
                        Lang.get("dic_item_toast_undo_known_msg") + ": <strong>" + word.Word + "</strong>", 
                        Lang.get("dic_item_toast_undo_btn"), 
                        () => {
                            this.actionWordKnown(e, word);
                            Tools.toastClear();
                        },
                        3000
                    );
                }
                else if (this.props.dictionary.ShowWords === ModelDictionary.DIC_SHOWWORDS_KNOWN && this.state.word.Known === 0) {
                    Tools.toastKnown(
                        Lang.get("dic_item_toast_undo_unknown_msg") + ": <strong>" + word.Word + "</strong>", 
                        Lang.get("dic_item_toast_undo_btn"), 
                        () => {
                            this.actionWordKnown(e, word);
                            Tools.toastClear();
                        },
                        3000
                    );
                }
                return this.changeDrawerWordsCnt();
            })
            .catch(e => {
                this.setState({
                        word: {...this.state.word, Known: this.state.word.Known === 0 ? 1 : 0}
                    }, this.changeWordsCnt);
                Tools.log(e)
            })
        this.setState({
                word: {...this.state.word, Known: this.state.word.Known === 0 ? 1 : 0}
            }, this.changeWordsCnt);
        
    }

    changeWordsCnt(){
        if(this.state.word.Known === 0){
            this.props.dispatch(setDicWordsCntChangeKnown(-1));
        }
        else {
            this.props.dispatch(setDicWordsCntChangeKnown(1));
        }
    }

    changeDrawerWordsCnt(){

        Promise.all([
            ModelDictionary.getBookmarksDictionary(true),
            ModelDictionary.getAllUserDictionaries()
        ])
            .then(results => {
                var bookmarksDictionary = results[0];
                var dictionaries = results[1];

                var userDictionaries = dictionaries.user;
                var userCatalogDictionaries = dictionaries.catalog;
                var userSharedDictionaries = dictionaries.shared;
                this.props.dispatch(setMultipleData({
                    bookmarksDictionary: bookmarksDictionary,
                    userDictionaries: userDictionaries,
                    userCatalogDictionaries: userCatalogDictionaries,
                    userSharedDictionaries: userSharedDictionaries,
                }));
            })
            .catch(Tools.log)

    }

    

    /* ----- */


    render() {
        const { word, dictionary, isInBookmarks, userDictionariesLength} = this.props;
        return (

                <tr className={(this.state.word.Known > 0 ? "known" : "") + (this.state.show ? " show" : "")} {...this.wordTouchEvents}>
                    <td className="mdl-data-table__cell--non-numeric"><div>{word.Word}</div></td>
                    <td className="mdl-data-table__cell--non-numeric">
                        {word.Trans.map((trans, indexWordTrans) =>
                            <div key={"word-" + indexWordTrans}>{trans.Word}<span>{trans.Type}</span></div>
                        )}
                    </td>
                    <td>
                        <button className="mdl-ignore-js mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon" id={"worditemknow-" + word.ID} onClick={(e) => { e.stopPropagation(); Tools.mdlElementAttach(["worditemknow-" + word.ID]);this.actionWordKnown(e, word) }}>
                            {(() => this.state.word.Known > 0 ? <i className="material-icons known-icon">done</i> : <i className="material-icons">done</i>)()}
                        </button>
                        <WordMenu 
                            word={word} 
                            dictionary={dictionary}
                            isInBookmarks={isInBookmarks}
                            userDictionariesLength={userDictionariesLength}
                            mini={true}
                        />
                    </td>
                </tr>
                
        )
    }

}

export default connect(function (store) {
    return {}
})(WordListItem)