import React, { Component } from 'react';
import { connect } from "react-redux";
import ModelDictionary from './../Models/ModelDictionary';
import { setMultipleData } from '../ReduxActions/AppActions';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class DialogWordCopy extends Component {
    
    dialog = null;
    dialogShow = false;

    _upgradeMdlSelect = false;

    constructor(props) {
        super(props);
        this.state = {
            copyWord: {},
            copyWordDictionaryID: 0,
            copyWordDictionaryName: "",
        }
        this.dialogShow = props.appDialogs.dialogWordCopy;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-copy-word');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordCopy && nextProps.appDialogs.dialogWordCopy !== this.dialogShow){
            this.state.copyWord = nextProps.appDialogs.dialogWordCopyPayload.word;
            if(!this.state.copyWordDictionaryID){
                this.state.copyWordDictionaryID = nextProps.appState.dictionary.ID;
                this.state.copyWordDictionaryName = nextProps.appState.dictionary.Name;
            }
            
            this.dialogShow = nextProps.appDialogs.dialogWordCopy;
            Tools.dialogNavBackOpen(this.dialog);
            this._upgradeMdlSelect = true;
        }
        return true;
    }

    componentDidUpdate() {
        if(this._upgradeMdlSelect){
            Tools.mdlSelectRefresh();
        }
        this._upgradeMdlSelect = false;
    }

    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }
    

    actionCopyWordToDictionaryOnChangeDictionary(e, dicID, dicName) {
        this.setState({ copyWordDictionaryID: parseInt(dicID, 10), copyWordDictionaryName: dicName });
    }

    actionCopyWordToDictionaryGo() {
        if(this.state.copyWordDictionaryID){
            ModelDictionary.copyWordToDictionary(this.state.copyWord.ID, this.state.copyWordDictionaryID)
                .then(() => {
                    return Promise.all([
                        ModelDictionary.getBookmarksDictionary(true),
                        ModelDictionary.getUserDictionaries()
                    ])
                        .then(results => {
                            var bookmarksDictionary = results[0];
                            var userDictionaries = results[1];
                            this.props.dispatch(setMultipleData({
                                bookmarksDictionary: bookmarksDictionary,
                                userDictionaries: userDictionaries,
                            }));
                        })
                })
                .then(() => {
                    Tools.toast(Lang.get("dic_dialog_copyword_done_toast"));
                })
                .catch(Tools.log)
        }
        this.closeDialog();
    }



    render() {
        const { appState } = this.props;

        return (

            <dialog className="mdl-dialog dialog dialog-copy-word" data-dialog-id="copyword">
                <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_copyword_title")}</h4>
                <div className="mdl-dialog__content">
                    <h5>{Lang.get("dic_dialog_copyword_word_label")}: "{this.state.copyWord.Word}"</h5>
                    <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                        <input tabIndex='-1' type="text" defaultValue={this.state.copyWordDictionaryName} className="mdl-textfield__input" id="DIcdw" readOnly />
                        <input type="hidden" />
                        <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                        <label htmlFor="DIcdw" className="mdl-textfield__label">{Lang.get("dic_dialog_copyword_dictionary_label")}</label>
                        <ul htmlFor="DIcdw" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                            {appState.bookmarksDictionary &&
                                ((this.state.copyWordDictionaryID === appState.bookmarksDictionary.ID) ? (
                                    <li onClick={e => this.actionCopyWordToDictionaryOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID} data-selected>{appState.bookmarksDictionary.Name}</li>
                                ) : (
                                        <li onClick={e => this.actionCopyWordToDictionaryOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID}>{appState.bookmarksDictionary.Name}</li>
                                    ))
                            }
                            {appState.userDictionaries && appState.userDictionaries.map((dic, index) =>
                                ((this.state.copyWordDictionaryID === dic.ID) ? (
                                    <li key={"aw-key-" + index} onClick={e => this.actionCopyWordToDictionaryOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID} data-selected>{dic.Name}</li>
                                ) : (
                                        <li key={"aw-key-" + index} onClick={e => this.actionCopyWordToDictionaryOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID}>{dic.Name}</li>
                                    ))
                            )}
                        </ul>
                    </div>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionCopyWordToDictionaryGo()}>{Lang.get("dic_dialog_copyword_btn_ok")}</button>
                    <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_copyword_btn_cancel")}</button>
                </div>
            </dialog>

        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordCopy)