
import Config from '../Config/Config';
import Dexie from 'dexie';

const db = new Dexie(Config.get("dbName"));
db.version(Config.get("dbVersion")).stores({
    dictionary: "++ID, OID, UserOID, Source, Type, [ID+Source], [ID+UserOID], [OID+UserOID]",
    word: "++ID, OID, Dictionary_ID, Word",
    syncqueue: "++ID, Method, Item_ID, Parent_ID, User_OID, Timestamp, [Method+Item_ID], [Method+User_OID], [User_OID+Parent_ID], [Method+Parent_ID]",
    userdata: "++ID"
})

export default db;