export default function reducer(state={
    dialogWordsSort: false,
    dialogWordsShow: false,
    dialogDicRename: false,
    dialogDicRenamePayload: {},
    dialogDicCopyAs: false,
    dialogDicAdd: false,
    dialogDicShare: false,
    dialogDicSharePayload: {},
    dialogDicShareToUser: false,
    dialogDicShareToUserPayload: {},
    dialogDicShareCatalog: false,
    dialogDicPublish: false,
    dialogWordAdd: false,
    dialogWordAddPayload: {},
    dialogWordAddCSV: false,
    dialogWordEdit: false,
    dialogWordEditPayload: {},
    dialogWordCopy: false,
    dialogWordCopyPayload: {},
    dialogWordsLargeList: false,
  }, action) {

    switch (action.type) {

      case "SET_DIALOG_DIC_ADD": {
        return {...state, dialogDicAdd: Math.random()}
      }
      case "SET_DIALOG_DIC_RENAME": {
        return {...state, dialogDicRename: Math.random(), dialogDicRenamePayload: action.payload}
      }
      case "SET_DIALOG_DIC_COPYAS": {
        return {...state, dialogDicCopyAs: Math.random()}
      }
      case "SET_DIALOG_DIC_SHARE": {
        return {...state, dialogDicShare: Math.random(), dialogDicSharePayload: action.payload}
      }
      case "SET_DIALOG_DIC_SHARE_CATALOG": {
        return {...state, dialogDicShareCatalog: Math.random()}
      }
      case "SET_DIALOG_DIC_SHARE_TO_USER": {
        return {...state, dialogDicShareToUser: Math.random(), dialogDicShareToUserPayload: action.payload}
      }
      case "SET_DIALOG_DIC_PUBLISH": {
        return {...state, dialogDicPublish: Math.random()}
      }
      case "SET_DIALOG_WORD_ADD": {
        return {...state, dialogWordAdd: Math.random(), dialogWordAddPayload: action.payload}
      }
      case "SET_DIALOG_WORD_ADDCSV": {
        return {...state, dialogWordAddCSV: Math.random()}
      }
      case "SET_DIALOG_WORD_COPY": {
        return {...state, dialogWordCopy: Math.random(), dialogWordCopyPayload: action.payload}
      }
      case "SET_DIALOG_WORD_EDIT": {
        return {...state, dialogWordEdit: Math.random(), dialogWordEditPayload: action.payload}
      }
      case "SET_DIALOG_WORDS_SORT": {
        return {...state, dialogWordsSort: Math.random()}
      }
      case "SET_DIALOG_WORDS_SHOW": {
        return {...state, dialogWordsShow: Math.random()}
      }
      case "SET_DIALOG_LARGE_LIST": {
        return {...state, dialogWordsLargeList: Math.random()}
      }
      default: {
        return state;
      }
    }
}
