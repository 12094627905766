import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import { setMultipleData } from '../ReduxActions/AppActions';
import ModelDictionary from './../Models/ModelDictionary';
import ModelUser from './../Models/ModelUser';
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';
import { Profiler } from '../Misc/Profiler';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import { setDialogWordAdd } from '../ReduxActions/AppActions';
import LocalStorage from '../Models/LocalStorage';

class DialogWordAdd extends Component {
    
    dialog = null;
    dialogShow = false;

    _upgradeMdlSelect = false;

    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            addWord: this.getDefaultAddWord(),
            addWordDictionaryID: 0,
            addWordDictionaryName: "",
        }
        this.dialogShow = props.appDialogs.dialogWordAdd;
    }

    componentDidMount() { 
        this.dialog = document.querySelector('.dialog-add-word');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.appDialogs.dialogWordAdd && nextProps.appDialogs.dialogWordAdd !== this.dialogShow){
            this.state.addWord = this.getDefaultAddWord();
            this.state.addWordDictionaryName = nextProps.appState.dictionary.Name;
            this.state.addWordDictionaryID = nextProps.appState.dictionary.ID;
            
            this.dialogShow = nextProps.appDialogs.dialogWordAdd;
            Tools.dialogNavBackOpen(this.dialog);
            this._upgradeMdlSelect = true;
        }
        if(this.state.addWord.Trans.length !== nextState.addWord.Trans.length){
            this._upgradeMdlSelect = true;
        }
        return true;
    }

    componentDidUpdate() {
        if(this._upgradeMdlSelect){
            document.querySelector(".dialog-add-word").querySelectorAll("*")
                .forEach(function(item) {
                    if(item.className.match(/(mdl-js-textfield|mdl-js-menu)/) && !item.hasAttribute('data-upgraded')){
                        Tools.mdlUpgradeElement(item);
                    }
                });
            Tools.mdlInputRefresh();
            Tools.mdlSelectRefresh();
            Profiler.show();
        }
        this._upgradeMdlSelect = false;
    }

    /* SUGGEST WORDS - BEGIN */
    onSuggestionsFetchRequested({ value }) {
        value = value.trim();
        if(value.length > 1 && Tools.onLine()){
            ModelDictionary.getSuggestedWord(value)
                .then((words) => {
                    if(JSON.stringify(this.state.suggestions) !== JSON.stringify(words)){
                        this.setState({
                            suggestions: words
                        });      
                    }
                })
                .catch(Tools.log)
        }

    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    };
    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        event.preventDefault();

        setTimeout(() => {
            document.getElementById('DIaww').blur();
        },100)

        this.setState({ addWord: suggestion }, () => { Tools.mdlInputRefresh(); Tools.mdlSelectRefresh(); });
    }
    getSuggestionValue(suggestion) {
        return suggestion.Word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    renderSuggestion(suggestion, { query }) {
        const matches = AutosuggestHighlightMatch(suggestion.Word, query);
        const parts = AutosuggestHighlightParse(suggestion.Word, matches);
        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

                    return (
                        <span className={className} key={index}>
                            {part.text}
                        </span>
                    );
                })}
            </span>
        );
    }
    /* SUGGEST WORDS - END */


    closeDialog(){
        Tools.dialogNavBackClose(this.dialog);
    }

    actionAddWord() {
        this.closeDialog();
        this.props.dispatch(setDialogWordAdd(
            {
                uploadCsvCallback: this.props.appDialogs.dialogWordAddPayload.uploadCsvCallback ? this.props.appDialogs.dialogWordAddPayload.uploadCsvCallback : null,
                addDoneCallback: this.props.appDialogs.dialogWordAddPayload.addDoneCallback ? this.props.appDialogs.dialogWordAddPayload.addDoneCallback : null
            }
        ));
    }

    onFocusBlurFocus(e) {
        // łatka na tymczasowy(?) bug w Chrome dla Androida, który w przypadku gdy są co najmnie dwa inputy po focusie na kolejnym, wpisaniu czegokolwiek i naciśnięciu spacji powodował wklejanie treści z poprzedniego inputa
        if(Tools.isChrome() && Tools.isAndroid()){
            let element = e.target;
            if(this._lastFocusElement !== element){
                this._lastFocusElement = element;
                element.blur();
                setTimeout(() => {
                    element.focus();
                }, 10)
            }
        }
    }

    actionAddWordOnChangeWord(e) {
        //Tools.log('#'+e.target.value+'#'+this.state.addWord.Word+'#');
        var _addWord = this.state.addWord;
        _addWord.Word = e.target.value;
        this.setState({ addWord: _addWord });
    }
    actionAddWordOnChangeTransName(e, idx) {
        //Tools.log('#'+e.target.value+'#'+this.state.addWord.Trans[0].Word+'#');
        var _addWord = this.state.addWord;
        if (_addWord.Trans[idx]) {
            _addWord.Trans[idx].Word = e.target.value;
            this.setState({ addWord: _addWord });
        }
    }
    actionAddWordOnChangeTransType(e, idx, typeStr) {
        //e.stopPropagation();
        var _addWord = this.state.addWord;
        if (_addWord.Trans[idx]) {
            _addWord.Trans[idx].Type = typeStr;
            this.setState({ addWord: _addWord });
        }
    }
    actionAddWordOnChangeDictionary(e, dicID, dicName) {
        this.setState({ addWordDictionaryID: parseInt(dicID, 10), addWordDictionaryName: dicName });
    }

    actionAddWordGo(history, nextWord) {
        if (this.state.addWord.Word && this.state.addWord.Trans[0].Word) {
            ModelDictionary.addWordToDictionary(this.state.addWordDictionaryID, this.state.addWord)
                .then(() => {
                    Tools.toast(Lang.get("dic_dialog_addword_done_toast"));
                    return ModelDictionary.getCurrentDictionarySorted()
                })
                .then((dictionary) => {

                    if (nextWord) {
                        this.actionAddWord();
                    }
                    

                    return Promise.all([
                        ModelDictionary.getBookmarksDictionary(true),
                        ModelDictionary.getUserDictionaries()
                    ])
                        .then(results => {
                            var bookmarksDictionary = results[0];
                            var userDictionaries = results[1];
                            this.props.dispatch(setMultipleData({
                                bookmarksDictionary: bookmarksDictionary,
                                userDictionaries: userDictionaries,
                                dictionary: dictionary
                            }));
                        })
                        .then(() => {
                            if(this.props.appDialogs.dialogWordAddPayload && this.props.appDialogs.dialogWordAddPayload.addDoneCallback){
                                this.props.appDialogs.dialogWordAddPayload.addDoneCallback();
                            }
                        })
                        .then(() => {
                            if(!nextWord && !LocalStorage.getTipRegister()){
                               return ModelUser.getUserOID()
                                    .then(userOID => {
                                        if(!userOID){
                                            if(this.props.appState.dictionary.Words.length > 2){
                                                Tools.confirm(
                                                    this.props, 
                                                    () => {
                                                        history.push('/register');
                                                    }, 
                                                    Lang.get("tip_register_header"),
                                                    Lang.get("tip_register_content"),
                                                    Lang.get("tip_register_btn"),
                                                    false,
                                                    Lang.get("tip_register_btn_cancel")
                                                )
                                                LocalStorage.setTipRegister();
                                            }
                                        }
                                    })
                            }
                        })

                })
                .catch(Tools.log)

            this.closeDialog();
        }
    }

    actionAddWordAddTrans() {
        var _obj = Tools.cloneObject(this.state.addWord);
        _obj.Trans.push({
            Word: '',
            Type: '',
        });
        this.setState({ addWord: _obj });
        setTimeout(function () {
            Tools.mdlSelectRefresh();
        }, 500)

    }

    actionAddWordDelTrans(index) {
        var _addWord = Tools.cloneObject(this.state.addWord);
        var _newTrans = [];
        for (var _idx in this.state.addWord.Trans) {
            if (index !== parseInt(_idx, 10)) {
                _newTrans.push(this.state.addWord.Trans[_idx]);
            }
        }
        if(!_newTrans.length){
            _newTrans = this.getDefaultAddWord().Trans;
        }
        _addWord.Trans = _newTrans;
        this.setState({ addWord: _addWord }, function () {
            Tools.mdlInputRefresh();
            Tools.mdlSelectRefresh();
        });
    }

    actionAddWordCSV() {
        this.closeDialog();
        if(this.props.appDialogs.dialogWordAddPayload.uploadCsvCallback){
            this.props.appDialogs.dialogWordAddPayload.uploadCsvCallback();
        }
    }

    getDefaultAddWord() {
        return {
            Word: '',
            Trans: [
                {
                    Word: '',
                    Type: '',
                }
            ]
        };
    }



    render() {
        const { appState } = this.props;
        return (
            <Route render={({ history }) => (
                <dialog className="mdl-dialog mdl-dialog--wide dialog dialog-add-word" data-dialog-id="addword">
                    <h4 className="mdl-dialog__title">{Lang.get("dic_dialog_addword_title")}</h4>
                    <div className="mdl-dialog__content">
                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                            
                            <Autosuggest 
                                suggestions={this.state.suggestions}
                                inputProps={{
                                    name: "word",
                                    value: this.state.addWord.Word,
                                    className: "mdl-textfield__input",
                                    type:"text",
                                    id:"DIaww",
                                    required : true,
                                    autoComplete: "off",
                                    autoCorrect: "off",
                                    autoCapitalize: "none",
                                    spellCheck: "false",
                                    onChange: (e) => this.actionAddWordOnChangeWord(e),
                                    onKeyPress: (e) => (e.key === 'Enter' ? this.actionAddWordGo(history) : null),
                                    onFocus: (e) => this.onFocusBlurFocus(e)
                                }}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                                onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                            />
                            <label className="mdl-textfield__label" htmlFor="DIaww">{Lang.get("dic_dialog_addword_word_label")}</label>

                        </div>
                        {this.state.addWord.Trans.map((trans, index) =>
                            <div key={"awt-" + index} className="dialog-add-word__trans">
                                <i className="delete material-icons" onClick={e => this.actionAddWordDelTrans(index)}>close</i>
                                <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                                    {(() => index > 0
                                        ?
                                        <input name="trans" className="mdl-textfield__input" type="text" id={"trwidx-" + index} value={trans.Word} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" onFocus={this.onFocusBlurFocus.bind(this)} onChange={e => this.actionAddWordOnChangeTransName(e, index)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionAddWordGo(history) : null)} />
                                        :
                                        <input name="trans" className="mdl-textfield__input" type="text" id={"trwidx-" + index} value={trans.Word} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" onFocus={this.onFocusBlurFocus.bind(this)} onChange={e => this.actionAddWordOnChangeTransName(e, index)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionAddWordGo(history) : null)} required />
                                    )()}
                                    <label className="mdl-textfield__label" htmlFor={"trwidx-" + index}>{Lang.get("dic_dialog_addword_trans_label")}</label>
                                </div>
                                <div className="dialog-add-word__trans-type mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                                    <input tabIndex='-1' type="text" defaultValue={trans.Type} className="mdl-textfield__input" id={"trtidx-" + index} readOnly />
                                    <input type="hidden" value="" />
                                    <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                    <label htmlFor={"trtidx-" + index} className="mdl-textfield__label">{Lang.get("dic_dialog_addword_transtype_label")}</label>
                                    <ul htmlFor={"trtidx-" + index} className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                        {trans.Type ? 
                                            (<li className="mdl-menu__item" onClick={e => this.actionAddWordOnChangeTransType(e, index, "")}></li>)
                                            :
                                            (<li className="mdl-menu__item" data-selected onClick={e => this.actionAddWordOnChangeTransType(e, index, "")}></li>)
                                        }

                                        {Lang.get("dic_dialog_addword_types").map((type, indexType) =>
                                            (type === trans.Type ? 
                                                (
                                                    <li key={"awtt-" + indexType} className="mdl-menu__item" onClick={e => this.actionAddWordOnChangeTransType(e, index, type)} data-selected="true">{type}</li>
                                                )
                                                :
                                                (
                                                    <li key={"awtt-" + indexType} className="mdl-menu__item" onClick={e => this.actionAddWordOnChangeTransType(e, index, type)}>{type}</li>
                                                )
                                            )
                                        )}

                                        {trans.Type && Lang.get("dic_dialog_addword_types").indexOf(trans.Type) < 0 &&
                                            <li key={"awtt-" + index + '-add'} data-selected className="mdl-menu__item" onClick={e => this.actionAddWordOnChangeTransType(e, index, trans.Type)}>{trans.Type}</li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className="add-trans">
                            <button className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab" onClick={() => this.actionAddWordAddTrans()}>
                                <i className="material-icons">add</i>
                            </button>
                        </div>
                        <div className="hide mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                            <input tabIndex='-1' type="text" defaultValue={this.state.addWordDictionaryName} className="mdl-textfield__input" id="DIawd" readOnly />
                            <input type="hidden" name="DIawd" />
                            <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                            <label htmlFor="DIawd" className="mdl-textfield__label">{Lang.get("dic_dialog_addword_dictionary_label")}</label>
                            <ul htmlFor="DIawd" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                {appState.bookmarksDictionary &&
                                    ((this.state.addWordDictionaryID === appState.bookmarksDictionary.ID) ? (
                                        <li onClick={e => this.actionAddWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID} data-selected>{appState.bookmarksDictionary.Name}</li>
                                    ) : (
                                            <li onClick={e => this.actionAddWordOnChangeDictionary(e, appState.bookmarksDictionary.ID, appState.bookmarksDictionary.Name)} className="mdl-menu__item" data-val={appState.bookmarksDictionary.ID}>{appState.bookmarksDictionary.Name}</li>
                                        ))
                                }
                                {appState.userDictionaries && appState.userDictionaries.map((dic, index) =>
                                    ((this.state.addWordDictionaryID === dic.ID) ? (
                                        <li key={"aw-key-" + index} onClick={e => this.actionAddWordOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID} data-selected>{dic.Name}</li>
                                    ) : (
                                            <li key={"aw-key-" + index} onClick={e => this.actionAddWordOnChangeDictionary(e, dic.ID, dic.Name)} className="mdl-menu__item" data-val={dic.ID}>{dic.Name}</li>
                                        ))
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="mdl-dialog__actions">
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionAddWordGo(history, true)}>{Lang.get("dic_dialog_addword_btn_ok_next")}</button>
                        <button type="button" className="mdl-button mdl-button--raised" onClick={() => this.actionAddWordGo(history)}>{Lang.get("dic_dialog_addword_btn_ok")}</button>
                        <button type="button" className="mdl-button close" onClick={() => this.closeDialog()}>{Lang.get("dic_dialog_addword_btn_cancel")}</button>
                    </div>
                    <div className="mdl-dialog__content">
                        <p className="disclaimer">{Lang.get("dic_dialog_addword_disclaimer_csv")}</p>
                        <button onClick={() => this.actionAddWordCSV()} className="mdl-button mdl-button--raised">{Lang.get("dic_dialog_addword_btn_csv")}</button>
                    </div>
                </dialog>
            )} />
        )
    }
}

export default connect(function (store) {
    return {
        appState: store.appState,
        appDialogs: store.appDialogs
    }
})(DialogWordAdd)