import LangConfig from '../Config/Lang';


var Lang = {
    
    get: function(key){
        if(LangConfig['default'] && typeof LangConfig['default'][key] !== 'undefined'){
            return LangConfig['default'][key];
        }
        return "___UNDEFINED___";
    }

}
export default Lang;