

class LocalStorage {

    
    get(key, defaultValue){
        return localStorage.getItem(key) || defaultValue;
    }

    set(key, value){
        localStorage.setItem(key, value);
    }

    delete(key){
        localStorage.removeItem(key);
    }

    clear(){
        localStorage.clear();
    }

    setDataInitialized(bool){
        this.set("DI", bool ? 1 : 0)
    }

    getDataInitialized(){
        var _init =  parseInt(this.get("DI", 0));
        return _init ? true : false;
    }

    setCurrentDictionaryID(dictionaryID){
        this.set("dictionaryID", parseInt(dictionaryID, 10) || 0)
    }

    getCurrentDictionaryID(){
        return parseInt(this.get("dictionaryID", 0), 10);
    }

    setLastSyncFromServerTime(){
        this.set("syncLastFSTS", Math.round(new Date().getTime()/1000));
    }

    getLastSyncFromServerTime(){
        return parseInt(this.get("syncLastFSTS", 0), 10);
    }


    setSpeechVoiceURI(voiceUri){
        this.set("voiceURI", voiceUri);
    }

    getSpeechVoiceURI(){
        return this.get('voiceURI', '');
    }

    setSettingSpeechOnTouch(bool){
        this.set('speechOnTouch', bool ? 1 : 0);
    }

    getSettingSpeechOnTouch(){
        var val = this.get('speechOnTouch', null);
        if(val === null){
            return true;
        }
        return parseInt(val) ? true : false;
    }

    setModeSlideshow(modeList){
        this.set("modeList", modeList ? 0 : 1);
    }

    getModeSlideshow(){
        var val = this.get('modeList', false);
        return parseInt(val) ? false : true;
    }

    setTipDoneHiddenWords(){
        this.set("tipHiddenWords", 1);
    }

    getTipDoneHiddenWords(){
        var val = this.get('tipHiddenWords', false);
        return parseInt(val) ? true : false;
    }

    setTipDoneLargeList(){
        this.set("tipLargeList", 1);
    }

    getTipDoneLargeList(){
        var val = this.get('tipLargeList', false);
        return parseInt(val) ? true : false;
    }

    setTipDoneHoldSpeech(){
        this.set("tipHoldSpeech", 1);
    }

    getTipDoneHoldSpeech(){
        var val = this.get('tipHoldSpeech', false);
        return parseInt(val) ? true : false;
    }

    setTipSlidesCarousel(){
        this.set("tipSlidesCarousel", 1);
    }

    getTipSlidesCarousel(){
        var val = this.get('tipSlidesCarousel', false);
        return parseInt(val) ? true : false;
    }

    setSWV(swv){
        this.set("SWV", swv);
    }

    getSWV(){
        return this.get('SWV', false);
    }

    setTipRegister(){
        this.set("tipRegister", 1);
    }

    getTipRegister(){
        var val = this.get('tipRegister', false);
        return parseInt(val) ? true : false;
    }

    setTipStorageInfo(){
        this.set("tipStorageInfo", 1);
    }

    getTipStorageInfo(){
        var val = this.get('tipStorageInfo', false);
        return parseInt(val) ? true : false;
    }

    addShareDicLinkToken(oid, token){
        var obj = this.getShareDicLinkTokens();
        obj.push({oid: oid, token: token});
        this.set('shareDicLinkTokens', JSON.stringify(obj));
    }

    getShareDicLinkTokens(){
        var val = this.get('shareDicLinkTokens', null);
        if(val){
            var obj = JSON.parse(val);
            return obj ? obj : [];
        }
        else {
            return [];
        }
    }

    clearShareDicLinkTokens(){
        this.set('shareDicLinkTokens', JSON.stringify([]));
    }

    setSettingDarkTheme(bool){
        this.set('darkTheme', bool ? 1 : 0);
    }

    getSettingDarkTheme(){
        var val = this.get('darkTheme', null);
        if(val === null){
            return false;
        }
        return parseInt(val) ? true : false;
    }

}

export default new LocalStorage(); 