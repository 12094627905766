import Config from '../Config/Config';
import Tools from '../Misc/Tools';
import db from './DB';
import ModelUser from './ModelUser.js';
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './LocalStorage.js';

class APISync {

    TABLE_SYNCQUEUE = 'syncqueue';
    TABLE_DICTIONARY = 'dictionary';
    TABLE_WORD = 'word';

    /**
     * Request JSON-RPC
     */
    APIRequest(method, data) {
        return this.RPCRequest(method, data);
    }

    RPCRequest(method, data) {

        var _requestID = Math.floor(Math.random() * (10000)) + 1;
        var _promises = [
            ModelUser.getAPIToken(),
            ModelUser.getUserOID(),
            ModelUser.getDeviceID(),
            iClientTimeDiff.getTimeDiff()
        ];

        return Promise.all(_promises)
            .then(results => {
                var metaData = {};
                metaData.DeviceName = ModelUser.getDeviceName();
                metaData.DeviceTimestamp = new Date().getTime();
                metaData.ClientTimeDiff = results[3];
                metaData.APIToken = results[0];
                metaData.UserOID = results[1];
                metaData.DeviceID = results[2];
                metaData.AppVersion = Config.get("appVersion");

                //var params = {...metaData, ...data};
                var params = data;

                var requestDataObject = {
                    jsonrpc: '2.0',
                    method: method,
                    id: _requestID,
                    meta: metaData,
                    params: params,
                }
                Tools.log('RPCRequest', requestDataObject);
    
                const request = new Request(
                    Config.get("APIEndpoint"),
                    {
                        method: "POST",
                        body: JSON.stringify(requestDataObject),
    
                    }
                );
                return fetch(request);
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 403) {
                    // wylogowujemy użytkownika
                    return ModelUser.logout()
                        .then(() => {
                            document.location.href = '/';
                            throw new Error('Unathorized request!');
                        })
                } else {
                    throw new Error('Something went wrong on api server!');
                }
            })
            .then(response => {
                Tools.log('RPCResponse', response);
                if (typeof response.error !== 'undefined' && !response.error) {

                    // wymuszenie odświeżenia service-worker.js
                    if(response.swv){
                        var _SWVFL = LocalStorage.getSWV();
                        if(_SWVFL && _SWVFL !== response.swv){
                            setTimeout(() => {
                                // wymuszamy update service-worker
                                if ('serviceWorker' in navigator) {
                                    navigator.serviceWorker.getRegistrations().then(function (registrations) {
                                        for (let registration of registrations) {
                                            registration.update();
                                        }
                                    })
                                }
                            }, 10000)
                        }
                        LocalStorage.setSWV(response.swv)
                    }


                    if(response.result.APIToken && response.result.User){
                        return ModelUser.setAPIToken(response.result.APIToken)
                            .then(() => {
                                ModelUser.setUser(response.result.User);
                            })
                            .then(() => {
                                return response.result
                            })
                    }
                    else if(response.result.APIToken){
                        return ModelUser.setAPIToken(response.result.APIToken)
                            .then(() => {
                                return response.result
                            })
                    }
                    else if(response.result.User){
                        return ModelUser.setUser(response.result.User)
                            .then(() => {
                                return response.result
                            })
                    }
                    else {
                        return response.result
                    }
                    

                }
                else {
                    throw new Error('');
                }
            })

    }


    /**
     * Dodaje słownika do kolejki synchronizacji 
     * @param dictionary
     */
    syncDictionary(dictionaryID, withWords) {

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }
                
                var _promise;
                if(withWords){
                    _promise = Promise.all([
                        db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionary', Item_ID: dictionaryID}).delete(),
                        db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWord', Parent_ID: dictionaryID}).delete(),
                        db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWordKnown', Parent_ID: dictionaryID}).delete(),
                        db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionaryKnownWordsClear', Item_ID: dictionaryID}).delete(),
                        db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDeleteWord', Parent_ID: dictionaryID}).delete()
                    ])
                }
                else {
                    _promise = new Promise((resolve, reject) => { resolve() } );
                }

                return _promise
                    .then(() => {
                        return ModelDictionary.getDictionaryById(dictionaryID, withWords)
                    })
                    .then(dictionary => {
                        dictionary = iDictionaryModifiedTimes.setLastModifiedUserDictionary(dictionary);
                        if(withWords){ 
                            dictionary = iDictionaryModifiedTimes.setLastModifiedWords(dictionary);
                        }
                        var dictionaryToSave = Tools.cloneObject(dictionary);
                        if(dictionaryToSave.Words){
                            delete dictionaryToSave.Words;
                        }
                        return db.table(this.TABLE_DICTIONARY).update(dictionaryToSave.ID, dictionaryToSave)
                            .then(() => { return dictionary });
                    })
                    .then(dictionary => {
                        var _method = 'syncDictionary';
                        if (withWords) {
                            _method = 'syncDictionaryWords';
                        }
    
                        return db.table(this.TABLE_SYNCQUEUE)
                            .where({ Method: _method, Item_ID: dictionary.ID })
                            .toArray(results => {
                                if (results && results.length > 0) {
                                    return results[0];
                                }
                                else {
                                    return null;
                                }
                            })
                            .then(queueItem => {
                                var _queueObj = {
                                    Method: _method,
                                    User_OID: userOID,
                                    Item_ID: dictionary.ID,
                                    Timestamp: new Date().getTime(),
                                    Process: false,
                                    Data: {
                                        dictionary: dictionary,
                                    }
                                };
                                Tools.log(_method, _queueObj);
                                if (queueItem) {
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .update(queueItem.ID, _queueObj);
                                }
                                else {
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .add(_queueObj)
                                }
                            })
    
                    })
                    .then(() => {
                        this.serviceworkerSync();
                        return true;
                    })
            })
    }

    /**
     * Dodaje słowo do kolejki synchronizacji 
     * @param dictionary
     */
    syncWord(wordID) {

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }
                return Promise.all([
                        ModelDictionary.getWordById(wordID),
                        ModelDictionary.getDictionaryByWordId(wordID),
                    ]) 
                    .then(results => {
                        var word = results[0];
                        var dictionary = results[1];

                        dictionary = iDictionaryModifiedTimes.setLastModifiedWords(dictionary);

                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                if(dictionary.OID){
                                    var _method = 'syncWord';
        
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .where({ Method: _method, Item_ID: word.ID })
                                        .toArray(results => {
                                            if (results && results.length > 0) {
                                                return results[0];
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                        .then(queueItem => {
                                            var _queueObj = {
                                                Method: _method,
                                                User_OID: userOID,
                                                Item_ID: word.ID,
                                                Parent_ID: dictionary.ID,
                                                Timestamp: new Date().getTime(),
                                                Process: false,
                                                Data: {
                                                    dictionary: dictionary,
                                                    word: word,
                                                }
                                            };
                                            Tools.log(_method, _queueObj);
                                            if (queueItem) {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .update(queueItem.ID, _queueObj);
                                            }
                                            else {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .add(_queueObj)
                                            }
                                        })
                                        .then(() => {
                                            this.serviceworkerSync();
                                            return true;
                                        })
        
                                }
                                else {
                                    return this.syncDictionary(dictionary.ID, true);
                                }
                            })

                    })
                
            })

    }

    syncWordKnown(word){

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }
                return ModelDictionary.getDictionaryById(word.Dictionary_ID)
                    .then(dictionary => {

                        dictionary = iDictionaryModifiedTimes.setLastModifiedKnownWords(dictionary);

                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                if(!dictionary.OID){
                                    return this.syncDictionary(dictionary.ID, true);
                                }
                                else if(!word.OID){
                                    return this.syncWord(word.ID);
                                }
                                else {

                                    var _method = 'syncWordKnown';
        
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .where({ Method: _method, Item_ID: word.ID })
                                        .toArray(results => {
                                            if (results && results.length > 0) {
                                                return results[0];
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                        .then(queueItem => {
                                            var _queueObj = {
                                                Method: _method,
                                                User_OID: userOID,
                                                Item_ID: word.ID,
                                                Parent_ID: dictionary.ID,
                                                Timestamp: new Date().getTime(),
                                                Process: false,
                                                Data: {
                                                    dictionary: dictionary,
                                                    word: word
                                                }
                                            };
                                            Tools.log(_method, _queueObj);
                                            if (queueItem) {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .update(queueItem.ID, _queueObj);
                                            }
                                            else {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .add(_queueObj)
                                            }
                                        })
                                        .then(() => {
                                            this.serviceworkerSync();
                                            return true;
                                        })
        
                                }
                            })

                    })
                
            })

    }

    syncDictionaryKnownWordsClear(dictionaryID){
        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }
                
                return ModelDictionary.getDictionaryById(dictionaryID)
                    .then(dictionary => {
                        dictionary = iDictionaryModifiedTimes.setLastModifiedKnownWords(dictionary);
                        if(typeof dictionary.Words !== 'undefined'){
                            delete dictionary.Words;
                        }

                        return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                            .then(() => {
                                if(dictionary.OID){
                                    var _method = 'syncDictionaryKnownWordsClear';

                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .where({ Method: _method, Item_ID: dictionary.ID })
                                        .toArray(results => {
                                            if (results && results.length > 0) {
                                                return results[0];
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                        .then(queueItem => {
                                            var _queueObj = {
                                                Method: _method,
                                                User_OID: userOID,
                                                Item_ID: dictionary.ID,
                                                Timestamp: new Date().getTime(),
                                                Process: false,
                                                Data: {
                                                    dictionary: dictionary
                                                }
                                            };
                                            Tools.log(_method, _queueObj);
                                            if (queueItem) {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .update(queueItem.ID, _queueObj);
                                            }
                                            else {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .add(_queueObj)
                                            }
                                        })
                                        .then(() => {
                                            this.serviceworkerSync();
                                            return true;
                                        })

                                }
                                else {
                                    return this.syncDictionary(dictionary.ID, true);
                                }
                            })
                    })
                
            })
    }

    /**
     * Dodaje do kolejki słowo do usunięcia
     * @param dictionary
     */
    
    syncDeleteWord(word) {

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }

                return Promise.all([
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWord', Item_ID: word.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWordKnown', Item_ID: word.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionaryWords', Item_ID: word.Dictionary_ID}).delete(),
                    ModelDictionary.getDictionaryById(word.Dictionary_ID)
                ])
                .then(results => {
                    var dictionary = results[3];
                    dictionary = iDictionaryModifiedTimes.setLastModifiedWords(dictionary);
                    return db.table(this.TABLE_DICTIONARY).update(dictionary.ID, dictionary)
                        .then(() => {

                            if(dictionary.OID){
                                if (word.OID) {
                                    var _method = 'syncDeleteWord';
                                    var _queueObj = {
                                        Method: _method,
                                        User_OID: userOID,
                                        Item_ID: word.ID,
                                        Parent_ID: word.Dictionary_ID,
                                        Timestamp: new Date().getTime(),
                                        Process: false,
                                        Data: {
                                            word: word,
                                            dictionary: dictionary
                                        }
                                    };
                                    Tools.log(_method, _queueObj);
            
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .where({ Method: _method, Item_ID: word.ID })
                                        .toArray(results => {
                                            if (results && results.length > 0) {
                                                return results[0];
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                        .then((queueItem) => {
                                            if (queueItem) {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .update(queueItem.ID, _queueObj);
                                            }
                                            else {
                                                return db.table(this.TABLE_SYNCQUEUE)
                                                    .add(_queueObj)
                                            }
                                        })
                                        .then(() => {
                                            
                                        })
                                        .then(() => {
                                            this.serviceworkerSync();
                                            return true;
                                        })
                                }
                                else {
                                    return true;
                                }
        
                            }
                            else {
                                return this.syncDictionary(dictionary.ID, true);
                            }


                        })
                })

            })

    }

    /**
     * Dodaje do kolejki słownik do usunięcia
     * @param dictionary
     */
    syncDeleteDictionary(dictionary) {

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }


                return Promise.all([
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionary', Item_ID: dictionary.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionaryWords', Item_ID: dictionary.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDictionaryKnownWordsClear', Item_ID: dictionary.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWord', Parent_ID: dictionary.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncWordKnown', Parent_ID: dictionary.ID}).delete(),
                    db.table(this.TABLE_SYNCQUEUE).where({Method: 'syncDeleteWord', Parent_ID: dictionary.ID}).delete()
                ])
                    .then(() => {
                        if (!dictionary.OID) {
                            return true;
                        }

                        dictionary = iDictionaryModifiedTimes.setLastModified(dictionary);

                        var _method = 'syncDeleteDictionary';

                        var _queueObj = {
                            Method: _method,
                            User_OID: userOID,
                            Item_ID: dictionary.ID,
                            Timestamp: new Date().getTime(),
                            Process: false,
                            Data: {
                                dictionary: dictionary,
                            }
                        };

                        Tools.log(_method, _queueObj);

                        return db.table(this.TABLE_SYNCQUEUE)
                            .where({ Method: _method, Item_ID: dictionary.ID })
                            .toArray(results => {
                                if (results && results.length > 0) {
                                    return results[0];
                                }
                                else {
                                    return null;
                                }
                            })
                            .then((queueItem) => {
                                if (queueItem) {
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .update(queueItem.ID, _queueObj);
                                }
                                else {
                                    return db.table(this.TABLE_SYNCQUEUE)
                                        .add(_queueObj)
                                }
                            })
                            .then(() => {
                                this.serviceworkerSync();
                                return true;
                            })

                    })

                
            })

    }



    /**
     * Synchronizuje wszystkie słowniki z serwera
     */
    syncDictionariesFromServer() {

        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID){
                    return true;
                }

                var _method = 'syncDictionariesFromServer';

                return db.table(this.TABLE_SYNCQUEUE)
                    .where({ Method: _method, User_OID: userOID })
                    .toArray(results => {
                        if (results && results.length > 0) {
                            return results[0];
                        }
                        else {
                            return null;
                        }
                    })
                    .then(queueItem => {
                        var _queueObj = {
                            Method: _method,
                            User_OID: userOID,
                            Timestamp: new Date().getTime(),
                            Process: false,
                            Data: {} // dane zupełniamy na etapie synchronizacji syncQueue()
                        };
                        if (queueItem) {
                            return db.table(this.TABLE_SYNCQUEUE)
                                .update(queueItem.ID, _queueObj);
                        }
                        else {
                            return db.table(this.TABLE_SYNCQUEUE)
                                .add(_queueObj)
                        }
                    })
                    
            });

        
    }


    /**
     * Synchronizuje kolejkę. Powinien być uruchamiany co kilka minut. 
     */
    syncQueue() {
        return ModelUser.getUserOID()
            .then(userOID => {
                if(!userOID || !Tools.onLine()){
                    return false;
                }

                return this._syncQueueGetCollectedData(userOID)
                    .then((collectedData) => {
                        return this._syncQueueCallAPI(collectedData)
                    })
                    .then(response => {
                        if(response){
                            return this._syncQueueAttachQueueItemToMethodsResponse(response.results);
                        }
                        else {
                            return [];
                        }
                    })
                    .then(methodsResponses => {
                        
                        var refreshClient = false;
                        var errorQueueItems = [];

                        // ASYNC I AWAIT - konieczna synchroniczność wywołań
                        return (async () => {

                            for(let response of methodsResponses){

                                let error = response.error;
                                let queueItem = response.queueItem;
                                let result = response.result;
    
                                if(queueItem){
                                    if(!error){
                                        if(result.refreshClient){
                                            refreshClient = true;
                                        }
                                        switch (queueItem.Method) {
                                            case 'syncDictionariesFromServer':
                                                    await this._syncQueueProcessMethodResultSyncDictionariesFromServer(result)
                                                        .then((_refreshClient) => {
                                                            if(_refreshClient){
                                                                refreshClient = true;
                                                            }
                                                            return this._syncQueueDeleteSyncQueueItem(queueItem);
                                                        })
                                                break;
                                            default:
                                                    await this._syncQueueProcessMethodResult(result)
                                                        .then(() => {
                                                            return this._syncQueueDeleteSyncQueueItem(queueItem);
                                                        })
                                                break;
                                        }
                                    }
                                    else {
                                        errorQueueItems.push(queueItem);
                                    }
                                }
                            }


                        })()
                        .then(() => {return [errorQueueItems, refreshClient]})
                    })

            });

        
    }

    _syncQueueGetCollectedData(userOID){
        return new Promise(function(resolve, reject) {
            db.table(this.TABLE_SYNCQUEUE)
                .orderBy("Timestamp")
                .limit(100)
                .toArray(results => results)
                .then(queueItems => {
                    let collectedData = [];
                    let _promisesToRun = [];
                    for(let item of queueItems){
                        if(item.User_OID !== userOID){
                            continue;
                        }
                        if(!item.Process || (item.ProcessTimestamp && (new Date().getTime() - item.ProcessTimestamp > (3 * 60 * 1000)))){
                            item.Process = true;
                            item.ProcessTimestamp = new Date().getTime();
                            _promisesToRun.push(db.table(this.TABLE_SYNCQUEUE).update(item.ID, item));

                            if(item.Method === 'syncDictionariesFromServer'){
                                // dla syncDictionariesFromServer uzupełniamy data o aktualne dane
                                _promisesToRun.push(ModelDictionary.getDictionaries()
                                    .then(dictionaries => {
                                        var allDictionaries = [];
                                        for(var dictionary of dictionaries){
                                            delete dictionary.Words;
                                            dictionary = iDictionaryModifiedTimes.mergeModifiedTimes(dictionary);
                                            allDictionaries.push(dictionary);
                                        }
                                        collectedData.push({
                                            method: item.Method,
                                            params: {
                                                dictionaries: allDictionaries
                                            },
                                            id: item.ID
                                        });
                                    }));

                            }
                            else {
                                collectedData.push({
                                    method: item.Method,
                                    params: item.Data,
                                    id: item.ID
                                });
                            }

                            // przetwarzamy tylko 50 rekordów w jednym przebiegu
                            if(collectedData.length >= 50){
                                break;
                            }
                        }
                    }
                    return Tools.promiseAll(_promisesToRun)
                        .then(() => {
                            if(collectedData.length > 0){
                                // jeżeli w tablicy jest metoda syncDictionariesFromServer musi zostać zakolejkowana na pierwszym miejscu
                                var collectedDataSorted = [];
                                for(var _item1 of collectedData){
                                    if(_item1.method === 'syncDictionariesFromServer'){
                                        collectedDataSorted.push(_item1);
                                    }
                                }
                                for(var _item2 of collectedData){
                                    if(_item2.method !== 'syncDictionariesFromServer'){
                                        collectedDataSorted.push(_item2);
                                    }
                                }
                                collectedData = collectedDataSorted;
                            }

                            resolve(collectedData);
                        })
                })
                .catch(reject)
        }.bind(this));
    }

    _syncQueueCallAPI(collectedData){
        return new Promise(function(resolve, reject) {
            if(collectedData.length > 0){
                var data = {methods: collectedData};
                this.RPCRequest('bulk', data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(reject)
            }
            else {
                resolve();
            }
        }.bind(this));
    }

    _syncQueueAttachQueueItemToMethodsResponse(results){
        return new Promise(function(resolve, reject) {
            var _promisesToRun = [];
            for(let itemResponse of results){
                let syncqueueID = itemResponse.id;

                _promisesToRun.push(db.table(this.TABLE_SYNCQUEUE)
                    .where('ID')
                    .equals(syncqueueID)
                    .toArray(syncqueueResults => {
                        if (syncqueueResults && syncqueueResults.length > 0) {
                            return syncqueueResults[0];
                        }
                        else {
                            return null;
                        }
                    })
                    .then(queueItem => {
                        itemResponse.queueItem = queueItem;
                    })
                );
            }

            Promise.all(_promisesToRun)
                .then(() => {
                    resolve(results)
                })
                .catch(reject);

        }.bind(this));
    }

    _syncQueueProcessMethodResult(result){
        return new Promise(function(resolve, reject) {
            var _promisesToRun = [];
            if (result.dictionary){
                if(Array.isArray(result.dictionary)){
                    for(var _dic of result.dictionary){
                        _promisesToRun.push(ModelDictionary.replaceDictionary(_dic));
                        iDictionaryModifiedTimes.setAllLastModifiedFromDictionary(_dic);
                    }
                }
                else {
                    _promisesToRun.push(ModelDictionary.replaceDictionary(result.dictionary))
                    iDictionaryModifiedTimes.setAllLastModifiedFromDictionary(result.dictionary);
                }
            }

            // update OIDów słów
            if(result.wordsNewOIDs && result.wordsNewOIDs.length > 0){
                for(let wordsNewOID of result.wordsNewOIDs){
                    _promisesToRun.push(
                        ModelDictionary.getWordById(wordsNewOID.ID, true)
                            .then(word => {
                                word.OID = wordsNewOID.OID;
                                return db.table(this.TABLE_WORD).update(word.ID, word)
                            })
                    );
                }
            }
            Promise.all(_promisesToRun)
                .then(resolve)
                .catch(reject)
        }.bind(this))
    }


    _syncQueueProcessMethodResultSyncDictionariesFromServer(result){
        return new Promise(function(resolve, reject) {
            if (result.dictionaries) {
                let refreshClient = false;
                ModelDictionary.getDictionaries()
                    .then(dictionariesFromDB => {
                        let _promisesToRun = [];
                        // sprawdzamy czy są do wykasowania
                        for(let dictionaryFromDB of dictionariesFromDB){
                            if (dictionaryFromDB.OID) {
                                var _exists = false;
                                for (let dictionaryFromServer of result.dictionaries) {
                                    if (dictionaryFromServer.OID === dictionaryFromDB.OID) {
                                        _exists = true;
                                        break;
                                    }
                                }
                                if (!_exists) {
                                    refreshClient = true;
                                    _promisesToRun.push(ModelDictionary.delete(dictionaryFromDB.ID, true))
                                }
                            }
                        }
                        // synchronizujemy tylko wtedy gdy w zwróconych danych są Words - to znaczy, że nowsza wersja jest po stronie serwera
                        for (let dictionaryFromServer of result.dictionaries) {
                            if (dictionaryFromServer.Words) {
                                refreshClient = true;
                                _promisesToRun.push(ModelDictionary.replaceDictionary(dictionaryFromServer));
                                iDictionaryModifiedTimes.setAllLastModifiedFromDictionary(dictionaryFromServer);
                            }
                        }
                        return Promise.all(_promisesToRun)
                    })
                    .then(() => {resolve(refreshClient)})
                    .catch(reject)
                        
            }
            else {
                reject(); 
            }
        })
    }

    _syncQueueDeleteSyncQueueItem(queueItem){
        return new Promise(function(resolve, reject) {
            db.table(this.TABLE_SYNCQUEUE).delete(queueItem.ID)
                .then(resolve)
                .catch(reject);
        }.bind(this))
    }
    



    serviceworkerSync(){

        return ModelUser.getUserOID()
            .then(userOID => {
                if('SyncManager' in window && userOID && !Tools.onLine()){
                    navigator.serviceWorker.ready.then(function(swRegistration) {
                        return swRegistration.sync.register('syncDictionaries');
                    });
                }
                else {
                    return false;
                }
            })
            .catch(() => {});

    }

}

export default new APISync();



/**
 * Klasa pomocnicza, która przechowuje w pamięci i zwraca wszystkie czasy modyfikacji
 * związane ze słownikiem (obiektem dictionary).
 * Powstastała po to, aby rozwiązać problem z asynchronicznym zapisem tych czasów w obiekcie
 * słownika. Funkcja synchronizująca dane z serwerem pobierała te daty wcześniej bezposrednio z InnoDB, 
 * ale okazywało się, że często zapisywany asynchronicznie wcześnej obiekt Dictionary 
 * nie zdążył się zapisać i zwrócone daty z InnoDB były nieaktualne.
 */
class DictionaryModifiedTimes {

    DICTIONARIES = {}

    constructor() {
        // Singleton
        if (!!DictionaryModifiedTimes.instance) {
            return DictionaryModifiedTimes.instance;
        }
        DictionaryModifiedTimes.instance = this;
        return this;
    }


    setLastModified(dictionary){
        this.setModifiedTimes(dictionary, 'LastModified');
        return this.mergeModifiedTimes(dictionary);
    }

    setLastModifiedUserDictionary(dictionary){
        this.setModifiedTimes(dictionary, 'LastModifiedUserDictionary');
        this.setModifiedTimes(dictionary, 'LastModified');
        return this.mergeModifiedTimes(dictionary);
    }

    setLastModifiedWords(dictionary){
        this.setModifiedTimes(dictionary, 'LastModifiedWords');
        this.setModifiedTimes(dictionary, 'LastModified');
        return this.mergeModifiedTimes(dictionary);
    }

    setLastModifiedKnownWords(dictionary){
        this.setModifiedTimes(dictionary, 'LastModifiedKnownWords');
        this.setModifiedTimes(dictionary, 'LastModified');
        return this.mergeModifiedTimes(dictionary);
    }

    setAllLastModifiedFromDictionary(dictionary){
        if(typeof this.DICTIONARIES[this.getDictionaryKey(dictionary)] == 'undefined'){
            this.DICTIONARIES[this.getDictionaryKey(dictionary)] = {};
        }
        this.DICTIONARIES[this.getDictionaryKey(dictionary)].LastModified = dictionary.LastModified;
        this.DICTIONARIES[this.getDictionaryKey(dictionary)].LastModifiedUserDictionary = dictionary.LastModifiedUserDictionary;
        this.DICTIONARIES[this.getDictionaryKey(dictionary)].LastModifiedWords = dictionary.LastModifiedWords;
        this.DICTIONARIES[this.getDictionaryKey(dictionary)].LastModifiedKnownWords = dictionary.LastModifiedKnownWords;
    }


    getDictionaryKey(dictionary){
        return 'dictionary-' + dictionary.ID;
    }
    
    
    setModifiedTimes(dictionary, ModifiedKey){
        if(typeof this.DICTIONARIES[this.getDictionaryKey(dictionary)] == 'undefined'){
            this.DICTIONARIES[this.getDictionaryKey(dictionary)] = {};
        }
        this.DICTIONARIES[this.getDictionaryKey(dictionary)][ModifiedKey] = new Date().getTime();
    }

    getModifiedTimes(dictionary, ModifiedKey){
        if(typeof this.DICTIONARIES[this.getDictionaryKey(dictionary)] !== 'undefined'){
            return this.DICTIONARIES[this.getDictionaryKey(dictionary)][ModifiedKey] || null;
        }
        else {
            return null;
        }
    }

    mergeModifiedTimes(dictionary){
        if(this.getModifiedTimes(dictionary, 'LastModified')){
            dictionary.LastModified = this.getModifiedTimes(dictionary, 'LastModified');
        }
        if(this.getModifiedTimes(dictionary, 'LastModifiedUserDictionary')){
            dictionary.LastModifiedUserDictionary = this.getModifiedTimes(dictionary, 'LastModifiedUserDictionary');
        }
        if(this.getModifiedTimes(dictionary, 'LastModifiedWords')){
            dictionary.LastModifiedWords = this.getModifiedTimes(dictionary, 'LastModifiedWords');
        }
        if(this.getModifiedTimes(dictionary, 'LastModifiedKnownWords')){
            dictionary.LastModifiedKnownWords = this.getModifiedTimes(dictionary, 'LastModifiedKnownWords');
        }
        return dictionary;
    }

}

const iDictionaryModifiedTimes = new DictionaryModifiedTimes();
//window.test = iDictionaryModifiedTimes;



/**
 * Klasa obliczająca różnicę czasu pomiędzy klientem a serwerem
 */
class ClientTimeDiff {

    CHECK_EXPIRE = 5 * 60 * 1000;

    TIME_DIFF = 0;
    LAST_CHECK = 0;

    constructor() {
        // Singleton
        if (!!ClientTimeDiff.instance) {
            return ClientTimeDiff.instance;
        }
        ClientTimeDiff.instance = this;
        return this;
    }

    getTimeDiff(){

        return new Promise(function(resolve, reject) {
            if(!this.TIME_DIFF || new Date().getTime() - this.LAST_CHECK > this.CHECK_EXPIRE){
                var requestDataObject = {
                    jsonrpc: '2.0',
                    method: 'serverTime',
                    id: 1,
                }
                const request = new Request(
                    Config.get("APIEndpoint"),
                    {
                        method: "POST",
                        body: JSON.stringify(requestDataObject),
    
                    }
                );
                return fetch(request)
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        if(response.result){
                            this.TIME_DIFF = response.result - new Date().getTime();
                            this.LAST_CHECK = new Date().getTime();
                            resolve(this.TIME_DIFF);
                        }
                        else {
                            reject(new Error('Wrong server time!'));
                        }
                    })
                
            }
            else {
                resolve(this.TIME_DIFF);
            }
        }.bind(this));

    }


}

const iClientTimeDiff = new ClientTimeDiff();
//window.test = iClientTimeDiff;