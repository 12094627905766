import React, { Component } from 'react';
import { connect } from "react-redux"

class Loader extends Component {
    render() {
        const { appTools } = this.props;
        return (
            <div id="loader" className={appTools.loader.on ? '' : 'hide'}>
                <div>
                    <div className="mdl-spinner mdl-js-spinner is-active"></div>
                    {appTools.loader.msg && <div className="loader-msg">{appTools.loader.msg}</div>}
                </div>
            </div>
        )
    }
}

export default connect(function (store) {
    return {
        appTools: store.appTools
    }
})(Loader)
