import React, { Component } from 'react';
import { connect } from "react-redux";
import Lang from '../Misc/Lang';
import Tools from '../Misc/Tools';

class Alert extends Component {

    dialog = null;

    constructor(props) {
        super(props);
        this.state = {
            alertMsg: null,
        }
    }

    componentDidMount() {
        this.dialog = document.querySelector('.dialog-alert');
        if (this.dialog && !this.dialog.showModal) {
            window.dialogPolyfill.registerDialog(this.dialog);
        }
    }

    componentDidUpdate() { 
        Tools.mdlUpgrade();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.appTools.alert !== this.alertMsg && this.dialog){
            this.alertMsg = nextProps.appTools.alert;
            if(nextProps.appTools.alert){
                if(this.dialog && !this.dialog.open){
                    this.dialog.showModal();
                }
                return true;
            }
            else {
                this.closeDialog();
            }
        }
        return false;
    }

    closeDialog(){
        if(this.dialog && this.dialog.open){
            this.dialog.close();
        }
        Tools.alert(this.props, null);
    }

    render() {
        const { appTools } = this.props;

        return (
            <dialog className="mdl-dialog dialog dialog-alert" data-dialog-id="alert">
                <div className="mdl-dialog__content">
                    <p>{appTools.alert}</p>
                </div>
                <div className="mdl-dialog__actions">
                    <button type="button" className="mdl-button mdl-button--raised close" onClick={() => this.closeDialog()}>{Lang.get("dialog_alert_btn_ok")}</button>
                </div>
            </dialog>
        )
    }
}

export default connect(function (store) {
    return {
        appTools: store.appTools
    }
})(Alert)
