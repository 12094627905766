import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Tools from '../Misc/Tools';
import ModelDictionary from './../Models/ModelDictionary';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import { setUserCatalogDictionaries } from '../ReduxActions/AppActions';
import APISync from '../Models/APISync';

class CatalogPage extends Component {

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }
    
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            results: {},
            emptyResults: false
        };
    }

    componentDidMount() {
        this.setState({filter: null});
    }

    componentDidUpdate() {
        this.setResults();
        Tools.mdlUpgrade();
    }




    setResults() {
        if(Tools.onLine()){
            var filter = '';
            if (this.props.match && this.props.match.params.query) {
                filter = this.props.match.params.query;
            }
            if(filter !== this.state.filter){
                this.setState({filter: filter});
                Tools.loader(this.props, true);
                ModelDictionary.getCatalogDictionaries(filter)
                    .then(catalogDictionaries => {
                        var _emptyResults = (!catalogDictionaries.highlight || catalogDictionaries.highlight.length === 0) && (!catalogDictionaries.common || catalogDictionaries.common.length === 0);
                        if(!_emptyResults){
                            Tools.loader(this.props, false);
                            // odsiewamy te, których właścicielem jest użytkownik
                            return this.filterCatalogDictionaries(catalogDictionaries)
                                .then((filteredCatalogDictionaries) => {
                                    this.setState({ results: filteredCatalogDictionaries, emptyResults: false }, this.setIHave)
                                    return true;
                                })
                        }
                        else {
                            // pobieramy pełną liste bez filtra
                            return ModelDictionary.getCatalogDictionaries('')
                                .then(catalogDictionaries => {
                                    Tools.loader(this.props, false);
                                    return this.filterCatalogDictionaries(catalogDictionaries)
                                        .then((filteredCatalogDictionaries) => {
                                            this.setState({ results: filteredCatalogDictionaries, emptyResults: true }, this.setIHave)
                                            return true;
                                        })
                                })
                        }
                        
                    })
                    .catch(e => {
                        Tools.loader(this.props, false);
                        Tools.log(e);
                        Tools.alert(this.props, Lang.get("dialog_alert_msg_error"));
                    })
            }
        }
    }


    filterCatalogDictionaries(catalogDictionaries){
        return ModelDictionary.getUserDictionaries()
            .then(userDictionaries => {
                var filteredCatalogDictionaries = {highlight: [], common: []}
                
                for(let catDic of catalogDictionaries.highlight){
                    let _exist = false;
                    for(let dic of userDictionaries){
                        if(catDic.OID === dic.OID){
                            _exist = true;
                            break;
                        }
                    }
                    if(!_exist){
                        filteredCatalogDictionaries.highlight.push(catDic);
                    }
                }

                for(let catDic of catalogDictionaries.common){
                    let _exist = false;
                    for(let dic of userDictionaries){
                        if(catDic.OID === dic.OID){
                            _exist = true;
                            break;
                        }
                    }
                    if(!_exist){
                        filteredCatalogDictionaries.common.push(catDic);
                    }
                }
                return filteredCatalogDictionaries;
            })
    }

    setIHave() {
        var results = this.state.results;

        if (results) {
            ModelDictionary.getUserCatalogDictionaries()
                .then(userCatalogDictionaries => {
                    var userCatalogDictionariesIDs = [];
                    for (let dic of userCatalogDictionaries) {
                        userCatalogDictionariesIDs.push(dic.OID);
                    }
                    for (let key in results) {
                        for (let idx in results[key]) {
                            results[key][idx].have = userCatalogDictionariesIDs.indexOf(results[key][idx].OID) > -1 ? true : false;
                        }
                    }
                    this.setState({ results: results });
                })
                .catch(Tools.log)
        }
    }


    actionAddDictionary(OID, history){
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelDictionary.addDictionaryFromCatalog(OID)
                .then((dictionary) => {
                    Tools.loader(this.props, false);
                    this.setIHave();
                    ModelDictionary.getUserCatalogDictionaries().then((dictionaries) => {this.props.dispatch(setUserCatalogDictionaries(dictionaries))})
                    Tools.toast(Lang.get("catalog_dic_added_toast"), Lang.get("catalog_dic_added_toast_dic_button"), () => {
                        history.push('/dic/' + dictionary.ID);
						Tools.toastClear();
                    }, 5000);
                })
                .catch(e => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                })
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionDelDictionary(OID){
        if(Tools.onLine()){
            Tools.confirm(
                this.props, 
                () => {
                    Tools.loader(this.props, true);
                    ModelDictionary.getDictionaryByOID(OID)
                        .then(dictionary => {
                            return ModelDictionary.delete(dictionary.ID)
                        })
                        .then(() => {
                            return APISync.syncQueue();
                        })
                        .then(() => {
                            Tools.loader(this.props, false);
                            this.setIHave();
                            ModelDictionary.getUserCatalogDictionaries().then((dictionaries) => {this.props.dispatch(setUserCatalogDictionaries(dictionaries))})
                        })
                        .catch(e => {
                            Tools.loader(this.props, false);
                            Tools.log(e);
                        })
                }, 
                Lang.get("dic_dialog_delete_title"),
                Lang.get("dic_dialog_delete_confirm"),
                Lang.get("dic_dialog_delete_btn_ok")
            )
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }



    render() {
        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("catalog_title")}
                    disableMenuItemSelect={true}
                    searchValue={this.state.filter}
                    onlineRequiredCallback={this.forceUpdate.bind(this)}
                >

                    <div className="page-catalog">
                        
                        {this.props.match && this.props.match.params.query &&
                            <div className="catalog-search">
                                <h2>{Lang.get("catalog_search_label")}: <span>{this.props.match.params.query}</span></h2>
                                {this.state.emptyResults &&
                                    <div className="catalog-search__empty">
                                        <p>{Lang.get("catalog_not_found")}</p>
                                        <hr />
                                    </div>
                                }
                            </div>
                        }

                        {this.state.results.highlight && this.state.results.highlight.length > 0 &&
                            <div className="mdl-grid">
                                {this.state.results.highlight.map((dic, index) =>
                                    <div key={"ich"+dic.OID} className="catalog-item mdl-cell mdl-cell--4-col">
                                        <h3>{dic.Name}</h3>
                                        <div className="catalog-item__description">{dic.Description}</div>
                                        <label>
                                            {dic.Author && <span className="catalog-item__autor">{Lang.get("catalog_label_author")}: {dic.Author}</span>}
                                            <span className="catalog-item__counter">{Lang.get("catalog_label_words_count")}: {dic.WordsCnt}</span>
                                        </label>
                                        <div className="add-dic">
                                            {dic.have &&
                                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionDelDictionary(dic.OID)}>
                                                    <i className="material-icons">close</i> {Lang.get("catalog_item_btn_del")}
                                                </button>
                                            }
                                            {!dic.have &&
                                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionAddDictionary(dic.OID, history)}>
                                                    <i className="material-icons">add</i> {Lang.get("catalog_item_btn_add")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        } 

                        {this.state.results.common && this.state.results.common.length > 0 &&
                            <div className="mdl-grid">
                                {this.state.results.common.map((dic, index) =>
                                    <div key={"ic"+dic.OID} className="catalog-item catalog-item--user mdl-cell mdl-cell--3-col mdl-cell--4-col-phone mdl-cell--4-col-tablet">
                                        <h3>{dic.Name}</h3>
                                        <div className="catalog-item__description">{dic.Description}</div>
                                        <label>
                                            {dic.Author && <span className="catalog-item__autor">{Lang.get("catalog_label_author")}: {dic.Author}</span>}
                                            <span className="catalog-item__counter">{Lang.get("catalog_label_words_count")}: {dic.WordsCnt}</span>
                                        </label>
                                        <div className="add-dic">
                                            {dic.have &&
                                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionDelDictionary(dic.OID)}>
                                                    <i className="material-icons">close</i> {Lang.get("catalog_item_btn_del")}
                                                </button>
                                            }
                                            {!dic.have &&
                                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionAddDictionary(dic.OID, history)}>
                                                    <i className="material-icons">add</i> {Lang.get("catalog_item_btn_add")}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        }

                    </div>

                </Layout>
            )} /> 

        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(CatalogPage)
