import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';
import Config from '../Config/Config';
import Tools from '../Misc/Tools';
import { Speech } from '../Misc/Speech';
import Lang from '../Misc/Lang';
import Layout from '../Components/Layout';
import ModelUser from './../Models/ModelUser';
import ModelDictionary from './../Models/ModelDictionary';
import LocalStorage from './../Models/LocalStorage';
import APISync from './../Models/APISync';
import { setUser, setMultipleData } from '../ReduxActions/AppActions';

class SettingsPage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            notActive: false,
            inputNick: '',
            errorNick: false,
            voice: Speech.getVoice(LocalStorage.getSpeechVoiceURI()),
            speechOnTouch: LocalStorage.getSettingSpeechOnTouch(),
            modeSlideshow: LocalStorage.getModeSlideshow(),
            themeDarkMode: LocalStorage.getSettingDarkTheme()
        }
    }

    componentDidCatch(error, info) {
        document.location = '/#/error';    
    }
    
    componentDidMount() {
        ModelUser.getUser()
            .then(user => {
                if(user && user.OID){
                    this.setState({logged: true, inputNick: user.Nick, notActive: user.Active ? false : true})
                }
            })
            .catch(Tools.log)


        // ponownie odczytujemy supportowane głosy (API ma opóźnienia)
        setTimeout(() => {
            this.setVoiceState();
        }, 1000)
    }

    componentDidUpdate() {
        Tools.mdlUpgrade();
        Tools.mdlInputRefresh();
        Tools.mdlSelectRefresh();
    }


    setVoiceState() {
        this.setState({voice: Speech.getVoice(LocalStorage.getSpeechVoiceURI())});
    }

    changeInputNick(e) {
        this.setState({ inputNick: e.target.value, errorNick: false });
    }

    actionChangeNick(history) {
        if(Tools.onLine()){
            ModelUser.getUser()
                .then(user => {
                    if(this.state.inputNick.trim() !== user.Nick){
                        if(Tools.isNickValid(this.state.inputNick)){
                            Tools.loader(this.props, true);
                            ModelUser.changeNick(this.state.inputNick.trim())
                                .then((response) => {
                                    user.Nick = this.state.inputNick.trim();
                                    Tools.loader(this.props, false);
                                    if(response.status){
                                        this.props.dispatch(setUser(user));
                                        Tools.toast(Lang.get("settings_nick_toast_changed"));
                                    }
                                    else {
                                        this.setState({errorNick: true});
                                    }
                                })
                                .catch(() => {
                                    Tools.loader(this.props, false);
                                    history.push('/error');
                                })
                        }
                        else {
                            this.setState({errorNick: true});
                        }
                    }
                })
                .catch(Tools.log)
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }


    actionChangePassword(history) {
        if(Tools.onLine()){
            history.push('/settings-password-change');
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
        
    }

    actionSharedDictionaries(history){
        if(Tools.onLine()){
            history.push('/shared-dictionaries');
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionDeleteAccount(history){
        if(Tools.onLine()){
            Tools.confirm(
                this.props, 
                () => {
                    Tools.loader(this.props, true);
                    ModelUser.deleteAccountRequest()
                        .then((response) => {
                            Tools.loader(this.props, false);
                            Tools.alert(this.props, Lang.get("settings_delete_sent_info"));
                        })
                        .catch(() => {
                            Tools.loader(this.props, false);
                            history.push('/error');
                        })
                }, 
                Lang.get("settings_delete_dialog_title"),
                Lang.get("settings_delete_dialog_confirm"),
                Lang.get("settings_delete_dialog_btn_ok")
            )
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }


    actionChangeTouchSpeech(){
        this.setState({speechOnTouch: !this.state.speechOnTouch}, () => {
            LocalStorage.setSettingSpeechOnTouch(this.state.speechOnTouch);
        })
    }

    actionChangeThemeDarkMode(){
        this.setState({themeDarkMode: !this.state.themeDarkMode}, () => {
            LocalStorage.setSettingDarkTheme(this.state.themeDarkMode);
            // obsługa motywu ciemnego w DOM jest na poziomie pliku index.js
            if(this.state.themeDarkMode){
                document.documentElement.classList.add('dark-theme');
                document.querySelector("meta[name=theme-color]").setAttribute("content", '#333333');
            }
            else {
                document.documentElement.classList.remove('dark-theme');
                document.querySelector("meta[name=theme-color]").setAttribute("content", '#2A9987');
            }
        })
    }

    actionModeChange(slideshow){
        LocalStorage.setModeSlideshow(slideshow);
        this.setState({modeSlideshow: !this.state.modeSlideshow});
    }

    actionClearDB(history){
        if(Tools.onLine()){
            Tools.confirm(
                this.props, 
                () => {
                    /** CZYŚCI BAZĘ TYLKO ZALOGOWANYCH
                    ModelUser.getUserOID()
                        .then(userOID => {
                            if(userOID){
                                Tools.loader(this.props, true);
                                return ModelDictionary.clearDBLoggedUser()
                                    .then(() => {
                                        return APISync.syncDictionariesFromServer();
                                    })
                                    .then(() => {
                                        return APISync.syncQueue()
                                    })
                                    .then(() => {
                                        LocalStorage.clear();
                                        Tools.loader(this.props, false);
                                        history.push('/welcome');
                                    })
                            }
                            else {
                                return true;
                            }
                        })
                        .catch((e) => {
                            Tools.loader(this.props, false);
                            Tools.log(e);
                            history.push('/error');
                        })
                    */

                    Tools.loader(this.props, true);
                    LocalStorage.setDataInitialized(false);
                    Tools.loader(this.props, true, Lang.get("sync_data_msg"));
                    ModelDictionary.initializeDB(true)
                        .then(() => {
                            return ModelUser.getUserOID()
                        })
                        .then(userOID => {
                            if(userOID){
                                return APISync.syncDictionariesFromServer()
                                    .then(() => {
                                        return APISync.syncQueue()
                                    })
                            }
                            else {
                                return true;
                            }
                        })
                        .then(() => {
                            //LocalStorage.clear();
                            //LocalStorage.setDataInitialized(true);
                            Tools.loader(this.props, false);
                            history.push('/welcome');
                        })
                        .catch((e) => {
                            Tools.loader(this.props, false);
                            Tools.log(e);
                            history.push('/error');
                        })
                }, 
                Lang.get("settings_cleardb_dialog_title"),
                Lang.get("settings_cleardb_dialog_confirm"),
                Lang.get("settings_cleardb_dialog_btn_ok")
            )
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionForceSync(history){
        if(Tools.onLine()){
            Tools.loader(this.props, true, Lang.get("sync_data_msg"));
            APISync.syncDictionariesFromServer()
                .then(() => {
                    return APISync.syncQueue();
                })
                .then(() => {
                    return Promise.all([
                        ModelUser.getUser(),
                        ModelDictionary.getBookmarksDictionary(true),
                        ModelDictionary.getUserDictionaries(),
                        ModelDictionary.getUserCatalogDictionaries(),
                        ModelDictionary.getUserSharedDictionaries(),
                        //ModelDictionary.getCurrentDictionary(),
                    ])
                        .then(results => {
                            this.props.dispatch(setMultipleData({
                                user: results[0],
                                bookmarksDictionary: results[1],
                                userDictionaries: results[2],
                                userCatalogDictionaries: results[3],
                                userSharedDictionaries: results[4],
                                //dictionary: results[5],
                            }));
                        })
                })
                .then(() => {
                    Tools.loader(this.props, false);
                    Tools.toast(Lang.get("sync_from_server_done_toast"));
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                    history.push('/error');
                })
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionActivationSend(history){
        if(Tools.onLine()){
            Tools.loader(this.props, true);
            ModelUser.sendActivation()
                .then(() => {
                    Tools.loader(this.props, false);
                    Tools.alert(this.props, Lang.get("settings_activation_sent"));
                })
                .catch((e) => {
                    Tools.loader(this.props, false);
                    Tools.log(e);
                    history.push('/error');
                })
        }
        else {
            Tools.alert(this.props, Lang.get("dialog_alert_msg_offline"));
        }
    }

    actionChangeVoice(e, voice){
        LocalStorage.setSpeechVoiceURI(voice.voiceURI);
        this.setVoiceState();
        Speech.speech(Config.get("speechTestMessage"));
    }


    render() {

        return (

            <Route render={({ history }) => (
                <Layout
                    headerTitle={Lang.get("settings_title")}
                    disableMenuItemSelect={true}
                >

                    <div className="page-settings compact-content">

                        {this.state.notActive &&
                            <div className="info-box info-box--notice">
                                {Lang.get("settings_not_active")}
                                <button className="mdl-button mdl-js-button mdl-js-ripple-effect" onClick={() => { this.actionActivationSend(history) }}>{Lang.get("settings_not_active_send_btn")}</button>
                            </div>
                        }

                        <section className="settings">
                            <h2>{Lang.get("settings_section_mode")}</h2>
                            <div className="settings__content settings__content-cols">
                                <div className="settings__content-col">
                                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="mode-2">
                                        <input type="radio" id="mode-2" className="mdl-radio__button" name="options" value="2" checked={this.state.modeSlideshow} onChange={() => this.actionModeChange(true)} />
                                        <span className="mdl-radio__label">{Lang.get("settings_mode_slideshow")}</span>
                                    </label>
                                </div>
                                <div className="settings__content-col">
                                    <label className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor="mode-1">
                                        <input type="radio" id="mode-1" className="mdl-radio__button" name="options" value="1" checked={!this.state.modeSlideshow} onChange={() => this.actionModeChange(false)} />
                                        <span className="mdl-radio__label">{Lang.get("settings_mode_list")}</span>
                                    </label>
                                </div>
                            </div>
                        </section>


                        <section className="settings">
                                <h2>{Lang.get("settings_section_theme")}</h2>
                                <div className="settings__content settings__content-cols">
                                    <div className="settings__content-col">
                                        <p>{Lang.get("settings_theme_dark")}</p>
                                    </div>
                                    <div className="settings__content-col">
                                        <label className={"mdl-switch is-upgraded" + (this.state.themeDarkMode ? ' is-checked' : '')} htmlFor="checkbox2">
                                            <input type="checkbox" id="checkbox2" className="mdl-switch__input" checked={this.state.themeDarkMode} onChange={this.actionChangeThemeDarkMode.bind(this)} />
                                            <span className="mdl-switch__label"></span>
                                            <div className="mdl-switch__track"></div>
                                            <div className="mdl-switch__thumb">
                                                <span className="mdl-switch__focus-helper"></span>
                                            </div>
                                            <span className="mdl-switch__ripple-container mdl-ripple--center" data-upgraded=",MaterialRipple">
                                                <span className="mdl-ripple"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </section>

                        {Speech.canSpeech() &&
                        
                            <section className="settings">
                                <h2>{Lang.get("settings_section_speech")}</h2>
                                {/* Niestety w Chrome for Android zarówno en_GB jak i en_US niczym się nie różnią (działa US) dlatego na razie ten przełącznik nie ma sensu */}
                                {/* Speech.getVoices().length > 1 &&
                                    <div className="settings__content">
                                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label getmdl-select">
                                            <input type="text" defaultValue={this.state.voice ? this.state.voice.name : ""} className="mdl-textfield__input" id="setVoiceURI" readOnly />
                                            <input type="hidden" value="" />
                                            <i className="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                            <label htmlFor="setVoiceURI" className="mdl-textfield__label">{Lang.get("settings_speech_voice")}</label>
                                            <ul htmlFor="setVoiceURI" className="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                            {Speech.getVoices().map((voice, index) =>
                                                ((voice.voiceURI === LocalStorage.getSpeechVoiceURI()) ? (
                                                    <li key={"spv-key-" + index} onClick={e => this.actionChangeVoice(e, voice)} className="mdl-menu__item" data-val={voice.voiceURI} data-selected>{voice.name}</li>
                                                ) : (
                                                        <li key={"spv-key-" + index} onClick={e => this.actionChangeVoice(e, voice)} className="mdl-menu__item" data-val={voice.voiceURI}>{voice.name}</li>
                                                    ))
                                            )}
                                            </ul>
                                        </div>
                                    </div>
                                */}
                                <div className="settings__content settings__content-cols">
                                    <div className="settings__content-col">
                                        <p>{Lang.get("settings_speechontouch")}</p>
                                    </div>
                                    <div className="settings__content-col">
                                        <label className={"mdl-switch is-upgraded" + (this.state.speechOnTouch ? ' is-checked' : '')} htmlFor="checkbox1">
                                            <input type="checkbox" id="checkbox1" className="mdl-switch__input" checked={this.state.speechOnTouch} onChange={this.actionChangeTouchSpeech.bind(this)} />
                                            <span className="mdl-switch__label"></span>
                                            <div className="mdl-switch__track"></div>
                                            <div className="mdl-switch__thumb">
                                                <span className="mdl-switch__focus-helper"></span>
                                            </div>
                                            <span className="mdl-switch__ripple-container mdl-ripple--center" data-upgraded=",MaterialRipple">
                                                <span className="mdl-ripple"></span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </section>
                        }

                        {this.state.logged && 
                            <div>
                                <section className="settings">
                                    <h2>{Lang.get("settings_section_nick")}</h2>
                                    <div className="settings__content">
                                        <div className="mdl-textfield mdl-textfield--full-width mdl-js-textfield mdl-textfield--floating-label">
                                            <input className="mdl-textfield__input" name="nick" type="text" minLength="3" maxLength="20" id="setNick" value={this.state.inputNick} onChange={e => this.changeInputNick(e)} onKeyPress={(e) => (e.key === 'Enter' ? this.actionChangeNick(history) : null)} required />
                                            <label className="mdl-textfield__label" htmlFor="setNick">{Lang.get("settings_nick_label")}</label>
                                            {this.state.errorNick && <span className="mdl-textfield__error mdl-textfield__error--force-show">{Lang.get("settings_nick_error")}</span>}
                                        </div>
                                        <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionChangeNick(history)}>{Lang.get("settings_nick_btn")}</button>
                                    </div>
                                </section>

                                <section className="settings">
                                    <h2>{Lang.get("settings_section_password")}</h2>
                                    <div className="settings__content">
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionChangePassword(history)}>{Lang.get("settings_changepassword_btn")}</button>
                                    </div>
                                </section>

                                <section className="settings">
                                    <h2>{Lang.get("settings_shareddictionaries_label")}</h2>
                                    <div className="settings__content">
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect mdl-button--colored mdl-color-text--white" onClick={() => this.actionSharedDictionaries(history)}>{Lang.get("settings_shareddictionaries_btn")}</button>
                                    </div>
                                </section>

                                <section className="settings">
                                    <h2>{Lang.get("settings_section_forcesync")}</h2>
                                    <div className="settings__content">
                                        <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionForceSync(history)}>{Lang.get("settings_forcesync_btn")}</button>
                                    </div>
                                </section>
                            </div>
                        }

                        <section className="settings">
                            <h2>{Lang.get("settings_section_cleardb")}</h2>
                            <div className="settings__content">
                                <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionClearDB(history)}>{Lang.get("settings_cleardb_btn")}</button>
                            </div>
                        </section>

                        {this.state.logged && 
                            <div>
                                <section className="settings">
                                    <h2>{Lang.get("settings_section_delete")}</h2>
                                    <div className="settings__content">
                                    <button className="mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect" onClick={() => this.actionDeleteAccount(history)}>{Lang.get("settings_delete_btn")}</button>
                                    </div>
                                </section>
                            </div>
                        }


                        <p><a href={Config.get("termsURL")} rel="noopener noreferrer" target="_blank">{Lang.get("settings_link_text_terms")}</a></p>
                        <p><a href={Config.get("privacyURL")} rel="noopener noreferrer" target="_blank">{Lang.get("settings_link_text_private_policy")}</a></p>
                        <p><a href={Config.get("contactURL")} rel="noopener noreferrer" target="_blank">{Lang.get("settings_link_text_contact")}</a></p>
                        
                    </div>

                </Layout>
            )} />
        )
    }

}

export default connect(function (store) {
    return {
        appState: store.appState
    }
})(SettingsPage)
